import { useTranslation } from 'react-i18next';

import ErrorPage from './index';

const NotFound = () => {
	const [t] = useTranslation();
	return (
		<ErrorPage
			error={{
				status: 404,
				code: null,
				text: t('Такой страницы не существует'),
				errorId: null,
			}}
		/>
	);
};

export default NotFound;
