import { notification } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import api from '~/api';
import { login } from '~shared/utils/loginFunctions';
import { useUser } from '~zustand/userData';

import { useStyles } from './styles';

const generateKey = () => {
	const symbols = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // Исключая O, I, 0, 1
	const getSequence = (length: number) =>
		new Array(length)
			.fill(undefined)
			.map(() => symbols[Math.floor(Math.random() * symbols.length)])
			.join('');
	return `${getSequence(4)}-${getSequence(4)}`;
};

const TvAuth = ({ redirectUrl = '/tv-client/tableau' }) => {
	const { classes } = useStyles();
	const [t] = useTranslation();
	const navigate = useNavigate();

	const secretKey = useMemo(() => generateKey(), []);

	const user = useUser();

	useEffect(() => {
		if (user.role !== 'guest') {
			navigate(redirectUrl);
		}
	}, [user.role]);

	useEffect(() => {
		const interval = setInterval(async () => {
			try {
				const { data } = await api.tvClient.auth({ secret_key: secretKey });

				await login({
					token: data.token,
					store_id: data.device.store_id,
				});
				clearInterval(interval);
			} catch {
				notification.error({
					message: t('Не удалось обновить статус аутентификации монитора'),
				});
			}
		}, 15000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className={classes.layout}>
			<div className={classes.paper}>
				<h1 className={classes.headerText} data-test="header">
					{t('Код авторизации')}:
				</h1>
				<div className={classes.authKeyBlock}>
					<span data-test="secret key">{secretKey}</span>
				</div>
			</div>
		</div>
	);
};

export default TvAuth;
