import { createContext } from 'react';

import type { StashResponse } from './Block';
import type { SuptoolsSearchHandler } from './Card/utils';

export type SupportSearchContextType = {
	addBlock: (key: string, text: string, runRequest: boolean) => void;
	removeBlock: (key: string) => void;
	responses: Record<string, StashResponse>;
	handlers: SuptoolsSearchHandler[] | null;
};

export const SupportSearchContext = createContext<SupportSearchContextType>({
	responses: {},
	addBlock() {},
	removeBlock() {},
	handlers: null,
});
