import api, { setAuthorizationHeader, setUserRoleHeader } from '~/api';
import { availableWithoutAuth } from '~constants/availableWithoutAuth';
import type { User } from '~types/user';
import { getUserData, useUserStore } from '~zustand/userData';

import { router } from '../../router';
import { unSubscribeAll } from './ev';
import { removeCredentialsFromLocalStorage, writeCredentialsInLocalStorage } from './localStorage';

export async function login(credentials: User.Credentials) {
	writeCredentialsInLocalStorage(JSON.stringify(credentials));
	setAuthorizationHeader(credentials.token);

	await getUserData();
	const lastPathname = localStorage.getItem('lavkaLastPathname');
	if (lastPathname && !availableWithoutAuth.includes(window.location.pathname)) {
		localStorage.removeItem('lavkaLastPathname');
		return lastPathname;
	}
	return '/';
}

export async function logout(saveLastPathname = false, redirectUrl?: string) {
	const lastPathname = window.location.pathname;
	if (saveLastPathname) {
		localStorage.setItem('lavkaLastPathname', lastPathname);
	}
	if (!redirectUrl) {
		const preferredProvider = useUserStore.getState().userData.preferred_provider;

		const query = preferredProvider ? `?provider=${preferredProvider}` : '';
		redirectUrl = `/login${query}`;
	}

	unSubscribeAll();

	// Инвалидируем токен на сервере, если произошла ошибка, то не критично
	try {
		await api.profile.logout();
	} catch {}

	removeCredentialsFromLocalStorage();
	setAuthorizationHeader(undefined);
	setUserRoleHeader(undefined);
	await getUserData();
	void router.navigate(redirectUrl);
}
