import type { EquipmentDisabledReasons } from '~server-types/doc/api/admin/equipments';

export const SWITCH_OFF_STORAGE_EQUIPMENT_REASONS: EquipmentDisabledReasons[] = [
	'no_electricity',
	'engineering_works',
	'breaking',
	'temperature_increase',
	'door_jammed',
];

export const SWITCH_OFF_KITCHEN_EQUIPMENT_REASONS = ['no_electricity', 'engineering_works', 'breaking', 'no_water'];
