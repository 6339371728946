import { Spin } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { JSX } from 'react';

import i18n from '~/i18n';

import withZero from './withZero';

export const timezoneTimeStyle = {
	display: 'block',
	fontWeight: 'normal' as const,
	fontSize: 12,
	lineHeight: '14px',
	color: '#BFBFBF',
};

const timeStyle = {
	whiteSpace: 'nowrap' as const,
};

const timeStringStyle = {
	whiteSpace: 'nowrap' as const,
	display: 'inline-flex',
	alignItems: 'center',
};

const getUTCOffsetInHours = (date: Dayjs) => {
	const nativeDate = new Date(date.format());
	const rawOffset = nativeDate.getTimezoneOffset();
	return (rawOffset / 60) * -1;
};

const UTCString = (date: Dayjs) => {
	const utcOffset = getUTCOffsetInHours(date);
	return `UTC${utcOffset >= 0 ? '+' : ''}${utcOffset}`;
};

export function formatTimeAndYear<T extends string | undefined = undefined, L extends boolean | undefined = undefined>(
	time: string | undefined | [string, string],
	options: {
		timezone?: T;
		onlyDate?: boolean;
		withSeconds?: boolean;
		alwaysShowYear?: boolean;
		stringView?: boolean;
	} = {},
	loading?: L
): T extends string ? JSX.Element : string | L extends boolean ? JSX.Element : string {
	if (loading) {
		return (<Spin size="small" />) as any;
	}
	if (!time) {
		return '—' as any;
	}

	const { timezone, onlyDate = false, withSeconds = false, alwaysShowYear = true } = options;

	const getDateFormat = (date: Dayjs) => {
		let format: string;
		if (!alwaysShowYear && date.isSame(new Date(), 'year')) {
			if (!onlyDate) {
				// if (date.isSame(new Date(), 'day')) {
				// 	return withSeconds ? date.format('HH:mm:ss') : date.format('HH:mm');
				// }
				format = withSeconds ? 'DD.MM, HH:mm:ss' : 'DD.MM, HH:mm';
			} else {
				format = 'DD.MM';
			}
		} else {
			if (!onlyDate) {
				if (withSeconds) {
					format = 'DD.MM.YYYY, HH:mm:ss';
				} else {
					format = 'DD.MM.YYYY, HH:mm';
				}
			} else {
				format = 'DD.MM.YYYY';
			}
		}
		return format;
	};

	if (Array.isArray(time)) {
		const from = time[0] && dayjs(time[0]).isValid() ? dayjs(time[0]) : undefined;
		const to = time[1] && dayjs(time[1]).isValid() ? dayjs(time[1]) : undefined;

		if (timezone) {
			return (
				<span style={options.stringView ? timeStringStyle : timeStyle}>
					<span data-test="time local" style={{ marginInlineEnd: 8 }}>
						{i18n.t('С {{from}} по {{to}}', {
							from: from ? from.tz(timezone).format(getDateFormat(from)) : '—',
							to: to ? to.tz(timezone).format(getDateFormat(to)) : '—',
						})}
					</span>
					<span data-test="time timezone" style={timezoneTimeStyle}>
						{i18n.t('С {{from}} по {{to}}', {
							from: from ? from.format(`(Z) ${getDateFormat(from)}`) : '—',
							to: to ? to.format(`(Z) ${getDateFormat(to)}`) : '—',
						})}
					</span>
				</span>
			) as any;
		}
		return i18n.t('С {{from}} по {{to}}', {
			from: from ? from.format(getDateFormat(from)) : '—',
			to: to ? to.format(getDateFormat(to)) : '—',
		}) as any;
	} else {
		const date = dayjs(time);
		if (!date.isValid()) return '' as any;
		const format = getDateFormat(date);
		if (timezone) {
			return (
				<span style={options.stringView ? timeStringStyle : timeStyle}>
					<span data-test="time local" style={{ marginInlineEnd: 8 }}>
						{date.tz(timezone).format(format)}
					</span>
					<span data-test="time timezone" style={timezoneTimeStyle}>
						{`(${UTCString(date)}) ` + date.format(format)}
					</span>
				</span>
			) as any;
		}
		return date.format(format) as any;
	}
}

export const sortByTime = (array: any[], timePropName: string, newFirst = true) => {
	return [...array].sort((a, b) => {
		const aM = dayjs(a[timePropName]);
		const bM = dayjs(b[timePropName]);
		if (aM.isBefore(bM)) {
			return newFirst ? 1 : -1;
		} else if (aM.isAfter(bM)) {
			return newFirst ? -1 : 1;
		}
		return 0;
	});
};

export const formatDifference = (
	dateOne: string | Dayjs,
	dateTwo: string | Dayjs,
	options?: {
		roundMinutes?: boolean;
	}
) => {
	let totalSeconds = dayjs(dateOne).diff(dateTwo, 'second');
	const minus = totalSeconds < 0;
	totalSeconds = options?.roundMinutes ? Math.abs(totalSeconds) + 59 : Math.abs(totalSeconds);
	const totalHours = Math.floor(totalSeconds / (60 * 60));
	totalSeconds = totalSeconds - totalHours * 60 * 60;
	const totalMinutes = Math.floor(totalSeconds / 60);
	totalSeconds = totalSeconds - totalMinutes * 60;
	return {
		minus,
		hours: totalHours,
		minutes: totalMinutes,
		seconds: options?.roundMinutes ? undefined : totalSeconds,
	};
};

export const counterString = (hours?: number, minutes?: number, seconds?: number, word?: boolean) =>
	word
		? `${hours ? i18n.t('{{hours}} ч', { hours: withZero(Math.abs(hours)) }) : ''}${
				minutes !== undefined ? ` ${i18n.t('{{minutes}} мин', { minutes: withZero(minutes) })}` : ''
			}${seconds !== undefined ? ` ${i18n.t('{{seconds}} сек.', { seconds: withZero(seconds) })}` : ''}`
		: `${hours ? `${withZero(Math.abs(hours))}:` : ''}${minutes !== undefined ? withZero(minutes) : ''}${
				seconds !== undefined ? `:${withZero(seconds)}` : ''
			}`;

export const formatSlotPeriod = (startedAt: string, closesAt: string) => {
	const dayStarted = dayjs(startedAt).format('DD.MM.YYYY');
	const dayCloses = dayjs(closesAt).format('DD.MM.YYYY');

	if (dayStarted === dayCloses) {
		const timeStarted = dayjs(startedAt).format('HH:MM');
		const timeCloses = dayjs(closesAt).format('HH:MM');

		return i18n.t('Слот {{ dayStarted }} {{ timeStarted }} — {{ timeCloses }}', {
			dayStarted,
			timeStarted,
			timeCloses,
		});
	}

	return i18n.t('Слот {{ startedAt }} — {{ closesAt }}', {
		startedAt: formatTimeAndYear(startedAt),
		closesAt: formatTimeAndYear(closesAt),
	});
};

export const formatTimeToString = (time: number | string, format: string): string => {
	return typeof time === 'number' ? dayjs.duration(time * 1000).format(format) : time;
};

export const formatTimeToDayjs = (time: number | string, format: string): Dayjs => {
	return typeof time === 'string' ? dayjs(time, format) : dayjs(formatTimeToString(time, format), format);
};

export const removeSeconds = (time: string) => {
	const timeArray = time.split(':');
	return timeArray.length === 3 ? timeArray.slice(0, -1).join(':') : time;
};
