import { Tooltip } from 'antd';

import { ReactComponent as NeedAcceptIcon } from '~images/icons/exclamation-circle-regular.svg';
import { ReactComponent as NeedScanIcon } from '~images/icons/need-scan-icon.svg';
import { ReactComponent as RescanIcon } from '~images/icons/rescan-icon.svg';
import { ReactComponent as ScanInProgressIcon } from '~images/icons/scan-in-progress-icon.svg';
import { ReactComponent as SuccessScanIcon } from '~images/icons/success-scan-icon.svg';
import type { OrderDocumentStatus } from '~server-types/doc/api/models/order';
import { orderDocumentStatusNames } from '~server-types/doc/api/models/order';
import Icon from '~shared/utils/icons';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

import { useStyles } from './styles';

// Что-то от Сокола, нет в нашем бэке
const statuses = makeDynamicTranslations({
	needScan: tDynamic('Требуется сканирование документов'),
	needApprove: tDynamic('Необходимо подтверждение приемки'),
});

type Props = {
	type: 'scan' | 'approve';
	status?: OrderDocumentStatus & keyof typeof statuses;
	tooltipTitle?: string;
};

const ICONS = {
	processing: ScanInProgressIcon,
	rescan: RescanIcon,
	done: SuccessScanIcon,
	scan: NeedScanIcon,
};

export function OrderTypeIcon({ type, status, tooltipTitle }: Props) {
	const { cx, classes: styles } = useStyles();

	const getIcon = (status: string) => {
		return (
			<Tooltip title={orderDocumentStatusNames[status]}>
				<Icon component={ICONS[status]} className={cx(styles.icon, `data-set-icon-${status}`)} />
			</Tooltip>
		);
	};

	const needScanIcon = (
		<Tooltip title={statuses.needScan}>
			<Icon component={NeedScanIcon} className={cx(styles.icon, 'data-set-icon-not-loaded')} />
		</Tooltip>
	);

	const needApproveIcon = (
		<Tooltip title={tooltipTitle ? tooltipTitle : statuses.needApprove}>
			<Icon component={NeedAcceptIcon} className={cx(styles.icon, 'data-test-icon-need-accept')} />
		</Tooltip>
	);

	if (type === 'scan') {
		return status && status in orderDocumentStatusNames ? getIcon(status) : needScanIcon;
	}

	return needApproveIcon;
}
