import { useCache } from '~cache/useCache';
import userName from '~shared/utils/userName';
import type { Stores } from '~types/stores';
import type { User } from '~types/user';

import { levelDictionary, sourceByType } from '../../constants';
import LittleText from '../LittleText';
import { useStyles } from './styles';

type Props = {
	id?: string;
	type: string;
	entity?: Stores.Cluster | User.Executor | Stores.Store;
};

const UserInfo = ({ id, type, entity }: Props) => {
	const { classes } = useStyles();
	const cache = useCache({
		stores: [],
		companies: [],
		clusters: [],
		userExecutors: [],
	});

	const sourceProps = sourceByType[type];
	const currentEntity = entity ?? cache[sourceProps.id_slice][id];

	let title = '—';
	if (sourceProps.titleProp === 'fullname') {
		title = userName(currentEntity) || '—';
	} else {
		title = currentEntity?.[sourceProps.titleProp] ?? '—';
	}

	return (
		<div className={classes.userInfoBlock}>
			<span data-test="health entity title" className={classes.levelHeader}>
				{title}
			</span>
			<LittleText dataTest="health entity type">{levelDictionary[type]}</LittleText>
		</div>
	);
};

export default UserInfo;
