import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const CouriersPage = lazy(() => import('../components/CouriersPage'));
const CouriersDataPage = lazy(() => import('../components/CouriersPage/CouriersDataPage'));
const CouriersImportDataPage = lazy(() => import('../components/CouriersPage/ImportDataPage'));

const couriersRoutes = makeRoutes([
	{
		path: '/couriers',
		permit: 'couriers_seek',
		element: <CouriersPage />,
	},
	{
		path: '/couriers/add',
		permit: 'couriers_create',
		element: <CouriersDataPage editMode addMode />,
	},
	{
		path: '/couriers/edit/:courier_id',
		permit: 'couriers_save',
		element: <CouriersDataPage editMode />,
	},
	{
		path: '/couriers/:courier_id',
		permit: 'couriers_load',
		element: <CouriersDataPage />,
	},
	{
		path: '/couriers_import/:couriers_import_id',
		permit: 'couriers_load',
		element: <CouriersImportDataPage />,
	},
	{
		path: '/stores/:store_id/couriers',
		permit: 'couriers_seek',
		element: <CouriersPage />,
	},
	{
		path: '/stores/:store_id/couriers/add',
		permit: 'couriers_create',
		element: <CouriersDataPage editMode addMode />,
	},
	{
		path: '/stores/:store_id/couriers/edit/:courier_id',
		permit: 'couriers_save',
		element: <CouriersDataPage editMode />,
	},
	{
		path: '/stores/:store_id/couriers/:courier_id',
		permit: 'couriers_load',
		element: <CouriersDataPage />,
	},
]);

export default couriersRoutes;
