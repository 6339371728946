import type { DependencyList } from 'react';

import { partnerPickupListOrders } from '~redux/actions/ordersData';
import useReduxData from '~shared/hooks/useReduxData';
import type { Orders } from '~types/orders';

type UseLoadPartnerPickupList = (partnerPickupListIsOn: boolean, deps?: DependencyList) => { orders: Orders.Order[] };

const useLoadPartnerPickupList: UseLoadPartnerPickupList = (partnerPickupListIsOn, deps?: DependencyList) => {
	const { data } = useReduxData(
		{
			asyncAction: () => partnerPickupListOrders(),
			dataKey: 'ordersData',
		},
		[...(deps ?? [])],
		!partnerPickupListIsOn
	);

	return { orders: data.partnerPickupOrders };
};

export default useLoadPartnerPickupList;
