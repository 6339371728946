import { Spin } from 'antd';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import i18n from '~/i18n';
import { checkPermit } from '~zustand/userData';

export const checkOnlyView = ({
	editMode,
	listPermit,
	loadPermit,
}: {
	editMode: boolean;
	loadPermit: string;
	listPermit: string;
}) => {
	const displayViewModeWhileInChangeMode = editMode && checkPermit(loadPermit) && !checkPermit(listPermit);
	return displayViewModeWhileInChangeMode || !editMode;
};

export const getViewValue = (value: any, dictionary: Record<string, string> = {}) => {
	if ((!value && value !== 0) || (Array.isArray(value) && !value.length)) {
		return i18n.t('Не заполнено');
	}
	return dictionary[value] ?? value;
};

export const getViewText = (
	value: string,
	path?: string,
	dictionary: Record<string, string> = {},
	loading = false,
	params?: {
		className?: string;
	}
) => {
	if (!value) {
		return loading ? (
			<Spin size="small" />
		) : (
			<span className={params?.className}>
				<Trans i18nKey="Не заполнено" />
			</span>
		);
	}

	if (path) {
		return (
			<Link to={`/${path}/${value}`} className={params?.className}>
				{getViewValue(value, dictionary)}
			</Link>
		);
	}

	return getViewValue(value, dictionary);
};
