import { makeListFn } from '~shared/utils/makeApiFns';
import type { UsersLogs } from '~types/usersLogs';

export default {
	list: makeListFn<UsersLogs.SeekRequest, UsersLogs.UsersLog>('/api/admin/users_log/list'),
	load: makeListFn<{ users_log_id: string[]; add_next?: boolean }, UsersLogs.UsersLogsLoad>(
		'/api/admin/users_log/load'
	),
	import_list: makeListFn<
		{
			user_id?: string;
			cursor?: string | null;
			obj_table?: string;
		},
		UsersLogs.UsersLog
	>('/api/admin/users_log/import_list'),
};
