import { Col, Row } from 'antd';

import type { SectionConfig } from '~constants/dataPageFields/types';

import { getColSpan } from '../Fields/utils';
import DataSection from './DataSection';
import type { DataFormProps } from './index';
import { useStyles } from './styles';

export const SectionsContent = (props: DataFormProps) => {
	const {
		disabled,
		pageName,
		colspan,
		addMode,
		editMode,
		layout,
		renderSections,
		formFields = [],
		twoColLayout,
		twoColFullWidth = false,
		additionalDataSecondCol,
		parentName,
		sectionClassName,
	} = props;
	const { classes } = useStyles({});

	const sections = (items: SectionConfig[]) =>
		items.map((item, i) => (
			<DataSection
				parentName={parentName}
				key={[parentName, i, item.label].join('')}
				section={item}
				disabled={disabled}
				pageName={pageName}
				colspan={colspan}
				addMode={addMode}
				editMode={editMode}
				classes={sectionClassName ? { ...classes, section: sectionClassName } : classes}
			/>
		));

	if (renderSections) {
		return renderSections({ editMode, addMode });
	}

	if (layout) {
		return <>{layout(sections(formFields))}</>;
	}

	if (twoColLayout) {
		return (
			<Row gutter={32} className={twoColFullWidth ? 'two-col-full-width' : ''}>
				<Col {...getColSpan(twoColLayout[0].colspan)} data-test={twoColLayout[0].sections[0]}>
					{sections(formFields.filter((field) => twoColLayout[0].sections.includes(field.label)))}
				</Col>
				<Col {...getColSpan(twoColLayout[1].colspan)} data-test={twoColLayout[1].sections[0]}>
					{sections(formFields.filter((field) => twoColLayout[1].sections.includes(field.label)))}
					{additionalDataSecondCol}
				</Col>
			</Row>
		);
	}

	return <>{sections(formFields)}</>;
};
