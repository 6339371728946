import { Col, Row, Select, Tooltip } from 'antd';
import { apiLoadProperties, getEntityTitle } from 'multiSelect/initMultiSelect';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type EntityMap, type EntityName } from '~cache/apiLoadProperties';
import { useCache } from '~cache/useCache';
import { ReactComponent as TooltipIcon } from '~images/question-circle-solid.svg';
import DeleteButton from '~shared/components/DeleteButton';
import { useStyles } from '~shared/components/Fields/styles';
import { EmptyValue, getColSpan } from '~shared/components/Fields/utils';

import type { SelectFieldProps } from '../../SelectField';
import EntitiesListToDisplay from './EntitiesListToDisplay';
import EntityLink from './EntityLink';
import { useStyles as ownUseStyles } from './styles';
import { checkOnlyView } from './utils';

const { Option } = Select;
const MultiSelect = (
	props: SelectFieldProps & {
		selectEntity: EntityName;
		searchDataProps?: Record<string, string | undefined>;
		setSearchData: Dispatch<SetStateAction<Record<string, string | undefined> | undefined>>;
		dictionary: Record<string, string>;
		fixedOptions?: string[];
		listDataTest?: string;
		tooltip?: string;
	}
) => {
	const { cx, classes } = useStyles();
	const { classes: ownClasses } = ownUseStyles();
	const [t] = useTranslation();
	const {
		editMode,
		colspan,
		fieldRequired,
		id,
		input,
		width,
		label,
		disabled,
		selectEntity,
		dictionary,
		loading,
		searchDataProps,
		setSearchData,
		options,
		fixedOptions,
		listDataTest,
		meta: { error },
		tooltip,
		disabledOptions,
	} = props;

	const [isFieldFocused, setIsFieldFocused] = useState(false);
	const entityProperties = apiLoadProperties[selectEntity];

	const value = input.value;
	//TODO: заменить title на searchField в onSearch

	// если есть пермит только на load, то в editMode показываем как во viewMode
	const onlyView = checkOnlyView({
		editMode,
		listPermit: entityProperties.listPermit,
		loadPermit: entityProperties.loadPermit,
	});

	const dictionaryKeys = Object.keys(dictionary ?? {});
	const optionsToDisplay = fixedOptions ?? (options?.length ? options : dictionaryKeys);

	const cache = useCache({
		[selectEntity]: !fixedOptions ? props.input.value : undefined,
	} as Record<keyof EntityMap, string[]>);

	return (
		<>
			<Row align="top" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={{ inlineSize: width }}>
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label
						className={cx(classes.label, {
							[classes.labelRequired]: editMode && fieldRequired,
						})}
						htmlFor={id}
					>
						{label}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
				{
					<Col {...getColSpan(colspan)}>
						{editMode && (
							<div className={cx(classes.inputContainer, 'wms-input-container')}>
								{!isFieldFocused && (
									<div
										className={cx(ownClasses.entitiesCounter, {
											[ownClasses.entitiesCounterDisabled]: disabled,
										})}
										data-test={`data form ${label} count`}
									>
										{t('Выбрано: {{count}}', { count: value?.length ?? 0 })}
									</div>
								)}
								<Select
									id={id}
									loading={loading}
									onFocus={() => setIsFieldFocused(true)}
									onBlur={() => {
										setIsFieldFocused(false);
										setSearchData(searchDataProps);
									}}
									disabled={disabled}
									data-test={`data form ${label}${disabled ? ' disabled' : ''}`}
									allowClear
									className={cx({
										[ownClasses.selectError]: !!error,
										[ownClasses.selectDisabled]: disabled,
										[ownClasses.select]: !disabled,
									})}
									showArrow={true}
									onSearch={(value) => setSearchData({ title: value || undefined })}
									popupClassName={`data-test-select-dropdown-${label.replace(/\s/g, '-')}`}
									// используем mode="multiple", который применятся для тэгов, потому что у него есть возможность протыкать варианты в дропдауне, а не выбирать по одному
									mode="multiple"
									// прописываем явно onChange и value, потому что из-за особенностей поля в режиме multiple
									// сложно одновременно и выводить список и поддерживать мультиселект с прокликанными опциями в дропдауне
									onChange={input.onChange}
									value={value}
									// чтобы рендерить не поле с тэгами, а кастомный список, используем tagRender={() => <></>}
									tagRender={() => <></>}
									// при передаче списка опций извне, извне также надо передавать и список
									filterOption={!!fixedOptions?.length}
								>
									{optionsToDisplay?.map((option) => {
										return (
											<Option
												key={option}
												value={option}
												disabled={disabledOptions?.includes(option)}
												data-test={`data form option select ${dictionary?.[option] || option}`}
											>
												{dictionary[option] ?? option}
											</Option>
										);
									})}
								</Select>
								{tooltip && (
									<div className={ownClasses.tooltipContainer}>
										<Tooltip overlayInnerStyle={{ padding: 16 }} title={tooltip}>
											<TooltipIcon className={cx(classes.labelTooltip, ownClasses.tooltipIcon)} />
										</Tooltip>
									</div>
								)}
							</div>
						)}
						{error && (
							<span
								className={cx({ [classes.inputTextError]: error }, classes.inputErrorContainer)}
								data-test={`data form ${label} error`}
							>
								{error}
							</span>
						)}
						<div>
							{!editMode && !input.value?.length && (
								<p className={classes.valueOnViewMode} data-test={listDataTest ?? `entities list ${selectEntity} view`}>
									<EmptyValue />
								</p>
							)}
							{onlyView && (
								<EntitiesListToDisplay
									entity={selectEntity}
									loading={cache[selectEntity].loading}
									ids={input.value ?? []}
									dictionary={cache[selectEntity]}
								/>
							)}
						</div>
					</Col>
				}
			</Row>
			<Row>
				<Col {...getColSpan(colspan)} />
				<Col {...getColSpan(colspan)}>
					{editMode && !disabled && (
						<ul data-test={`entities list ${selectEntity} edit`} className={ownClasses.entitiesList}>
							{input.value?.map((id: string) => {
								const entityInstance = cache[selectEntity][id];
								const title = getEntityTitle?.(selectEntity, entityInstance);

								return (
									<li
										key={id}
										className={ownClasses.viewModeListEntities}
										data-test={`${selectEntity} list item ${dictionary?.[id]}`}
									>
										<EntityLink entity={selectEntity} id={id} title={title} />
										<DeleteButton
											onClick={() => {
												input.onChange(input.value?.filter((item: string) => item !== id));
											}}
										/>
									</li>
								);
							})}
						</ul>
					)}
				</Col>
			</Row>
		</>
	);
};

export default MultiSelect;
