import { Typography } from 'antd';
import type { JSX } from 'react';

import { HeaderCenterPortal } from '~shared/components/Layout/HeaderCenterPortal';
import { useStyles as useCommonStyles } from '~styles/common';

import type { MenuButton } from '../DotsButton';
import DotsButton from '../DotsButton';
import type { PrimaryButton } from '../PrimaryButtonComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent';
import { useStyles } from './styles';

const { Title } = Typography;

interface Props {
	title: string | JSX.Element | null;
	moveToHeader?: boolean;
	primaryButtons?: PrimaryButton[];
	rightContent?: JSX.Element;
	dotsOptions?:
		| MenuButton[]
		| {
				section: string;
				buttons: MenuButton[];
				condition?: boolean;
				dataTest?: string;
		  }[];
	customText?: JSX.Element;
}

const TableHeader = ({
	title,
	moveToHeader = true,
	primaryButtons = [],
	rightContent,
	dotsOptions,
	customText,
}: Props) => {
	const { classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();

	const renderHeader = () => (
		<div className={commonClasses.header}>
			<Title level={2} className={commonClasses.title}>
				<div className={classes.titleContainer}>{title}</div>

				{!!customText && <p>{customText}</p>}
			</Title>
			<div className={classes.buttonsBlock}>
				{primaryButtons.map((button, index) => (
					<PrimaryButtonComponent key={index} button={button} inHeader />
				))}
			</div>
			{rightContent}
			{dotsOptions && (
				<DotsButton buttons={dotsOptions} className={classes.dotsOptions} dataTest="header dots button" inHeader />
			)}
		</div>
	);

	if (moveToHeader) {
		return <HeaderCenterPortal>{renderHeader()}</HeaderCenterPortal>;
	}
	return renderHeader();
};

export default TableHeader;
