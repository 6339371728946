import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

import { RedirectToOrderByExternalId } from '../components/OrdersPage/OrdersDataPage/RedirectToOrderByExternalId';

const OrdersTable = lazy(() => import('../components/OrdersPage'));
const OrdersAddDataPage = lazy(() => import('../components/OrdersPage/OrdersDataPage/OrdersAddDataPage'));
const OrdersViewDataPage = lazy(() => import('../components/OrdersPage/OrdersDataPage/OrdersViewDataPage'));
const AllOrdersTable = lazy(() => import('~shared/components/OrdersHistory'));

const ordersRoutes = makeRoutes([
	{
		path: '/orders',
		permit: 'dispatcher',
		exp: 'exp_old_disp_off',
		alternativeElement: <PrependPath />,
		element: <Navigate replace to="/orders_new" />,
	},
	{
		path: '/all_orders',
		permit: 'dispatcher',
		element: <AllOrdersTable />,
	},
	{
		path: '/orders/add',
		permit: 'dispatcher',
		element: <OrdersAddDataPage />,
	},
	{
		path: '/orders/:order_id',
		permit: 'dispatcher',
		element: <OrdersViewDataPage />,
	},
	{
		path: '/stores/:store_id/orders',
		permit: 'dispatcher',
		element: <OrdersTable />,
	},
	{
		path: '/stores/:store_id/orders/add',
		permit: 'dispatcher',
		element: <OrdersAddDataPage />,
	},
	{
		path: '/stores/:store_id/orders/:order_id',
		permit: 'dispatcher',
		element: <OrdersViewDataPage />,
	},
	{
		path: '/stores/:store_id/orders/ext/:external_id',
		permit: 'dispatcher',
		element: <RedirectToOrderByExternalId />,
	},
]);
export default ordersRoutes;
