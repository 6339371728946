/* eslint-disable max-lines */

import { equipmentDisabledReasonsNames } from '~server-types/doc/api/admin/equipments';
import { orderInventoryTypesNames, orderInventoryTypesValues } from '~server-types/doc/api/disp/orders';
import {
	extraWayPointsNames,
	extraWayPointsValues,
	orderCheckValidModeNames,
	orderCheckValidModeValues,
	orderTypeNames,
	stopListReasonsNames,
	stopListReasonsValues,
} from '~server-types/doc/api/models/order';
import { shelfTypesNames } from '~server-types/doc/api/models/shelf';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

import { SWITCH_OFF_STORAGE_EQUIPMENT_REASONS } from '../../components/Equipment/StoresEquipment/const';
import { order, orderTypesList } from '../order';
import type { SectionConfig } from './types';

export const orderFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'order_id',
				label: tDynamic('Идентификатор ордера'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'external_id',
				label: tDynamic('Идентификатор при создании'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'store_id',
				label: tDynamic('Идентификатор склада'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'type',
				label: tDynamic('Тип ордера'),
				type: 'string',
				inputType: 'select',
				defaultValue: 'order',
				canEdit: true,
				options: orderTypesList,
				dictionary: orderTypeNames,
			},
			{
				key: 'doc_number',
				label: tDynamic('Ордер №'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'doc_date',
				label: tDynamic('Дата'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'courier.name',
				label: tDynamic('Курьер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'client_address.fullname',
				label: tDynamic('Адрес'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'contractor',
				label: tDynamic('Поставщик'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'total_price',
				label: tDynamic('Сумма заказа'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'inventory_type',
				label: tDynamic('Тип инвентаризации'),
				type: 'string',
				inputType: 'select',
				options: orderInventoryTypesValues,
				dictionary: orderInventoryTypesNames,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'vars.total_order_weight',
				label: tDynamic('Вес заказа'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'request_number',
				label: tDynamic('Заказ №'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'parent',
				label: tDynamic('Родительские ордера'),
				type: 'select-multi',
				options: [],
				inputType: 'tag',
				canEdit: false,
				hidden: {
					view: false,
					edit: true,
				},
				viewType: 'link',
			},
			{
				key: 'status',
				label: tDynamic('Статус ордера'),
				type: 'string',
				inputType: 'select',
				options: Object.keys(order),
				dictionary: order,
				canEdit: true,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'estatus',
				label: tDynamic('Дополнительный статус'),
				type: 'string',
				inputType: 'text',
				hidden: true,
				canEdit: true,
			},
			{
				key: 'target',
				label: tDynamic('Целевой статус'),
				type: 'string',
				inputType: 'select',
				options: ['complete', 'canceled', 'failed'],
				dictionary: order,
				canEdit: true,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'ack',
				label: tDynamic('Исполнители'),
				type: 'string',
				inputType: 'select',
				options: [],
				canEdit: true,
				hidden: false,
			},
			{
				key: 'users',
				label: tDynamic('Исполнители'),
				type: 'string',
				inputType: 'select',
				options: [],
				viewType: 'link',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'mode',
				label: tDynamic('Режим работы'),
				type: 'string',
				inputType: 'select',
				options: orderCheckValidModeValues,
				dictionary: orderCheckValidModeNames,
				defaultValue: 'store2trash',
				canEdit: true,
				hidden: {
					view: true,
					edit: true,
				},
			},
			{
				key: 'vars.mode',
				label: tDynamic('Режим работы'),
				type: 'string',
				inputType: 'select',
				options: orderCheckValidModeValues,
				dictionary: orderCheckValidModeNames,
				canEdit: true,
				hidden: {
					view: true,
					edit: true,
				},
			},
			{
				key: 'approved',
				label: tDynamic('Подтверждён'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
				defaultChecked: true,
			},
			{
				key: 'study',
				label: tDynamic('Учебный заказ'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
				defaultChecked: false,
				hidden: {
					view: true,
					edit: false,
				},
			},
			{
				key: 'created',
				label: tDynamic('Когда был создан ордер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'updated',
				label: tDynamic('Когда был изменен ордер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},

			{
				key: 'version',
				label: tDynamic('version'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'revision',
				label: tDynamic('revision'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'serial',
				label: tDynamic('serial'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'required',
				label: tDynamic('Список товаров'),
				type: 'other',
				inputType: 'other',
				hidden: true,
			},
			{
				key: 'is_visible',
				label: tDynamic('Признак того что группа продуктов отображается'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'invoice_sum',
				label: tDynamic('К оплате клиентом'),
				type: 'other',
				inputType: 'other',
				hidden: false,
			},
			{
				key: 'invoice_payment_method_type',
				label: tDynamic('Тип оплаты'),
				type: 'other',
				inputType: 'other',
				hidden: false,
			},
			{
				key: 'attr.waybill',
				label: tDynamic('Номер ТТН'),
				type: 'other',
				inputType: 'other',
			},
			{
				key: 'attr.partner_name',
				label: tDynamic('Партнер'),
				type: 'other',
				inputType: 'other',
				hidden: false,
			},
			{
				key: 'attr.courier_pin',
				label: tDynamic('OTP-код'),
				type: 'other',
				inputType: 'other',
				hidden: false,
			},
			{
				key: 'creation_reason',
				label: tDynamic('Причина создания'),
				type: 'string',
				inputType: 'select',
				options: stopListReasonsValues,
				dictionary: stopListReasonsNames,
				hidden: true,
				canEdit: true,
			},
			{
				key: 'vars.reason',
				label: tDynamic('Причина постановки'),
				type: 'string',
				inputType: 'select',
				options: SWITCH_OFF_STORAGE_EQUIPMENT_REASONS,
				dictionary: equipmentDisabledReasonsNames,
				hidden: true,
			},
			{
				key: 'planned_time',
				label: tDynamic('Доставить до'),
				type: 'other',
				inputType: 'other',
				hidden: false,
			},
			{
				key: 'paused_until',
				label: tDynamic('Пауза'),
				type: 'other',
				inputType: 'other',
				hidden: false,
			},
			{
				key: 'user_id',
				label: tDynamic('Заказ создал'),
				type: 'string',
				inputType: 'text',
				hidden: false,
			},
			{
				key: 'user_done',
				label: tDynamic('Заказ завершил'),
				type: 'string',
				inputType: 'text',
				hidden: false,
			},
			{
				key: 'courier_id',
				label: tDynamic('Курьер'),
				type: 'string',
				inputType: 'text',
				hidden: false,
			},
			{
				key: 'attr.extra_waypoints',
				label: tDynamic('Другие точки в маршруте'),
				type: 'string',
				inputType: 'select',
				options: extraWayPointsValues,
				dictionary: extraWayPointsNames,
				hidden: true,
				canEdit: false,
				onlyView: true,
			},
			{
				key: 'client_address.fullname',
				label: tDynamic('Адрес'),
				type: 'string',
				inputType: 'text',
				hidden: false,
			},
			{
				key: 'eda_status',
				label: tDynamic('Статус доставки'),
				type: 'string',
				inputType: 'text',
				hidden: false,
			},
			{
				key: 'attr.reason',
				label: tDynamic('Причина'),
				type: 'string',
				inputType: 'text',
				hidden: false,
			},
			{
				key: 'vars.check_project_id',
				label: tDynamic('Родительский проект ЛИ'),
				type: 'string',
				inputType: 'text',
			},
			{
				key: 'vars.equipment_id',
				label: tDynamic('Оборудование'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
		],
	},
]);

export const orderRegradingFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Товары'),
		fields: [
			{
				key: 'regrading_products',
				label: '',
				type: 'other',
				inputType: 'other',
				hidden: false,
			},
		],
	},
]);

export const orderInventoryFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'order_id',
				label: tDynamic('Идентификатор ордера'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'external_id',
				label: tDynamic('Идентификатор при создании'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'store_id',
				label: tDynamic('Идентификатор склада'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'type',
				label: tDynamic('Тип ордера'),
				type: 'string',
				inputType: 'select',
				defaultValue: 'order',
				canEdit: true,
				options: orderTypesList,
				dictionary: orderTypeNames,
			},
			{
				key: 'inventory_type',
				label: tDynamic('Тип инвентаризации'),
				type: 'string',
				inputType: 'select',
				canEdit: true,
				options: orderInventoryTypesValues,
				dictionary: orderInventoryTypesNames,
				hidden: {
					view: true,
					edit: false,
				},
			},
			{
				key: 'shelf_types',
				label: tDynamic('Типы полок'),
				type: 'select-multi',
				inputType: 'tag',
				canEdit: true,
				options: [
					'store',
					'markdown',
					'kitchen_components',
					'office',
					'trash',
					'kitchen_trash',
					'review',
					'repacking',
					'cargo',
				],
				dictionary: shelfTypesNames,
				hidden: {
					view: false,
					edit: false,
				},
			},
			{
				key: 'doc_number',
				label: tDynamic('Ордер №'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'doc_date',
				label: tDynamic('Дата'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'courier.name',
				label: tDynamic('Курьер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'client_address.fullname',
				label: tDynamic('Адрес'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'contractor',
				label: tDynamic('Поставщик'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'total_price',
				label: tDynamic('Сумма заказа'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'inventory_type',
				label: tDynamic('Тип инвентаризации'),
				type: 'string',
				inputType: 'select',
				options: orderInventoryTypesValues,
				dictionary: orderInventoryTypesNames,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'request_number',
				label: tDynamic('Заказ №'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'parent',
				label: tDynamic('Родительские ордера'),
				type: 'select-multi',
				options: [],
				inputType: 'tag',
				canEdit: false,
				hidden: {
					view: false,
					edit: true,
				},
				viewType: 'link',
			},
			{
				key: 'status',
				label: tDynamic('Статус ордера'),
				type: 'string',
				inputType: 'select',
				options: Object.keys(order),
				dictionary: order,
				canEdit: true,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'estatus',
				label: tDynamic('Дополнительный статус'),
				type: 'string',
				inputType: 'text',
				hidden: true,
				canEdit: true,
			},
			{
				key: 'target',
				label: tDynamic('Целевой статус'),
				type: 'string',
				inputType: 'select',
				options: ['complete', 'canceled', 'failed'],
				dictionary: order,
				canEdit: true,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'ack',
				label: tDynamic('Исполнители'),
				type: 'string',
				inputType: 'select',
				options: [],
				canEdit: true,
				hidden: false,
			},
			{
				key: 'users',
				label: tDynamic('Исполнители'),
				type: 'string',
				inputType: 'select',
				options: [],
				viewType: 'link',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'mode',
				label: tDynamic('Режим работы'),
				type: 'string',
				inputType: 'select',
				options: orderCheckValidModeValues,
				dictionary: orderCheckValidModeNames,
				defaultValue: 'store2trash',
				canEdit: true,
				hidden: {
					view: true,
					edit: true,
				},
			},
			{
				key: 'vars.mode',
				label: tDynamic('Режим работы'),
				type: 'string',
				inputType: 'select',
				options: orderCheckValidModeValues,
				dictionary: orderCheckValidModeNames,
				canEdit: true,
				hidden: {
					view: true,
					edit: true,
				},
			},
			{
				key: 'approved',
				label: tDynamic('Подтверждён'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
				defaultChecked: true,
			},
			{
				key: 'third_party_assistance',
				label: tDynamic('Инвентаризация с внешним подсчетом для сверки'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
				defaultChecked: false,
				hidden: false,
			},
			{
				key: 'created',
				label: tDynamic('Когда был создан ордер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'updated',
				label: tDynamic('Когда был изменен ордер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},

			{
				key: 'version',
				label: tDynamic('version'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'revision',
				label: tDynamic('revision'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'serial',
				label: tDynamic('serial'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'required',
				label: tDynamic('Список товаров'),
				type: 'other',
				inputType: 'other',
				hidden: true,
			},
			{
				key: 'is_visible',
				label: tDynamic('Признак того что группа продуктов отображается'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
		],
	},
]);

export const orderAcceptanceFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'order_id',
				label: tDynamic('Идентификатор ордера'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'external_id',
				label: tDynamic('Идентификатор при создании'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'store_id',
				label: tDynamic('Идентификатор склада'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'type',
				label: tDynamic('Тип ордера'),
				type: 'string',
				inputType: 'select',
				defaultValue: 'order',
				canEdit: true,
				options: orderTypesList,
				dictionary: orderTypeNames,
			},
			{
				key: 'doc_number',
				label: tDynamic('Ордер №'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'attr.doc_number',
				label: tDynamic('Ордер №'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: true,
					edit: false,
				},
				canEdit: true,
			},
			{
				key: 'doc_date',
				label: tDynamic('Дата'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'contractor',
				label: tDynamic('Поставщик'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'attr.contractor',
				label: tDynamic('Поставщик'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: true,
					edit: false,
				},
				canEdit: true,
			},
			{
				key: 'total_price',
				label: tDynamic('Сумма заказа'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'inventory_type',
				label: tDynamic('Тип инвентаризации'),
				type: 'string',
				inputType: 'select',
				options: orderInventoryTypesValues,
				dictionary: orderInventoryTypesNames,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'request_number',
				label: tDynamic('Заказ №'),
				type: 'string',
				inputType: 'text',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'parent',
				label: tDynamic('Родительские ордера'),
				type: 'select-multi',
				options: [],
				inputType: 'tag',
				canEdit: false,
				hidden: {
					view: false,
					edit: true,
				},
				viewType: 'link',
			},
			{
				key: 'status',
				label: tDynamic('Статус ордера'),
				type: 'string',
				inputType: 'select',
				options: Object.keys(order),
				dictionary: order,
				canEdit: true,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'estatus',
				label: tDynamic('Дополнительный статус'),
				type: 'string',
				inputType: 'text',
				hidden: true,
				canEdit: true,
			},
			{
				key: 'target',
				label: tDynamic('Целевой статус'),
				type: 'string',
				inputType: 'select',
				options: ['complete', 'canceled', 'failed'],
				dictionary: order,
				canEdit: true,
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'ack',
				label: tDynamic('Исполнители'),
				type: 'string',
				inputType: 'select',
				options: [],
				canEdit: true,
				hidden: false,
			},
			{
				key: 'users',
				label: tDynamic('Исполнители'),
				type: 'string',
				inputType: 'select',
				options: [],
				viewType: 'link',
				hidden: {
					view: false,
					edit: true,
				},
			},
			{
				key: 'mode',
				label: tDynamic('Режим работы'),
				type: 'string',
				inputType: 'select',
				options: orderCheckValidModeValues,
				dictionary: orderCheckValidModeNames,
				defaultValue: 'store2trash',
				canEdit: true,
				hidden: {
					view: true,
					edit: true,
				},
			},
			{
				key: 'vars.mode',
				label: tDynamic('Режим работы'),
				type: 'string',
				inputType: 'select',
				options: orderCheckValidModeValues,
				dictionary: orderCheckValidModeNames,
				canEdit: true,
				hidden: {
					view: true,
					edit: true,
				},
			},
			{
				key: 'approved',
				label: tDynamic('Подтверждён'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
				defaultChecked: true,
			},
			{
				key: 'study',
				label: tDynamic('Учебный заказ'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
				defaultChecked: false,
				hidden: {
					view: true,
					edit: false,
				},
			},
			{
				key: 'created',
				label: tDynamic('Когда был создан ордер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'updated',
				label: tDynamic('Когда был изменен ордер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},

			{
				key: 'version',
				label: tDynamic('version'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'revision',
				label: tDynamic('revision'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'serial',
				label: tDynamic('serial'),
				type: 'string',
				inputType: 'number',
				hidden: true,
			},
			{
				key: 'required',
				label: tDynamic('Список товаров'),
				type: 'other',
				inputType: 'other',
				hidden: true,
			},
			{
				key: 'is_visible',
				label: tDynamic('Признак того что группа продуктов отображается'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
		],
	},
]);

export const orderPartRefundCreateFields: SectionConfig[] = makeDynamicTranslations([
	// поля для создания ордера частичного возврата
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'external_id',
				label: tDynamic('Идентификатор при создании'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'type',
				label: tDynamic('Тип ордера'),
				type: 'string',
				inputType: 'select',
				defaultValue: 'order',
				canEdit: false,
				onlyView: true,
				options: orderTypesList,
				dictionary: orderTypeNames,
			},
			{
				key: 'parent_order_id',
				label: tDynamic('Родительский ордер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'required',
				label: tDynamic('Список товаров'),
				type: 'other',
				inputType: 'other',
				hidden: true,
			},
		],
	},
]);

export const orderShipmentRollbackCreateFields: SectionConfig[] = makeDynamicTranslations([
	// поля для создания ордера возврата отгрузки
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'external_id',
				label: tDynamic('Идентификатор при создании'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'type',
				label: tDynamic('Тип ордера'),
				type: 'string',
				inputType: 'select',
				defaultValue: 'order',
				canEdit: false,
				onlyView: true,
				options: orderTypesList,
				dictionary: orderTypeNames,
			},
			{
				key: 'reason',
				label: tDynamic('Причина'),
				type: 'string',
				inputType: 'textarea',
				canEdit: true,
			},
			{
				key: 'parent_order_id',
				label: tDynamic('Родительский ордер'),
				type: 'string',
				inputType: 'text',
				hidden: true,
			},
			{
				key: 'required',
				label: tDynamic('Список товаров'),
				type: 'other',
				inputType: 'other',
				hidden: true,
			},
		],
	},
]);

export const suggestFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Исполнитель'),
		fields: [
			{
				key: 'user_done',
				label: tDynamic('Исполнитель'),
				type: 'string',
				inputType: 'text',
				hidden: false,
				viewType: 'link',
			},
		],
	},
	{
		label: tDynamic('Дата'),
		fields: [
			{
				key: 'created',
				label: tDynamic('Дата создания'),
				type: 'string',
				inputType: 'text',
				hidden: false,
			},
			{
				key: 'updated',
				label: tDynamic('Дата изменения'),
				type: 'string',
				inputType: 'text',
				hidden: false,
			},
		],
	},
]);
