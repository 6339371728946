import { useEffect } from 'react';

import useOrdersMonitor from '~shared/hooks/useOrdersMonitor';
import useProcessedCourierAndOrderData from '~shared/hooks/useProcessedCourierAndOrderData';
import { useStoreExecuters } from '~shared/hooks/useStoreExecuters';
import type { StorekeepersDataType } from '~shared/hooks/useStorekeepersData';
import { changeJoinedStore } from '~zustand/actions/userData';
import { useCheckPermit, useUser } from '~zustand/userData';

import Couriers from './Columns/Couriers/Couriers';
import OrdersColumn from './Columns/OrdersColumn/OrdersColumn';
import Storekeepers from './Columns/Storekeepers';
import WarehouseOperations from './Columns/WarehouseOperations/WarehouseOperations';
import { useStyles } from './styles';

interface Props {
	storeId?: string | null;
	timezone?: string | null;
}

const HealthTableau = ({ storeId, timezone }: Props) => {
	const { classes: healthTableauClasses } = useStyles();
	const user = useUser();

	const isPermitOrdersIncomplete = useCheckPermit('orders_incomplete');

	const { ordersData } = useOrdersMonitor(user.store_id!, isPermitOrdersIncomplete, true);

	useEffect(() => {
		if (storeId && user.store_id !== storeId) {
			void changeJoinedStore(storeId);
		}
	}, [storeId]);

	const { couriers, orders } = useProcessedCourierAndOrderData(ordersData);

	const { avaliable, occupied, inactive, usersOrders } = useStoreExecuters(ordersData.orders, storeId, timezone);

	return (
		<div data-test="health tablo" className={healthTableauClasses.wrapper}>
			<WarehouseOperations orders={ordersData.orders} key={storeId} storeId={storeId} />
			<OrdersColumn orders={orders} storeId={storeId} />
			<Storekeepers
				data={{ avaliable: avaliable as StorekeepersDataType['avaliable'], occupied, inactive, usersOrders }}
				storeId={storeId}
			/>
			<Couriers couriers={couriers} />
		</div>
	);
};

export default HealthTableau;
