import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const PriceListsPage = lazy(() => import('../components/PriceListsPage'));
const PriceListDataPage = lazy(() => import('../components/PriceListsPage/PriceListsDataPage'));

const priceListsRoutes = makeRoutes([
	{
		path: '/price_lists',
		permit: 'price_lists_seek',
		element: <PriceListsPage />,
	},
	{
		path: '/price_lists/add',
		permit: 'price_lists_create',
		element: <PriceListDataPage editMode addMode />,
	},
	{
		path: '/price_lists/edit/:price_list_id',
		permit: 'price_lists_save',
		element: <PriceListDataPage editMode />,
	},
	{
		path: '/price_lists/:price_list_id',
		permit: 'price_lists_load',
		element: <PriceListDataPage />,
	},
]);

export default priceListsRoutes;
