import dayjs from 'dayjs';
import type { JSX } from 'react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import courierName from '~shared/utils/courierName';

interface Props {
	lastTime?: string;
	lastTarget?: 'any_taxi' | 'any_courier' | 'any_bike' | 'any_vehicle' | string;
}

const AssignInformer = ({ lastTarget, lastTime }: Props) => {
	const [t] = useTranslation();

	// округляем по определенным правилам, описанным в ТЗ
	const countMinutes = () => {
		const diff = dayjs().diff(lastTime, 'minute', true);
		if (diff < 1.29) {
			return 1;
		} else {
			return Math.round(diff);
		}
	};

	const [counter, setCounter] = useState<number>(countMinutes());

	useEffect(() => {
		if (lastTarget && lastTarget) {
			const timer = setInterval(() => setCounter(countMinutes), 1000);
			return () => {
				clearInterval(timer);
			};
		}
	}, [lastTime, lastTarget]);

	const { couriersBrief } = useCache({
		// запрашиваем только в том случае, если в lastTarget courier_id
		couriersBrief: !!(lastTarget && lastTarget.length < 12) ? null : lastTarget,
	});

	let text: string | JSX.Element = t('Назначить курьера');
	if (lastTarget && lastTime) {
		switch (lastTarget) {
			case 'any_taxi':
				text = `${t('Выполняется поиск такси (прошло {{count}} минут)', {
					count: counter,
				})}`;
				break;
			case 'any_bike':
				text = `${t('Выполняется поиск мото/вело курьера (прошло {{count}} минут)', {
					count: counter,
				})}`;
				break;
			case 'any_vehicle':
				text = `${t('Выполняется поиск любого ТС (прошло {{count}} минут)', {
					count: counter,
				})}`;
				break;
			case 'any_courier':
				text = `${t('Выполняется поиск курьера (прошло {{count}} минут)', {
					count: counter,
				})}`;
				break;
			default:
				text = `${t('Выполняется назначение на {{courierName}} (прошло {{count}} минут)', {
					courierName: courierName(couriersBrief[lastTarget]),
					count: counter,
				})}`;
		}
	}

	return <>{text}</>;
};

export default memo(AssignInformer);
