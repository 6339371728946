import i18n from '~/i18n';
import { order as orderStatusDictionary } from '~constants/order';
import type { ClusterMapLayer } from '~server-types/doc/api/models/cluster';
import { orderDeliveryStatusNames } from '~server-types/doc/api/models/order';
import courierName from '~shared/utils/courierName';
import { escapeHtml } from '~shared/utils/escapeHtml';
import type { Couriers } from '~types/couriers';
import { checkExp } from '~zustand/userData';

import type { OrderWithExtra } from './Yandex/YandexOrdersMap';

const re = /\d+-\d+$/;
export const getShortNumber = (docNumber: string) => docNumber.match(re);

const NO_REASSIGNMENT_STATUSES = [
	'DELIVERED',
	'CANCELLED',
	'ARRIVED_TO_CUSTOMER',
	'CUSTOMER_NO_SHOW',
	'ORDER_TAKEN',
	'PICKUP',
	'UNKNOWN',
];

export const getPreset = (mapType: ClusterMapLayer, status?: string | null) => {
	const canReassignAfterTaken = checkExp('exp_order_reassign_after_taken');

	const noReassignmentStatuses = canReassignAfterTaken
		? NO_REASSIGNMENT_STATUSES.filter((e) => e !== 'ORDER_TAKEN')
		: NO_REASSIGNMENT_STATUSES;

	const color = !status || noReassignmentStatuses.includes(status) ? 'gray' : 'blue';

	if (mapType === 'yandex') {
		return `islands#${color}StretchyIcon`;
	}

	if (mapType === 'google') {
		return color;
	}
};

export const getPlacemarkBalloonBody = (
	order: OrderWithExtra,
	courier: Couriers.CourierBrief | undefined,
	header?: string
) =>
	`${
		header ? `<p style="margin-block-end: 5px; font-size: 15.6px; font-weight: bold; color: #262626">${header}</p>` : ''
	}` +
	'<div class="balloon-template" style="color: #262626">' +
	`<div><span>${i18n.t('Статус:')} </span><span style="font-weight: bold;">${
		order.status ? escapeHtml(orderStatusDictionary[order.status]) : i18n.t('Нет статуса')
	}</div>` +
	`<div><span>${i18n.t('Статус доставки:')} </span><span style="font-weight: bold;">${
		order.eda_status ? escapeHtml(orderDeliveryStatusNames[order.eda_status]) : i18n.t('Нет статуса')
	}</span></div>` +
	`<div><span>${
		order.courier_id
			? escapeHtml(courierName(courier, { showEatsId: false }))
			: order.dispatch_delivery_type === 'yandex_taxi'
				? i18n.t('Доставка на такси')
				: i18n.t('Нет курьера')
	}</span></div>` +
	'<div style="display: flex; flex-direction: column">' +
	(order.isBatched && order.batchedWith
		? `<hr /><div>${i18n.t('Другие заказы курьера')}:</div>` +
			order.batchedWith.map((num) => `<div>${num}</div>`).join('')
		: '') +
	'</div></div>';

export const createDiv = ({ classNames = [], children = [] }: { classNames?: string[]; children?: HTMLElement[] }) => {
	const div = document.createElement('div');
	div.classList.add(...classNames);
	div.append(...children);

	return div;
};
