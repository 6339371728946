import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const errorPageDictionary = makeDynamicTranslations({
	FRONT_CRUSH: {
		code: tDynamic('Сервер не отвечает'),
		text: tDynamic('В данный момент что-то не так с сервером. Мы знаем о проблеме и уже работаем над ее устранением.'),
	},
	ER_ACCESS: {
		code: tDynamic('Страница недоступна'),
		text: tDynamic('У вас нет прав доступа к данной странице'),
	},
});
