import { Button, notification } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import api from '~/api';
import { appVersion } from '~/appConfig';
import { useCache } from '~cache/useCache';
import IdReplacer from '~shared/components/IdReplacer';
import { changeJoinedStore } from '~zustand/actions/userData';
import { getUserData, useUserConstants } from '~zustand/userData';

import { errorPageDictionary } from './dictionary';
import { useStyles } from './styles';

interface Props {
	error: {
		status: number | string | null;
		code: string | null;
		text: string | null;
		errorId: string | null;
		details?: {
			store_id?: string | null;
			company_id?: string | null;
		};
	};
}

const ErrorPage = ({ error }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const constants = useUserConstants();

	const goMainPage = () => {
		navigate('/');
	};

	const cache = useCache({
		companies: error.details?.company_id,
		stores: error.details?.store_id,
	});

	const company = error.details?.company_id ? cache.companies[error.details?.company_id] : null;

	const linkToEntity = async () => {
		if (!error.details?.store_id && !error.details?.company_id) {
			goMainPage();
		}

		try {
			if (error.details?.store_id) {
				await changeJoinedStore(error.details.store_id);
			}
			if (error.details?.company_id) {
				await api.companies.joinCompany({ company_id: error.details?.company_id });

				notification.success({
					message: t('Вы прикреплены к компании «{{companyTitle}}»', {
						companyTitle: company?.title,
					}),
				});
			}
			await getUserData();
			await navigate(location, { replace: true });
		} catch {
			notification.error({
				message: error.details?.company_id
					? t('Не удалось прикрепиться к компании')
					: t('Не удалось прикрепиться к складу'),
			});
		}
	};

	const buttonText = useMemo(() => {
		if (error.details?.store_id) {
			return t('Прикрепиться к складу');
		}
		if (error.details?.company_id) {
			return t('Прикрепиться к компании');
		}
		return t('Вернуться в главное меню');
	}, [error]);

	return (
		<>
			<div data-test="error page" className={classes.paper}>
				<div />
				<div>
					<h1 className={classes.status} data-test="error status">
						{error.status}
					</h1>
					<h2 className={classes.code} data-test="error code">
						{errorPageDictionary[error.code ?? '']?.code || error.code}
					</h2>
					<p className={classes.text} data-test="error text">
						{errorPageDictionary[error.code ?? '']?.text || error.text}
					</p>
					{error.status === 403 && error.details?.company_id && (
						<p>
							<IdReplacer
								id={error.details?.company_id}
								type="companies"
								keyReplaceOn="title"
								link={`/companies/${error.details?.company_id}`}
							/>
						</p>
					)}
					{error.status === 403 && error.details?.store_id && (
						<p>
							<IdReplacer
								id={error.details?.store_id}
								type="stores"
								keyReplaceOn="title"
								link={`/stores/${error.details?.store_id}`}
							/>
						</p>
					)}
					{error.status === 403 && (
						<Button type="primary" onClick={linkToEntity} size="large" data-test="link button">
							{buttonText}
						</Button>
					)}
					{error.status === 404 && (
						<Button type="primary" onClick={goMainPage} size="large" data-test="go home button">
							{t('Вернуться в главное меню')}
						</Button>
					)}
				</div>
				<div className={classes.versionData}>
					<div>
						<p className={classes.dataTitle}>Error id</p>
						<b>{error.errorId}</b>
					</div>
					<div>
						<p className={classes.dataTitle}>UI</p>
						<b>{appVersion}</b>
					</div>
					<div>
						<p className={classes.dataTitle}>Backend</p>
						<b>{constants.backend_version}</b>
					</div>
				</div>
			</div>
		</>
	);
};

export default ErrorPage;
