import { Col, notification, Row } from 'antd';
import type { JSX } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import api from '~/api';
import { useCache } from '~cache/useCache';
import DeleteButton from '~shared/components/DeleteButton';
import type { Stores } from '~types/stores';

import type { SelectFieldProps } from '../SelectField';
import SelectWithSearch from '../SelectField/SelectWithSearch';
import { useStyles } from '../styles';
import { EmptyValue, getColSpan } from '../utils';

const StoreSelect = (props: SelectFieldProps) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const controller = useRef<AbortController | null>(null);

	const cache = useCache({
		stores: props.input.value,
	});

	const changeValue = (type: 'add' | 'remove', storeId: string) => {
		switch (type) {
			case 'add':
				props.input.onChange([...props.input.value, storeId]);
				break;
			case 'remove':
				props.input.onChange(props.input.value.filter((id: any) => id !== storeId));
		}
	};

	const searchStores = async (searchText: string) => {
		try {
			controller.current?.abort();
			controller.current = new AbortController();
			const searchData: Stores.SeekRequest = {
				cursor: '',
			};
			if (searchText) {
				searchData.title = searchText;
			}
			if (props.additionalProps?.clusterId) {
				searchData.cluster_id = props.additionalProps.clusterId;
			}
			if (props.additionalProps?.companyId) {
				searchData.company_id = props.additionalProps.companyId;
			}
			const { data } = await api.stores.list(searchData, { signal: controller.current!.signal }); // у складов title принимает как id, так и текстовое значение

			cache.addData({
				entityName: 'stores',
				data: data.results,
			});

			return data.results.map((store: Stores.Store) => ({
				value: store.store_id,
				text: store.title,
			}));
		} catch (error) {
			if (error.status !== 'CANCELED') {
				notification.error({
					message: t('Не удалось получить список складов'),
				});
			}
		}
	};

	const customLayout = (label: string, input: JSX.Element) => (
		<Row gutter={[0, { xs: 0, sm: props.editMode ? 10 : 20 }]}>
			<Col {...getColSpan(props.colspan)} className={classes.labelContainer}>
				<label className={classes.label} htmlFor={props.id}>{`${label}${props.editMode ? ':' : ''}`}</label>
				{!props.editMode ? <div className={classes.dotSeparator} /> : ''}
			</Col>
			<Col {...getColSpan(props.colspan)} className={classes.readOnlyFieldContainer}>
				{props.editMode && input}
				<ul
					data-test={`store list ${label}`}
					className={classes.storesList}
					style={{ marginBlockStart: props.editMode ? 16 : 0 }}
				>
					{props.input.value && props.input.value.length
						? props.input.value.map((storeId: string) => {
								const storeObj = cache.stores[storeId];
								return (
									<li
										key={storeId}
										className={classes.storesListItem}
										data-test={`store list item ${storeObj ? storeObj.title : storeId}`}
									>
										<Link to={`/stores/${storeId}`} className={classes.storeListLink}>
											{storeObj ? storeObj.title : storeId}
										</Link>
										{props.editMode && <DeleteButton onClick={() => changeValue('remove', storeId)} />}
									</li>
								);
							})
						: !props.editMode && (
								<p className={classes.valueOnViewMode} data-test={`store list item ${label}`}>
									<EmptyValue />
								</p>
							)}
				</ul>
			</Col>
		</Row>
	);

	const getValue = (value: string | string[]) => {
		if (Array.isArray(value)) {
			return cache.stores[value[0]]?.title ?? value[0];
		}
		if (typeof value === 'string') {
			return cache.stores[value]?.title ?? value;
		}
		return value;
	};

	return (
		<div>
			<SelectWithSearch
				customLayout={props.additionalProps?.mode === 'multiple' ? customLayout : undefined}
				searchFunc={searchStores}
				onClear={() =>
					props.input.onChange(props.additionalProps?.isArray || props.additionalProps?.mode === 'multiple' ? [] : null)
				}
				onSelect={(storeId: string) => {
					if (props.additionalProps?.mode === 'multiple' && !props.input.value.includes(storeId)) {
						changeValue('add', storeId);
					}
					if (props.additionalProps?.mode === 'single') {
						// TODO LAVKADEV-9424: массив для обратной совместимости с бэком, нужно будет выпилить этот if
						if (props.additionalProps?.isArray) {
							props.input.onChange([storeId]);
						} else {
							props.input.onChange(storeId);
						}
					}
				}}
				{...props}
				input={{
					...props.input,
					value:
						props.additionalProps?.mode === 'multiple'
							? t('Выбран {{count}} склад', {
									count: props.input.value?.length ?? 0,
								})
							: getValue(props.input.value),
					onChange: () => {},
				}}
			/>
		</div>
	);
};

export default StoreSelect;
