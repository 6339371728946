import axios from 'axios';

import type { Dxgy } from '~types/dxgy';

const X_WMS_PROXYPATH = 'X-WMS-ProxyPath';
const X_WMS_CLUSTER_ID = 'X-WMS-Cluster-Id';
const DXGY_PROXY = '/api/admin/salaries/dxgy/proxy';
const DXGY_HOST = '/admin/grocery-dxgy-registry';

export default {
	// Поправить ручку лист, как будет готов бек логистики
	list: ({ cluster_id, ...params }: any) =>
		axios.get<{ results: Dxgy.DxgyBack[]; cursor: string }>(`${DXGY_HOST}/...`, {
			params,
			headers: { [X_WMS_CLUSTER_ID]: cluster_id },
		}),
	load: ({ cluster_id, ...data }: Dxgy.LoadRequest) =>
		axios.post<Dxgy.DxgyBack>(DXGY_PROXY, data, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/drafts/v1/view`,
				[X_WMS_CLUSTER_ID]: cluster_id,
			},
		}),
	save: (dxgy: Dxgy.SaveRequest) =>
		axios.post(DXGY_PROXY, dxgy, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/drafts/v1/create`,
				[X_WMS_CLUSTER_ID]: dxgy.cluster_id,
			},
		}),
	edit: (dxgy: Dxgy.SaveRequest) =>
		axios.post(DXGY_PROXY, dxgy, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/drafts/v1/edit`,
				[X_WMS_CLUSTER_ID]: dxgy.cluster_id,
			},
		}),
	changeStatus: ({ cluster_id, ...dxgy }: Dxgy.ChangeStatusRequest) =>
		axios.post(DXGY_PROXY, dxgy, {
			headers: {
				[X_WMS_PROXYPATH]: `${DXGY_HOST}/drafts/v1/change-status`,
				[X_WMS_CLUSTER_ID]: cluster_id,
			},
		}),
};
