import { notification } from 'antd';
import dayjs from 'dayjs';
import type { JSX } from 'react';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import { checkProjectOrdersTableHeaders } from '~constants/order';
import { orderStatusNames, orderTypeNames } from '~server-types/doc/api/models/order';
import SelectField from '~shared/components/Fields/SelectField';
import { Field, Form } from '~shared/components/Forms';
import OrderModal from '~shared/components/OrdersHistory/OrderModal';
import TablePagination from '~shared/components/TablePagination';
import useSubscribe from '~shared/hooks/useSubscribe';
import type { ProColumns } from '~shared/ui-kit/ProTable';
import { ProTable } from '~shared/ui-kit/ProTable';
import type { Event } from '~shared/utils/ev';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import renderStatus from '~shared/utils/renderStatus';
import userName from '~shared/utils/userName';
import type { Orders } from '~types/orders';

import JoinStoreDialog from '../../StoresPage/modals/JoinStoreDialog';
import { useStyles } from '../styles';
import DefibrillationRegrading from './Defibrillation';
import ExportCSV from './ExportCSV';
import Filter from './Filter';
import { checkProjectsOrderStatusColor, statusOrder } from './pageConfig';
import type { ExportOrderProps } from './types';
import {
	checkProjectOrdersConfig,
	checkProjectOrdersFilteredByDate,
	checkProjectOrdersNewestDate,
	checkProjectOrdersUniqueDates,
	sortByStatusAndProcessingStatusTime,
} from './utils';

interface Props {
	cursor: string;
	setCursor: (cursor: string) => void;
	checkProjectId: string;
	userStoreId?: string;
	isRegradingType: boolean;
	failedOrdersExists: boolean;
	checkProjectOrders: Orders.Order[];
	loading: boolean;
	updateCheckProjectOrders: () => void;
	setSearchData: (value: { status: keyof typeof orderStatusNames | undefined }) => void;
}

const CheckProjectOrders = ({
	cursor,
	setCursor,
	checkProjectId,
	userStoreId,
	isRegradingType,
	failedOrdersExists,
	checkProjectOrders,
	loading,
	updateCheckProjectOrders,
	setSearchData,
}: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [valuesToShow, setValuesToShow] = useState<string[]>([]);
	const [orderIdToShow, setOrderIdToShow] = useState<string | undefined>(undefined);
	const [filtered, setFiltered] = useState<boolean>(false);
	const [orderStoreId, setOrderStoreId] = useState<string>('');
	const [showJoinStore, setShowJoinStore] = useState<boolean>(false);

	const uniqueDates = checkProjectOrdersUniqueDates(checkProjectOrders);
	const newestDate = checkProjectOrdersNewestDate(uniqueDates);
	const filteredData = checkProjectOrders.filter((el) => newestDate === el.doc_date);
	const filteredOrders = checkProjectOrdersUniqueDates(filteredData);

	useEffect(() => {
		setValuesToShow(filteredOrders);
		setFiltered(!!filteredOrders.length);
	}, [filteredOrders.join('')]);

	const filteredByDate = checkProjectOrdersFilteredByDate(checkProjectOrders, valuesToShow);

	const cache = useCache({
		stores: {
			ids: checkProjectOrders?.map((order) => order.store_id),
			_fields: ['store_id', 'cluster', 'address', 'title'],
		},
		userExecutors: checkProjectOrders.flatMap((order) => order.acks ?? []),
	});

	const config = checkProjectOrdersConfig(checkProjectOrders);
	const orders = filteredOrders?.length
		? sortByStatusAndProcessingStatusTime(filteredByDate)
		: sortByStatusAndProcessingStatusTime(checkProjectOrders);

	const exportOrders: ExportOrderProps[] = checkProjectOrders.map((order) => {
		return {
			status: order.status,
			created: formatTimeAndYear(order.created) ?? '—',
			begin_date: formatTimeAndYear(order.status_time?.processing) ?? '—',
			end_date: formatTimeAndYear(order.status_time?.complete) ?? '—',
			cluster: cache.stores[order.store_id]?.cluster ?? '—',
			address: cache.stores[order.store_id]?.address ?? '—',
			type: orderTypeNames[order.type],
			doc_number: order.doc_number,
			products: order.products?.length,
			executers: order.acks[0] ? userName(cache.userExecutors[order.acks[0]]) || '—' : '—',
		};
	});

	const columns: ProColumns<Orders.Order>[] = [
		{
			title: t('Статус'),
			dataIndex: 'status',
			key: 'status',
			render: (text) => renderStatus(text, orderStatusNames, checkProjectsOrderStatusColor),
			sorter: (a, b) => statusOrder[a.status] - statusOrder[b.status],
		},
		{
			title: t('Создан'),
			dataIndex: 'created',
			key: 'created',
			render: (date) => formatTimeAndYear(date),
			sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
		},
		{
			title: t('Взят в работу'),
			dataIndex: 'status_time',
			key: 'statusTimeProcessing',
			render: (status_time) => formatTimeAndYear(status_time?.processing) ?? '—',
			sorter: (a, b) => dayjs(a.status_time?.processing).diff(dayjs(b.status_time?.processing)),
		},
		{
			title: t('Завершен'),
			dataIndex: 'status_time',
			key: 'statusTimeComplete',
			render: (status_time) => formatTimeAndYear(status_time?.complete) ?? '—',
			sorter: (a, b) => dayjs(a.status_time?.complete).diff(dayjs(b.status_time?.complete)),
		},
		{
			title: t('Кластер'),
			dataIndex: 'store_id',
			key: 'store_id',
			render: (storeId) => cache.stores[storeId]?.cluster ?? '—',
			sorter: (a, b) => {
				const clusterA = cache.stores[a.store_id]?.cluster ?? '';
				const clusterB = cache.stores[b.store_id]?.cluster ?? '';
				return clusterA.localeCompare(clusterB);
			},
		},
		{
			title: t('Адрес склада'),
			dataIndex: 'store_id',
			key: 'store_id',
			render: (storeId) => cache.stores[storeId]?.address ?? '—',
			sorter: (a, b) => {
				const addressA = cache.stores[a.store_id]?.address;
				const addressB = cache.stores[b.store_id]?.address;
				if (addressA && addressB) return addressA.localeCompare(addressB);
				if (addressA) return 1;
				if (addressB) return -1;
				return 0;
			},
		},
		{
			title: t('Вид операции'),
			dataIndex: 'type',
			key: 'type',
			render: (_, { type }) => orderTypeNames[type],
			sorter: (a, b) => {
				if (orderTypeNames[a.type] && b.type) return orderTypeNames[a.type].localeCompare(orderTypeNames[b.type]);
				if (orderTypeNames[a.type]) return 1;
				if (orderTypeNames[b.type]) return -1;
				return 0;
			},
		},
		{
			title: t('Номер'),
			dataIndex: 'doc_number',
			key: 'doc_number',
			render: (docNumber) => docNumber,
			sorter: (a, b) => a.doc_number.localeCompare(b.doc_number),
		},
		{
			title: t('Позиций'),
			dataIndex: 'products',
			key: 'products',
			render: (products) => products.length,
		},
		{
			title: t('Исполнители'),
			dataIndex: 'acks',
			key: 'acks',
			render: (acks) => userName(cache.userExecutors[acks[0]]) || '—',
		},
	];

	const subscribeCallback = (event: Event) => {
		if (checkProjectId === event.data[0]?.check_project_id) {
			updateCheckProjectOrders();
			notification.success({
				message: t('Документы Рекласса перезапущены.'),
				description: t('Перейдите в Проекты контроля и в нужный проект, чтобы посмотреть результаты'),
			});
		}
	};

	useSubscribe(
		{
			key: ['check_project', 'check_project', checkProjectId],
			cb: subscribeCallback,
			unSub: false,
			single: true,
		},
		[checkProjectId, loading]
	);

	const form = useForm<{ status: keyof typeof orderStatusNames }>();
	const formValues = form.watch();

	useEffect(() => {
		setSearchData({ status: formValues.status });
	}, [formValues.status]);
	const layout = (label: string, input: JSX.Element) => {
		return <div className={classes.statusField}>{input}</div>;
	};

	return (
		<>
			{showJoinStore ? (
				<JoinStoreDialog
					visible={showJoinStore}
					selectedStore={{
						store_id: orderStoreId,
						title: cache.stores[orderStoreId]?.title ?? '—',
					}}
					closeModal={() => setShowJoinStore(false)}
					usersStoreTitle={cache.stores[userStoreId ?? '']?.title ?? '—'}
				/>
			) : (
				<OrderModal setOrderIdToShow={setOrderIdToShow} orderIdToShow={orderIdToShow} />
			)}
			<ProTable
				rowKey="check_projects_orders"
				tableLayout="auto"
				columns={columns}
				loading={loading}
				data-test="check_projects_orders table"
				dataSource={orders}
				onRow={(order: Orders.Order) => {
					return {
						onClick: () => {
							setShowJoinStore(order.store_id !== userStoreId);
							setOrderStoreId(order.store_id);
							order.store_id === userStoreId && setOrderIdToShow(order.order_id);
						},
					};
				}}
				scroll={{
					x: true,
				}}
				toolBarRender={() => [
					<TablePagination
						key="pagination"
						itemsAmount={orders.length}
						loading={loading}
						loadPage={setCursor}
						currentCursor={cursor}
					/>,
					<DefibrillationRegrading
						key="defibrillation"
						checkProjectLoading={loading}
						visible={isRegradingType}
						failedOrdersExists={failedOrdersExists}
						checkProjectId={checkProjectId}
					/>,
					orders && (
						<ExportCSV
							headers={checkProjectOrdersTableHeaders}
							exportOrders={exportOrders}
							checkProjectId={checkProjectId}
						/>
					),
					<Form key="form" data-test="ordersFilter" hookForm={form}>
						<Field
							name="status"
							dataTest="filter select status"
							placeholder={t('Статус')}
							customLayout={layout}
							editMode
							allowClear
							options={Object.keys(orderStatusNames)}
							dictionary={orderStatusNames}
							component={SelectField}
							showSearch={true}
						/>
					</Form>,
					<Filter
						key="created"
						allValues={Object.keys(config)}
						valuesToShow={valuesToShow}
						onChange={setValuesToShow}
						dictionary={config}
						filtered={filtered}
						dataTest="check_project_orders table status filter"
					/>,
				]}
			/>
		</>
	);
};

export default memo(CheckProjectOrders);
