import { blue } from '@ant-design/colors';
import { Button, Checkbox, Input, Radio } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { Dispatch, SetStateAction } from 'react';
import { useRef, useState } from 'react';

import i18n from '~/i18n';
import DatePicker from '~shared/components/DayJsComponents/DatePicker';
import { SearchOutlined } from '~shared/utils/icons';
import { setTableFilters } from '~zustand/tableFilters';

import { checkIsWmsId } from '../ids';
import { placeholderMap, saveFilterInLocalStorage } from './utils';

const { RangePicker } = DatePicker;

// TODO: LAVKADEV-8575: Переписать columnSearch как хук
export default function <T extends Record<string, any>>(
	searchValues: T,
	handleSearch: (arg: T) => void | Dispatch<SetStateAction<T>>,
	type: 'text' | 'component' | 'radio' | 'checkbox' | 'date_range' | 'date' = 'text',
	options?: {
		label: string;
		value: string[] | string | boolean;
	}[],
	persistState?: {
		reduxKey?: string;
		localStorageKey?: string;
	},
	component?: (
		value: any,
		setSelectedKeys: (value: any[]) => void,
		resetComponent: boolean,
		setResetComponent: (resetComponent: boolean) => void
	) => any,
	additionalOptions?: {
		[key: string]: any;
		rangePickerFormat?: string;
		datePickerFormat?: string;
		datePickerDataFormat?: string;
		datePickerShowTime?: boolean;
		rangePickerAllowEmpty?: [boolean, boolean];
		rangePickerValueFormatter?: (value: { from: string | null; to: string | null }) => [Dayjs | null, Dayjs | null];
		onDateRangeChange?: (
			date: any,
			dateString: [string, string],
			setSelectedKeys: (value: [Dayjs | null, Dayjs | null] | [{ from: Dayjs | null; to: Dayjs | null }]) => void
		) => void;
		setWasFilterOpened?: (value: boolean) => void;
		hide?: boolean;
	}
) {
	return function useColumnSearch(
		dataIndex: keyof T & string,
		placeHolderKey: string = '',
		excludeDataIndex?: keyof T & string
	) {
		const inputRef = useRef<any>(null);
		let inputPlaceholder = '';
		const [searchType, setSearchType] = useState<'search' | 'exclude'>('search');
		const [resetComponent, setResetComponent] = useState(false);
		//  сохранение фильтра в редакс
		let setReduxValue: (filterValues: Record<string, any>) => void;
		if (persistState?.reduxKey) {
			setReduxValue = (filterValues) =>
				setTableFilters({
					filterKey: persistState.reduxKey!,
					filterValues,
				});
		}

		if (additionalOptions?.hide) {
			return {};
		}

		return {
			onFilterDropdownOpenChange: (visible: boolean) => {
				setTimeout(() => {
					if (inputRef.current && visible) {
						inputRef.current.focus();
					}
				}, 10);
			},
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
				const resetHandler = () => {
					setResetComponent(true);
					if (['user_id', 'courier_id'].includes(dataIndex) && !checkIsWmsId(selectedKeys[0])) {
						dataIndex = 'last_name';
					}
					const values = {
						...searchValues,
						[dataIndex]: undefined,
					};
					if (excludeDataIndex) {
						delete values[excludeDataIndex];
					}
					persistState?.localStorageKey && saveFilterInLocalStorage(persistState.localStorageKey, values);
					setReduxValue && setReduxValue(values);
					handleSearch(values);
					clearFilters();
					setSelectedKeys([]);
					// Ниже - стандартная функция анта. Добавлена для корректной работы кнопки "Сброс".
					// Без нее по щелчку срабатывает сброс фильтра, но поле ввода дропдауна сохраняет ранее введенное значение для фильтра + иконка фильтра остается подсвеченной.
					confirm();
				};

				const searchAction = (buttonClicked?: 'search' | 'exclude') => {
					if (type === 'checkbox' && Array.isArray(selectedKeys[0]) && !selectedKeys[0].length) {
						resetHandler();
						return;
					}
					confirm();
					if (['user_id', 'courier_id'].includes(dataIndex) && !checkIsWmsId(selectedKeys[0])) {
						dataIndex = 'last_name';
					}
					if (excludeDataIndex) {
						delete searchValues[excludeDataIndex];
					}
					delete searchValues[dataIndex];
					const values = {
						...searchValues,
						[buttonClicked === 'exclude' && excludeDataIndex ? excludeDataIndex : dataIndex]: selectedKeys[0],
					};
					persistState?.localStorageKey && saveFilterInLocalStorage(persistState.localStorageKey, values);
					setReduxValue && setReduxValue(values);
					handleSearch(values);
				};
				let inputContent;

				switch (type) {
					case 'radio':
						inputContent = (
							<Radio.Group
								style={{ display: 'block', marginBlockEnd: 20 }}
								onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
								value={selectedKeys[0] || searchValues[dataIndex]}
							>
								{options?.map((option, index) => {
									return (
										<Radio
											key={index}
											style={{
												display: 'block',
												blockSize: '30px',
												lineHeight: '30px',
											}}
											data-test={`${dataIndex} ${option.value} radio btn`}
											value={option.value}
										>
											{option.label}
										</Radio>
									);
								})}
							</Radio.Group>
						);
						break;
					case 'checkbox':
						inputContent = (
							<>
								{excludeDataIndex && (
									<Radio.Group
										style={{ inlineSize: '100%', minInlineSize: '264px', paddingBlockEnd: 0 }}
										value={searchType}
										onChange={(e) => {
											setSearchType(e.target.value);
										}}
									>
										<Radio.Button value="search" style={{ inlineSize: '50%', textAlign: 'center' }}>
											{i18n.t('Выбpaть')}
										</Radio.Button>
										<Radio.Button value="exclude" style={{ inlineSize: '50%', textAlign: 'center' }}>
											{i18n.t('Исключить')}
										</Radio.Button>
									</Radio.Group>
								)}
								<Checkbox.Group
									style={{
										display: 'block',
										marginBlockEnd: 20,
										maxBlockSize: 500,
										overflowX: 'hidden',
									}}
									onChange={(value) => setSelectedKeys([value])}
									value={selectedKeys[0] || searchValues[dataIndex]}
								>
									{options?.map((option, index) => {
										return (
											<Checkbox
												key={index}
												style={{
													display: 'flex',
													blockSize: '30px',
													lineHeight: '30px',
													marginInlineStart: 0,
												}}
												data-test={`${dataIndex} ${option.value} checkbox btn`}
												value={option.value}
											>
												{option.label}
											</Checkbox>
										);
									})}
								</Checkbox.Group>
							</>
						);
						break;
					case 'text':
						inputPlaceholder = i18n.t('Поиск по {{placeholderKey}}', {
							placeholderKey: placeholderMap[placeHolderKey ? placeHolderKey : dataIndex] || dataIndex,
						});

						inputContent = (
							<Input
								ref={inputRef}
								placeholder={inputPlaceholder}
								data-test={`${dataIndex} search field`}
								value={selectedKeys[0] ?? searchValues[dataIndex]}
								onChange={(e) => {
									setSelectedKeys([e.target.value]);
								}}
								onPressEnter={() => searchAction()}
								style={{
									marginBlockEnd: 8,
									display: 'block',
								}}
							/>
						);
						break;
					case 'component':
						inputContent = component?.(
							selectedKeys[0] || searchValues[dataIndex],
							setSelectedKeys,
							resetComponent,
							setResetComponent
						);
						break;
					case 'date': {
						const onDateChange = (date: any) => {
							setSelectedKeys([date.format(additionalOptions?.datePickerDataFormat) || '']);
						};
						inputContent = (
							<div style={{ display: 'block', marginBlockEnd: 16 }}>
								<DatePicker
									ref={inputRef}
									showTime={additionalOptions?.datePickerShowTime}
									format={additionalOptions?.datePickerFormat}
									value={
										selectedKeys[0] || searchValues[dataIndex]
											? (dayjs(
													selectedKeys[0] || searchValues[dataIndex],
													additionalOptions?.datePickerDataFormat
												) as any)
											: undefined
									}
									onChange={onDateChange}
								/>
							</div>
						);
						break;
					}
					case 'date_range': {
						const format = additionalOptions?.datePickerShowTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
						const onDateRangeChange =
							additionalOptions?.onDateRangeChange ||
							((date, dateString, setSelectedKeys: (value: [Dayjs | null, Dayjs | null]) => void) => {
								let dateValue: any;
								try {
									dateValue = dateString
										? [
												{
													from: date[0]?.format(format) || null,
													to: date[1]?.format(format) || null,
												},
											]
										: [];
								} catch {
									dateValue = [];
								}
								setSelectedKeys(dateValue);
							});
						const value = selectedKeys[0] || searchValues[dataIndex];
						const valueFormatter =
							additionalOptions?.rangePickerValueFormatter ||
							((value) => {
								return [
									value?.from ? (dayjs(value?.from, format) as any) : null,
									value?.to ? (dayjs(value?.to, format) as any) : null,
								];
							});
						const formattedValue = valueFormatter(value);
						inputContent = (
							<div style={{ display: 'block', marginBlockEnd: 8 }}>
								<RangePicker
									ref={inputRef}
									allowEmpty={additionalOptions?.rangePickerAllowEmpty}
									showTime={additionalOptions?.datePickerShowTime}
									format={additionalOptions?.rangePickerFormat || 'DD.MM.YYYY'}
									value={formattedValue}
									onChange={(value, dateString) => onDateRangeChange(value, dateString, setSelectedKeys)}
								/>
							</div>
						);
					}
				}
				return (
					<div style={{ padding: 8 }}>
						{inputContent}
						<div style={{ display: 'flex', justifyContent: 'center', minInlineSize: '160px', gap: '8px' }}>
							<Button
								data-test={`${dataIndex} search btn`}
								type="primary"
								onClick={() => searchAction(searchType)}
								icon={<SearchOutlined />}
								size="small"
								style={{ inlineSize: '100%' }}
							>
								{i18n.t('Поиск')}
							</Button>
							<Button
								data-test={`${dataIndex} clear btn`}
								onClick={resetHandler}
								size="small"
								style={{ inlineSize: '100%' }}
							>
								{i18n.t('Сброс')}
							</Button>
						</div>
					</div>
				);
			},
			onFilter: () => true,
			filterIcon: (filtered: any) => {
				return (
					<div
						style={{
							blockSize: '30px',
							inlineSize: '30px',
							backgroundColor: filtered || searchValues[dataIndex] ? blue[4] : undefined,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						onClick={() => additionalOptions?.setWasFilterOpened?.(true)}
					>
						<SearchOutlined
							data-test={`${dataIndex} search trigger`}
							style={{
								color: filtered || searchValues[dataIndex] ? '#fff' : undefined,
							}}
						/>
					</div>
				);
			},
		} as const;
	};
}
