import { useState } from 'react';

import api from '~/api';
import type { orderStatusNames } from '~server-types/doc/api/models/order';
import useLoadData from '~shared/hooks/useLoadData';
import useRerender from '~shared/hooks/useRerender';
import { useUser } from '~zustand/userData';

import CheckProjectOrders from '../../CheckProjectOrders';
import type { CheckProjectFormValues } from '../types';

type Props = {
	checkProjectId: string;
	checkProjectType: CheckProjectFormValues['check_project_type'];
};

export function OrdersSection({ checkProjectId, checkProjectType }: Props) {
	const user = useUser();
	const [cursor, setCursor] = useState<string>('');

	const { rerenderKey: checkProjectUpdated, updateRerenderKey: updateCheckProjectOrders } = useRerender();
	const [searchData, setSearchData] = useState<{ status: keyof typeof orderStatusNames | undefined }>({
		status: undefined,
	});
	const checkProjectOrders = useLoadData(
		() => api.checkProjects.find_orders({ check_project_id: checkProjectId, ...searchData, cursor }),
		[cursor, checkProjectId, checkProjectUpdated, Object.values(searchData).join('')],
		!checkProjectId
	);

	const failedOrdersExists = !!checkProjectOrders.data?.orders.some((order) => order.status === 'failed');

	return (
		<CheckProjectOrders
			setSearchData={setSearchData}
			cursor={cursor}
			setCursor={setCursor}
			loading={checkProjectOrders.loading}
			updateCheckProjectOrders={updateCheckProjectOrders}
			checkProjectOrders={checkProjectOrders.data?.orders ?? []}
			checkProjectId={checkProjectId}
			userStoreId={user?.store_id}
			isRegradingType={checkProjectType === 'regrading'}
			failedOrdersExists={failedOrdersExists}
		/>
	);
}
