import { useMemo } from 'react';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import useLoadData from '~shared/hooks/useLoadData';
import { getStoresData, useStoresData } from '~zustand/storesData';

export type TreeNodesIdMap = Record<string, LocationRowType>;

export interface LocationRowType {
	key: string;
	title: string;
	external_id: string;
	entity_type: 'store' | 'cluster' | 'company';
	children?: LocationRowType[];
}

export default function (companyId?: string) {
	const { stores: storesList, loading: storesLoading } = useStoresData(companyId);

	useAsyncEffect(async () => {
		if (companyId) {
			await getStoresData(companyId);
		}
	}, [companyId]);

	const { data: clustersResult, loading: clustersLoading } = useLoadData(
		() => api.clusters.list({ company_id: companyId }),
		[],
		!companyId
	);

	const clustersList = clustersResult?.results;

	const cache = useCache({
		companies: companyId,
	});

	const company = cache.companies[companyId ?? ''];

	const treeData: {
		locationTree: LocationRowType[];
		treeNodesIdMap: TreeNodesIdMap;
	} = useMemo(() => {
		if (!clustersList?.length || !storesList.length || !company || !companyId) {
			return { locationTree: [], treeNodesIdMap: {} };
		}

		const locationTree: LocationRowType[] = [
			{
				key: companyId,
				external_id: company.external_id,
				title: company.title ?? '',
				entity_type: 'company',
				children: [],
			},
		];

		const root = locationTree[0];
		const treeNodesIdMap: TreeNodesIdMap = { [companyId as string]: root };

		const clusterStoreMap = clustersList.reduce((map, cluster) => {
			if (cluster?.cluster_id) {
				map[cluster.cluster_id] = [];
			}
			return map;
		}, {});

		for (const store of storesList) {
			if (store?.cluster_id && clusterStoreMap[store.cluster_id]) {
				const storeNode = {
					key: store.store_id,
					external_id: store.external_id,
					title: store.title,
					entity_type: 'store' as const,
				};
				clusterStoreMap[store.cluster_id].push(storeNode);
				treeNodesIdMap[store.store_id] = storeNode;
			}
		}

		for (const cluster of clustersList) {
			const clusterNode = {
				key: cluster.cluster_id,
				external_id: cluster.external_id,
				title: cluster.title,
				entity_type: 'cluster' as const,
				children: clusterStoreMap[cluster.cluster_id],
			};
			root.children!.push(clusterNode);
			treeNodesIdMap[cluster.cluster_id] = clusterNode;
		}

		return { locationTree, treeNodesIdMap };
	}, [clustersList, storesList, company, companyId]);

	return {
		locationTree: treeData.locationTree,
		treeNodesIdMap: treeData.treeNodesIdMap,
		loading: storesLoading || clustersLoading,
		storesList,
	};
}
