import { lazy } from 'react';

import NotFound from '~shared/components/ErrorPage/NotFound';
import { useCheckPermit } from '~zustand/userData';

import { useCashConfiguration } from '../WalletPage/utils';

const WalletPage = lazy(() => import('../WalletPage'));
const CourierLogPage = lazy(() => import('../WalletPage/CourierLogPage/CourierLogPage'));

const WalletFinanceStorePage = lazy(() => import('./WalletFinanceStorePage'));
const WalletFinanceCourierPage = lazy(() => import('./WalletFinanceCourierPage'));

export const WalletStorePage = () => {
	const cashConfig = useCashConfiguration();
	const isPermitWalletsLoad = useCheckPermit('wallets_load');

	if (!cashConfig.isConfigsReady) {
		return null;
	}

	if (cashConfig.isNewWalletUI) {
		return <WalletFinanceStorePage cashConfig={cashConfig} />;
	}

	/**
	 * В роутах изменился пермит на показ страницы с wallets_load на finance_wallet_access.
	 * finance_wallet_access расширяет wallets_load и используется для нового UI.
	 * Поэтому в случае старого UI добавляем проверку на старый пермит, чтобы не сломать текущее поведение
	 */
	return isPermitWalletsLoad ? <WalletPage /> : <NotFound />;
};

export const WalletCourierPage = () => {
	const cashConfig = useCashConfiguration();
	const isPermitWalletsLoad = useCheckPermit('wallets_load');

	if (!cashConfig.isConfigsReady) {
		return null;
	}

	if (cashConfig.isNewWalletUI) {
		return <WalletFinanceCourierPage cashConfig={cashConfig} />;
	}

	/**
	 * В роутах изменился пермит на показ страницы с wallets_load на finance_wallet_access.
	 * finance_wallet_access расширяет wallets_load и используется для нового UI.
	 * Поэтому в случае старого UI добавляем проверку на старый пермит, чтобы не сломать текущее поведение
	 */
	return isPermitWalletsLoad ? <CourierLogPage /> : <NotFound />;
};
