import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { ProductScope } from '~types/common';

export const clustersTranslations = makeDynamicTranslations({
	Москва: tDynamic('Москва'),
	'Санкт-Петербург': tDynamic('Санкт-Петербург'),
	Казань: tDynamic('Казань'),
	'Ростов-на-Дону': tDynamic('Ростов-на-Дону'),
	'Нижний Новгород': tDynamic('Нижний Новгород'),
	Париж: tDynamic('Париж'),
	'Тель-Авив': tDynamic('Тель-Авив'),
	Йоханнесбург: tDynamic('Йоханнесбург'),
	Краснодар: tDynamic('Краснодар'),
	Воронеж: tDynamic('Воронеж'),
	Екатеринбург: tDynamic('Екатеринбург'),
	Уфа: tDynamic('Уфа'),
	Новосибирск: tDynamic('Новосибирск'),
	Лондон: tDynamic('Лондон'),
	Иркутск: tDynamic('Иркутск'),
	Рязань: tDynamic('Рязань'),
	Riyadh: tDynamic('Эр-Рияд'),
	Dubai: tDynamic('Дубай'),
});

export const clustersMapData = {
	Москва: {
		center: [37.64, 55.76],
		zoom: 11,
	},
	'Санкт-Петербург': {
		center: [30.32, 59.94],
		zoom: 11,
	},
	Казань: {
		center: [49.09, 55.76],
		zoom: 11,
	},
	'Ростов-на-Дону': {
		center: [39.65, 47.25],
		zoom: 11,
	},
	'Нижний Новгород': {
		center: [43.83, 56.3],
		zoom: 11,
	},
	Париж: {
		center: [2.35, 48.86],
		zoom: 11,
	},
	'Тель-Авив': {
		center: [34.8, 32.08],
		zoom: 13,
	},
	Йоханнесбург: {
		center: [28.0434, -26.20665],
		zoom: 11,
	},
	Краснодар: {
		center: [38.98, 45.04],
		zoom: 11,
	},
	Воронеж: {
		center: [39.2, 51.66],
		zoom: 11,
	},
	Екатеринбург: {
		center: [60.6, 56.84],
		zoom: 11,
	},
	Уфа: {
		center: [55.96, 54.74],
		zoom: 11,
	},
	Новосибирск: {
		center: [82.92, 55.03],
		zoom: 11,
	},
	Лондон: {
		center: [-0.13, 51.51],
		zoom: 12,
	},
	Иркутск: {
		center: [104.28, 52.29],
		zoom: 12,
	},
};

export const productsScopeDictionary = makeDynamicTranslations({
	russia: tDynamic('Россия'),
	israel: tDynamic('Израиль'),
	france: tDynamic('Франция'),
	england: tDynamic('Англия'),
	belarus: tDynamic('Беларусь'),
	kazakhstan: tDynamic('Казахстан'),
	south_africa: tDynamic('ЮАР'),
	united_arab_emirates: tDynamic('Объединенные Арабские Эмираты'),
	saudi_arabia: tDynamic('Саудовская Аравия'),
	uzbekistan: tDynamic('Узбекистан'),
	turkey: tDynamic('Турция'),
});

export const productsScope = Object.keys(productsScopeDictionary) as ProductScope[];

export const fallbackTypes = makeDynamicTranslations({
	deli: tDynamic('Доставка'),
	NO_VALUE: tDynamic('Отсутствует'),
	taxi: tDynamic('Такси'),
});

export const printInventorySnapshot = `
	@media print {
		@page {
			margin: 8mm;
			size: landscape;
			margin: 0mm;
		}
		body  {
		}
		table {
			page-break-inside: auto;
		}
		tr {
			page-break-inside: avoid;
			page-break-after: auto;
		}
	}
`;

export const printAcceptance = `
	@media print {
		@page {
			margin: 3mm 0mm;
		}
		table {
			page-break-inside: auto;
		}
		tr {
			page-break-inside: avoid;
			page-break-after: auto;
		}
	}
`;
