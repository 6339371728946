import { Col, Row, Select } from 'antd';
import { apiLoadProperties, getEntityTitle } from 'multiSelect/initMultiSelect';
import type { Dispatch, SetStateAction } from 'react';

import type { EntityName } from '~cache/apiLoadProperties';
import { useCache } from '~cache/useCache';
import type { SelectFieldProps } from '~shared/components/Fields/SelectField';
import { useStyles } from '~shared/components/Fields/styles';
import { EmptyValue, getColSpan } from '~shared/components/Fields/utils';

import { useStyles as ownUseStyles } from './styles';
import { checkOnlyView, getViewText } from './utils';

const { Option } = Select;

const SingleSelect = (
	props: SelectFieldProps & {
		selectEntity: EntityName;
		searchDataProps?: Record<string, string | undefined>;
		setSearchData: Dispatch<SetStateAction<Record<string, string | undefined> | undefined>>;
		dictionary: Record<string, string>;
		fixedOptions?: string[];
		link?: string;
	}
) => {
	const { cx, classes } = useStyles();
	const { classes: ownClasses } = ownUseStyles();
	const {
		editMode,
		colspan,
		fieldRequired,
		id,
		input,
		width,
		label,
		disabled,
		selectEntity: entity,
		dictionary,
		loading,
		searchDataProps,
		setSearchData,
		options,
		fixedOptions,
	} = props;

	const entityProperties = apiLoadProperties[entity];
	// если есть пермит только на load, то в editMode показываем как во viewMode
	const onlyView = checkOnlyView({
		editMode,
		listPermit: entityProperties?.listPermit,
		loadPermit: entityProperties?.loadPermit,
	});

	const dictionaryKeys = Object.keys(dictionary ?? {});
	const optionsToDisplay = fixedOptions ?? (options?.length ? options : dictionaryKeys);

	//@ts-expect-error
	const cache = useCache({
		[entity]: input.value,
	});

	const entityInstance = cache[entity]?.[input.value];
	const title = getEntityTitle?.(entity, entityInstance);

	const viewTextDisctionary = title ? { [input.value]: title } : dictionary;

	return (
		<>
			<Row align="top" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={{ inlineSize: width }}>
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label
						className={cx(classes.label, {
							[classes.labelRequired]: editMode && fieldRequired,
						})}
						htmlFor={id}
					>
						{label}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
				{
					<Col {...getColSpan(colspan)}>
						{editMode && (
							<div className={cx(classes.inputContainer, 'wms-input-container')}>
								<Select
									id={id}
									loading={loading}
									onBlur={() => {
										setSearchData(searchDataProps);
									}}
									disabled={disabled || onlyView}
									data-test={`data form ${label}${disabled ? ' disabled' : ''}`}
									allowClear
									className={cx({ [ownClasses.selectDisabled]: disabled, [ownClasses.select]: !disabled })}
									showArrow={true}
									showSearch
									onSearch={(value) => setSearchData({ title: value || undefined })}
									onChange={input.onChange}
									value={input.value}
									popupClassName={`data-test-select-dropdown-${label.replace(/\s/g, '-')}`}
									// если опции переданы извне, фильтруем их, если нет, отключаем фильтр и будет использоваться onSearch
									filterOption={
										!!fixedOptions?.length
											? (inputValue, option) => option?.props.children?.toLowerCase().includes(inputValue.toLowerCase())
											: false
									}
								>
									{optionsToDisplay?.map((option) => {
										return (
											<Option
												key={option}
												value={option}
												data-test={`data form option select ${dictionary?.[option] || option}`}
											>
												{dictionary[option] ?? option}
											</Option>
										);
									})}
								</Select>
							</div>
						)}
						{(onlyView || !editMode) && (
							<div data-test={`data form ${label}`}>
								{!input.value ? (
									<p className={classes.valueOnViewMode}>
										<EmptyValue />
									</p>
								) : (
									getViewText(input.value, entity, viewTextDisctionary, loading, {})
								)}
							</div>
						)}
					</Col>
				}
			</Row>
		</>
	);
};

export default SingleSelect;
