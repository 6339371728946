import { notification } from 'antd';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import api from '~/api';
import i18n from '~/i18n';
import { useCacheStore } from '~cache/cache';
import getAllRecursive from '~shared/utils/getAllRecursive';
import type { Stores } from '~types/stores';

type State = {
	storesData: Record<Stores.Store['company_id'], Stores.Store[]> & { loading: boolean };
	getStoresData: (companyId: string) => Promise<void>;
};

const initialState = {
	loading: false,
} as Record<Stores.Store['company_id'], Stores.Store[]> & { loading: boolean };

const STORE_FIELDS: Array<keyof Stores.Store> = ['title', 'store_id', 'cluster_id', 'company_id', 'external_id'];

export const useStoresStore = create<State>()(
	devtools(
		immer((set, get) => ({
			storesData: initialState,
			getStoresData: async (companyId) => {
				try {
					if (get().storesData[companyId]) return;

					set((state) => {
						state.storesData.loading = true;
					});

					const { data } = await getAllRecursive(api.stores.list, {
						company_id: companyId,
						_fields: STORE_FIELDS,
					});
					useCacheStore.getState().addData({
						entityName: 'stores',
						data: data.results,
						fields: STORE_FIELDS,
					});

					set(
						(state) => {
							state.storesData[companyId] = data.results;
						},
						false,
						'getStoresData'
					);
				} catch {
					notification.error({
						message: i18n.t('Не удалось получить склады'),
					});
				} finally {
					set((state) => {
						state.storesData.loading = false;
					});
				}
			},
		})),
		{ name: 'storesData' }
	)
);

// Это в компонентах
export const useStoresData = (companyId?: string) =>
	useStoresStore((state) => {
		if (!companyId)
			return {
				stores: [],
				loading: false,
			};

		return {
			stores: state.storesData[companyId] ?? [],
			loading: state.storesData.loading,
		};
	});

// Это в функциях
export const getStoresData = async (companyId: string) => await useStoresStore.getState().getStoresData(companyId);
