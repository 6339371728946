import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { Shelves } from '~types/shelves';

import type { ShelfImportObj } from '../components/ShelvesPage/TopologyCreator/utils';
import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<EmptyObject, Shelves.Shelf>('/api/admin/shelves/list'),
	load: makeLoadFn<Shelves.Shelf, 'shelf_id'>('/api/admin/shelves/load', 'shelf_id'),
	save: (data: Record<string, any>, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: Shelves.Shelf }>('/api/admin/shelves/save', data, config),
	create_rack: (data: Shelves.Rack, config?: AxiosAdditionalConfig) =>
		axios.post('/api/admin/shelves/create_rack', data, config),
	import_data: (data: { csv: string } | { json: ShelfImportObj[] }) =>
		axios.post('/api/admin/shelves/import_data', data),
};
