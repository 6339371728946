import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Orders } from '~types/orders';
import type { Wallets } from '~types/wallets';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	load: makeLoadFn<Wallets.Wallet, 'wallet_id'>('/api/admin/wallets/load', 'wallet_id'),
	listOld: makeListFn<
		{
			wallet_type: Wallets.WalletType;
			courier_id?: string[];
			direction?: 'ASC' | 'DESC';
			empty_wallets?: boolean;
			limit?: number;
		},
		Wallets.Wallet
	>('/api/admin/wallets/list'),
	list: makeListFn<
		{
			wallet_type: Wallets.WalletType;
			courier_id?: string[];
			direction?: 'ASC' | 'DESC';
			empty_wallets?: boolean;
			limit?: number;
		},
		Wallets.Wallet
	>('/api/admin/wallets/v2/list'),
	collect: (data: Wallets.CollectData) => axios.post<{ order: Orders.Order }>('/api/admin/wallets/collect', data),
	logOld: (data: {
		wallet_id: string[];
		courier_shift_id?: string[];
		type?: Wallets.LogType[];
		cursor?: string;
		direction?: 'ASC' | 'DESC';
		zero_log?: boolean;
	}) => axios.post<{ wallets_log: Wallets.Log[]; cursor: string | null }>('/api/admin/wallets/log', data),
	log: (data: {
		wallet_id: string[];
		courier_shift_id?: string[];
		type?: Wallets.LogType[];
		cursor?: string;
		direction?: 'ASC' | 'DESC';
		zero_log?: boolean;
	}) => axios.post<{ wallets_log: Wallets.Log[]; cursor: string | null }>('/api/admin/wallets/v2/log', data),
};
