import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Area,
	AreaChart,
	Bar,
	BarChart,
	CartesianGrid,
	Line,
	LineChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip as ReChartTooltip,
	XAxis,
	YAxis,
} from 'recharts';
import type { CurveType } from 'recharts/types/shape/Curve';

import ChartTooltip from '../../../components/AnalyticsPage/ChartTooltip';
import type { GraphItemMetric } from '../../../components/AnalyticsPage/types';

export interface ChartConfig {
	tooltipSize?: 'small' | 'big' | 'huge';
	detailsList?: string[];
	chartWidth?: number;
	chartHeight?: number;
	gridStroke?: string;
	gridVertical?: boolean;
	gridHorizontal?: boolean;
	normLineColor?: string;
	normLineStroke?: string;
	displayYAxisLine?: boolean;
	displayXAxisLine?: boolean;
	yAxisWidth?: number;
	xAxisWidth?: number;
	yAxisLine?: boolean;
	xAxisLine?: boolean;
	yTickLine?: boolean;
	xTickLine?: boolean;
	lineStroke?: string;
	lineWidth?: number;
	barSize?: number;
	barColor?: string;
	removeDots?: boolean;
	isAnimationActive?: boolean;
	margin?: {
		blockStart: number;
		inlineEnd: number;
		inlineStart: number;
		blockEnd: number;
	};
	customTooltip?: ReactElement;
	lineType?: CurveType;
	yAxisDomain?: [string | number, string | number];
	baseValue?: number;
	[key: string]: any;
}

interface Props {
	type: 'area' | 'line' | 'bar';
	config?: ChartConfig;
	data: GraphItemMetric[];
	unit: string | undefined;
	loading: boolean;
	alterKey?: string;
	syncId?: string;
	showTooltip?: boolean;
}

export const chartColors = {
	green: '#52C41A',
	cyan: '#36CFC9',
	blue: '#2F54EB',
	purple: '#722ED1',
	orange: '#FA8C16',
	lightblue: '#BAE7FF',
	grey: '#8c8c8c',
	dayBreakBlue: '#1890ff',
	dustRed: '#F5222D',
};

const basicConfig = {
	chartWidth: 500,
	chartHeight: 300,
	tooltipSize: 'small' as const,
	gridStroke: '6 6',
	gridVertical: false,
	gridHorizontal: true,
	normLineColor: '#1890FF',
	normLineStroke: '10 10',
	yAxisLine: false,
	xAxisLine: { stroke: '#D9D9D9' },
	yTickLine: false,
	xTickLine: { stroke: '#D9D9D9' },
	lineStroke: '#82ca9d',
	lineWidth: 2,
	barSize: 10,
	barColor: '#8884d8',
	yAxisOrientation: 'left',
};

const Chart = ({ type, config, data, unit, loading, alterKey, syncId, showTooltip }: Props) => {
	const [, i18n] = useTranslation();
	const isRtl = i18n.dir() === 'rtl';

	const chartTypes = {
		area: AreaChart,
		line: LineChart,
		bar: BarChart,
	};

	const ChartType = chartTypes[type];

	// Данные для линии референса - берем лимит из последнего дня, в котором он есть
	const reversedData = [...data].reverse();
	const maxThreshold = reversedData.find((el) => el.threshold_max)?.threshold_max;
	const minThreshold = reversedData.find((el) => el.threshold_min)?.threshold_min;

	const chartElement = (type: 'area' | 'line' | 'bar', alterKey?: string) => {
		const commonProps = {
			key: Math.random(),
			'data-test': `${type} chart ${type}`,
			dataKey: alterKey || 'value',
			animationDuration: 1500,
		};

		switch (type) {
			case 'area':
				return (
					<Area
						type={config?.lineType || 'monotoneX'}
						stroke={alterKey ? '#d9d9d9' : config?.lineStroke || basicConfig.lineStroke}
						fill={`url(${config?.lineStroke || basicConfig.lineStroke})`}
						strokeWidth={config?.lineWidth || basicConfig.lineWidth}
						fillOpacity={alterKey ? 0 : 1}
						baseValue={config?.baseValue}
						dot={
							config?.removeDots
								? false
								: {
										stroke: config?.lineStroke || basicConfig.lineStroke,
										strokeWidth: config?.lineWidth || basicConfig.lineWidth,
										r: (config?.lineWidth || basicConfig.lineWidth) * 1.5,
										fill: 'white',
										fillOpacity: 1,
									}
						}
						{...commonProps}
						isAnimationActive={config?.isAnimationActive}
					/>
				);
			case 'bar':
				return (
					<Bar
						fill={config?.barColor || basicConfig.barColor}
						barSize={config?.barSize || basicConfig.barSize}
						{...commonProps}
					/>
				);
			case 'line':
				return (
					<Line
						type={config?.lineType || 'monotone'}
						stroke={config?.lineStroke || basicConfig.lineStroke}
						strokeWidth={config?.lineWidth || basicConfig.lineWidth}
						dot={{
							stroke: config?.lineStroke || basicConfig.lineStroke,
							strokeWidth: config?.lineWidth || basicConfig.lineWidth,
						}}
						{...commonProps}
					/>
				);
		}
	};

	return (
		<ResponsiveContainer width="100%" height="100%" debounce={300}>
			<ChartType
				width={config?.chartWidth || basicConfig.chartWidth}
				height={config?.chartHeight || basicConfig.chartHeight}
				data={data}
				syncId={syncId}
				// @ts-expect-error
				margin={
					config?.margin
						? config.margin
						: {
								blockStart: 5,
								inlineEnd: 30,
								inlineStart: unit ? (unit === '%' ? -10 : 15) : -10,
								blockEnd: 5,
							}
				}
			>
				<defs>
					{Object.keys(chartColors).map((color) => (
						<linearGradient key={color} id={chartColors[color].slice(1)} x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor={chartColors[color]} stopOpacity={0.8} />
							<stop offset="95%" stopColor={chartColors[color]} stopOpacity={0} />
						</linearGradient>
					))}
				</defs>
				<CartesianGrid
					strokeDasharray={config?.gridStroke || basicConfig.gridStroke}
					vertical={config?.gridVertical !== undefined ? config?.gridVertical : basicConfig.gridVertical}
					horizontal={config?.gridHorizontal !== undefined ? config?.gridHorizontal : basicConfig.gridHorizontal}
					stroke="#EBEBEB"
				/>
				{(config?.displayXAxisLine === undefined || config?.displayXAxisLine) && (
					<XAxis
						dataKey="date"
						axisLine={config?.xAxisLine || basicConfig.xAxisLine}
						tickLine={config?.xTickLine || basicConfig.xTickLine}
						tickMargin={10}
						interval={config?.interval}
						widths={config?.xAxisWidth}
					/>
				)}
				{(config?.displayYAxisLine === undefined || config?.displayYAxisLine) && (
					<YAxis
						tickMargin={isRtl ? 56 : undefined}
						domain={config?.yAxisDomain}
						axisLine={config?.yAxisLine || basicConfig.yAxisLine}
						tickLine={config?.yTickLine || basicConfig.yTickLine}
						unit={unit}
						width={config?.yAxisWidth !== undefined ? config?.yAxisWidth : 75}
						tickFormatter={config?.tickFormatter ? (value) => config?.tickFormatter(value) : undefined}
						orientation={config?.yAxisOrientation || basicConfig.yAxisOrientation}
					/>
				)}
				{!config?.customTooltip ? (
					<ReChartTooltip
						content={
							// @ts-expect-error
							<ChartTooltip
								size={config?.tooltipSize || basicConfig.tooltipSize}
								unit={unit}
								detailsList={config?.detailsList}
								valueFormatter={config?.valueFormatter}
							/>
						}
					/>
				) : (
					<ReChartTooltip active={showTooltip} content={config?.customTooltip} />
				)}
				{maxThreshold && (
					<ReferenceLine
						y={maxThreshold}
						stroke={config?.normLineColor || basicConfig.normLineColor}
						strokeDasharray={config?.normLineStroke || basicConfig.normLineStroke}
						isFront={true}
						ifOverflow="extendDomain"
					/>
				)}
				{minThreshold && (
					<ReferenceLine
						y={minThreshold}
						stroke={config?.normLineColor || basicConfig.normLineColor}
						strokeDasharray={config?.normLineStroke || basicConfig.normLineStroke}
						isFront
						ifOverflow="extendDomain"
					/>
				)}
				{loading ? <ReferenceLine stroke="#EBEBEB" /> : chartElement(type)}
				{alterKey ? loading ? <ReferenceLine stroke="#EBEBEB" /> : chartElement(type, alterKey) : null}
			</ChartType>
		</ResponsiveContainer>
	);
};

export default Chart;
