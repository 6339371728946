import { combineReducers } from 'redux';

import ordersData from './ordersData';

const reducer = combineReducers({
	ordersData,
});

export type AppState = ReturnType<typeof reducer>;

export default reducer;
