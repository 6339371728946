import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';

import { compareDate } from '../../../../components/StoresPage/StoresDataPage/Events/utils';
import { DatePicker as EventsDatePicker } from '../../DatePicker';
import type { DisabledStatuses } from '../../DatePicker/types';
import { EventsCalendarContext } from '../context';
import { useStyles } from './styles';

export const EventsSlider = () => {
	const { cx, classes } = useStyles();
	const { searchData, setSearchData, timezone, startWeekFromSunday, storeEventsDataByWeek } =
		useContext(EventsCalendarContext);

	const disabled = useMemo<DisabledStatuses>(
		() => ({
			prev: compareDate(dayjs(searchData.local_date), dayjs().endOf('week')),
			next: compareDate(dayjs().add(3, 'week').endOf('week'), dayjs(searchData.local_date)),
		}),
		[searchData.local_date]
	);

	const handleClick = (value: string) => {
		setSearchData &&
			setSearchData({
				...searchData,
				local_date: value,
			});
	};

	return (
		<div className={classes.eventsDayPickerContainer} data-test="events slider">
			<EventsDatePicker
				startWeekFromSunday={startWeekFromSunday}
				disabled={disabled}
				searchData={searchData}
				setSearchData={setSearchData}
				timezone={timezone}
				paginationAdditionalClass={classes.eventsDayControlButton}
			>
				<div className={classes.eventsWeek}>
					{Object.keys(storeEventsDataByWeek).map((day) => {
						const weekDay = dayjs(day).format('dd');
						const dayOfMonth = dayjs(day).format('D');

						return (
							<button
								className={cx(classes.eventsWeekDay, {
									active: day === searchData.local_date,
								})}
								data-test="events-weekday"
								key={day}
								onClick={() => handleClick(day)}
							>
								{weekDay}
								<span className={classes.eventsWeekDate}>{dayOfMonth}</span>
							</button>
						);
					})}
				</div>
			</EventsDatePicker>
		</div>
	);
};
