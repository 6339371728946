import { Tooltip } from 'antd';
import type { JSX } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { schetsTask } from '~constants/dataPageFields/schetTaskFields';
import { ReactComponent as CheckRegular } from '~images/icons/check-regular.svg';
import { ReactComponent as ClockRegular } from '~images/icons/clock-regular.svg';
import { orderCheckValidModeNames, orderTypeNames } from '~server-types/doc/api/models/order';
import Icon from '~shared/utils/icons';
import type { Schets } from '~types/schets';

import { useStyles } from './styles';

type Props = {
	data: Schets.Schet;
};

export const SchetHandlerCell = memo(({ data }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();

	let additional: JSX.Element | null;

	switch (data.handler) {
		case 'create_check_valid':
			additional = (
				<ul className={classes.schetTaskDesc}>
					<li>
						<b>{t('Тип документа')}:</b> {orderTypeNames[data.kwargs?.order_type ?? ''] ?? data.kwargs?.order_type}
					</li>
					<li>
						<b>{t('Режим проверки')}:</b> {orderCheckValidModeNames[data.kwargs?.mode ?? ''] ?? data.kwargs?.mode}
					</li>
				</ul>
			);
			break;
		case 'sync_products':
			additional = (
				<ul className={classes.schetTaskDesc}>
					<li>
						<b>{t('Имя ПИМа для синхронизации')}:</b> {data.kwargs?.bird_name}
					</li>
				</ul>
			);
			break;
		default:
			additional = null;
	}

	return (
		<div className={classes.schetTaskBlock}>
			{!data.schedule_draft && data.approved_by ? (
				<Tooltip title={t('Подтверждено')}>
					<Icon component={CheckRegular} className={cx(classes.approveMark, classes.approveMarkApproved)} />
				</Tooltip>
			) : (
				data.schedule_draft && (
					<Tooltip title={t('Требуется подтверждение')}>
						<Icon component={ClockRegular} className={classes.approveMark} />
					</Tooltip>
				)
			)}
			<span>{schetsTask[data.handler] ?? data.handler}</span>
			{additional}
		</div>
	);
});
