import { PageHeaderButton } from '@lavka/ui-kit';
import { Spin, Tooltip, Typography } from 'antd';
import type { JSX, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import i18n from '~/i18n';
import { tabletMaxWidth } from '~constants/breakPoints';
import { HeaderCenterPortal } from '~shared/components/Layout/HeaderCenterPortal';
import { FloppyDiskRegularIcon, PencilRegularIcon, XmarkRegularIcon } from '~shared/ui-kit/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import type { MenuButton } from '../../DotsButton';
import DotsButton from '../../DotsButton';
import type { PrimaryButton } from '../../PrimaryButtonComponent';
import PrimaryButtonComponent from '../../PrimaryButtonComponent';
import { useStyles } from './styles';

const { Title } = Typography;

interface Props {
	addMode?: boolean;
	editMode?: boolean;
	disabled?: boolean;
	inProgress?: boolean;
	backLink?: string;
	quitEditModeLink?: string;
	enterEditModeLink?: string;
	submit: () => void;
	dotsOptions?:
		| MenuButton[]
		| {
				section: string;
				buttons: MenuButton[];
				condition?: boolean;
				dataTest?: string;
		  }[];
	testSelectors: {
		back?: string;
		exitEdit: string;
		enterEdit: string;
		save: string;
		cancel?: string;
	};
	children: ReactNode;
	title:
		| {
				add: string | JSX.Element;
				edit: string | JSX.Element;
				view: string | JSX.Element;
		  }
		| string
		| JSX.Element;
	resetForm?: () => void;
	saveBtnTitle?: string;
	saveBtnComponent?: (submit: () => void, saveBtnTitle: string, disabled: boolean) => JSX.Element;
	additionalButtons?: PrimaryButton[];
	showHeader?: boolean;
	editModeModalVisible?: (visible: boolean) => void;
	editModeDisabledReason?: string;
}

const DataFormControls = ({
	addMode = false,
	editMode = false,
	disabled = false,
	inProgress = false,
	enterEditModeLink,
	quitEditModeLink,
	submit,
	dotsOptions,
	testSelectors,
	children,
	title,
	resetForm,
	saveBtnTitle = i18n.t('Сохранить'),
	saveBtnComponent,
	additionalButtons,
	showHeader = true,
	editModeModalVisible,
	editModeDisabledReason,
}: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const { cx, classes: commonClasses } = useCommonStyles();
	const isTabletOrMobile = useMediaQuery({
		query: `(max-width: ${tabletMaxWidth}px)`,
	});

	const renderTitle = useMemo(() => {
		if (addMode) {
			return typeof title === 'object' && 'add' in title ? title.add : title;
		}
		if (editMode) {
			return typeof title === 'object' && 'edit' in title ? title.edit : title;
		}
		return typeof title === 'object' && 'view' in title ? title.view : title;
	}, [title, addMode, editMode]);

	if (!showHeader) {
		return <>{children}</>;
	}

	const editButton =
		!editMode &&
		enterEditModeLink &&
		(editModeDisabledReason ? (
			<Tooltip title={editModeDisabledReason}>
				<PageHeaderButton
					variant="icon"
					view="header-accent"
					data-test={testSelectors.enterEdit}
					iconLeft={<PencilRegularIcon width={20} height={20} />}
					disabled
				/>
			</Tooltip>
		) : editModeModalVisible ? (
			<PageHeaderButton
				variant="icon"
				view="header-accent"
				data-test={testSelectors.enterEdit}
				onClick={() => editModeModalVisible(true)}
				iconLeft={<PencilRegularIcon width={20} height={20} />}
			/>
		) : (
			<Link to={enterEditModeLink} className={classes.editButton}>
				<PageHeaderButton
					variant="icon"
					view="header-accent"
					data-test={testSelectors.enterEdit}
					iconLeft={<PencilRegularIcon width={20} height={20} />}
				/>
			</Link>
		));

	return (
		<>
			<HeaderCenterPortal>
				<div className={commonClasses.header}>
					<Title
						className={cx(commonClasses.title, { [commonClasses.editModeTitle]: editMode })}
						data-test="page title"
					>
						{renderTitle}
					</Title>
					{inProgress && <Spin className={classes.spin} />}
					{additionalButtons?.length && (
						<div className={classes.additionalButtonsBar}>
							{additionalButtons.map((button: PrimaryButton, index) => (
								<PrimaryButtonComponent key={index} button={button} inHeader />
							))}
						</div>
					)}
					{(addMode || editMode) && (
						<div className={classes.buttonsBarRight}>
							{quitEditModeLink && (
								<Link to={quitEditModeLink}>
									<PageHeaderButton
										view="header-accent"
										variant={isTabletOrMobile ? 'icon' : undefined}
										data-test={testSelectors.exitEdit}
										onClick={() => resetForm?.()}
										iconLeft={isTabletOrMobile ? <XmarkRegularIcon width={20} height={20} /> : undefined}
										style={{ marginInlineEnd: 8 }}
									>
										{!isTabletOrMobile && t('Отмена')}
									</PageHeaderButton>
								</Link>
							)}
							{saveBtnComponent && saveBtnTitle ? (
								saveBtnComponent(submit, saveBtnTitle, disabled)
							) : (
								<PageHeaderButton
									view="header-accent"
									variant={isTabletOrMobile ? 'icon' : undefined}
									disabled={disabled}
									onClick={() => submit()}
									data-test={testSelectors.save}
									iconLeft={isTabletOrMobile ? <FloppyDiskRegularIcon width={20} height={20} /> : undefined}
								>
									{!isTabletOrMobile && saveBtnTitle}
								</PageHeaderButton>
							)}
						</div>
					)}
					{editButton}
					{dotsOptions && (
						<DotsButton buttons={dotsOptions} className={classes.dotsOptions} dataTest="header dots button" inHeader />
					)}
				</div>
			</HeaderCenterPortal>
			{children}
		</>
	);
};

export default DataFormControls;
