import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Orders } from '~types/orders';
import type { Stores } from '~types/stores';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<Stores.SeekRequest, Stores.Store>('/api/admin/stores/list'),
	load: makeLoadFn<Stores.Store, 'store_id'>('/api/admin/stores/load', 'store_id'),
	save: (data: Record<string, any>) => axios.post<{ result: Stores.Store }>('/api/admin/stores/save', data),
	joinStore: (data: { store_id: string }) => axios.post('/api/admin/stores/join-store', data),
	printerTokenLoad: (data: { store_id: string }) =>
		axios.post<{ result: string }>('/api/admin/stores/printer-token/load', data),
	printerTokenChange: (data: { store_id: string }) =>
		axios.post<{ store_id: string; printer_token: string }>('/api/admin/stores/printer-token/change', data),
	changeExternalId: (data: { store_id: string; new_external_id: string }) =>
		axios.post<{ store: Stores.Store }>('/api/admin/stores/change-external_id', data),
	inventoryMode: (data: { mode: 'stop' | 'start' }) => axios.post('/api/admin/stores/inventory-mode', data),
	clusterStat: (data?: { company_id?: string; cluster_id?: string }) =>
		axios.post<{ stat: Stores.ClusterStat[] }>('/api/admin/stores/cluster-stat', data ?? {}),
	getMessagesCount: () => axios.post('/api/admin/stores/get-messages-count'),
	allow_store: (data: { store_id: string; user_id: string[] }) =>
		axios.post<EmptyObject>('/api/admin/stores/allow_store ', data),
	disallow_store: (data: { store_id: string; user_id: string[] }) =>
		axios.post<EmptyObject>('/api/admin/stores/disallow_store ', data),
	sell: (data: { source_store_id: string; target_store_id: string }) =>
		axios.post<{ result: Orders.Order }>('/api/admin/stores/sell', data),
};
