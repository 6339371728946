import type { DependencyList } from 'react';
import { useCallback, useEffect, useState } from 'react';

const queryReq: Record<symbol, () => Promise<void>> = {};

export type UseLoadDataReturnType<T = any> = {
	data: T | null;
	loading: boolean;
	loaded: boolean;
	error: string | boolean;
	update: (data: T | null) => void;
	req: () => void;
};

export default function <T = any>(
	fetchFunction: () => Promise<{ data: T }>,
	deps: DependencyList = [],
	skip = false,
	onSuccess: ((data: T) => void) | false = false,
	onError?: () => void,
	queryKey?: symbol
): UseLoadDataReturnType<T> {
	const [loading, toggleLoading] = useState<boolean>(!skip);
	const [loaded, toggleLoaded] = useState<boolean>(false);
	const [error, setError] = useState<string | boolean>(false);
	const [data, setData] = useState<T | null>(null);

	const req = useCallback(async function () {
		toggleLoading(true);
		toggleLoaded(false);

		try {
			const { data } = await fetchFunction();
			setData(data);
			toggleLoaded(true);
			if (onSuccess) {
				onSuccess(data);
			}
		} catch (e) {
			setError(e);
			onError?.();
		} finally {
			toggleLoading(false);
		}
	}, deps);

	useEffect(() => {
		if (skip) {
			return;
		}

		void req();
	}, deps);

	useEffect(() => {
		if (!queryKey) {
			return;
		}

		queryReq[queryKey] = req;

		return () => {
			delete queryReq[queryKey];
		};
	}, [queryKey, req]);

	if (skip) {
		return {
			data: null,
			update: setData,
			loaded: false,
			loading: false,
			error: false,
			req,
		};
	}

	// @ts-expect-error
	if (error?.strict) {
		throw error;
	}

	return {
		data,
		loading,
		loaded,
		error,
		update: setData,
		req,
	};
}

export function refetchQuery(queryKey: symbol) {
	return queryReq[queryKey]?.();
}
