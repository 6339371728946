import { makeDynamicTranslations, tDynamic } from '../makeDynamicTranslations';

export const placeholderMap = makeDynamicTranslations({
	nick: tDynamic('имени'),
	fullname: tDynamic('имени'),
	email: tDynamic('email'),
	phone: tDynamic('телефону'),
	role: tDynamic('роли'),
	title: tDynamic('имени'),
	doc_number: tDynamic('номеру'),
	external_id: tDynamic('внешнему ИД'),
	courier_service_name: tDynamic('названию'),
});

export const saveFilterInLocalStorage = (filterKey: string, filterValues: Record<string, any>) => {
	try {
		const tableFiltersStateString = localStorage.getItem('tableFiltersState');
		let tableFiltersState: Record<string, any> = {};
		if (tableFiltersStateString) {
			tableFiltersState = JSON.parse(tableFiltersStateString);
		}
		localStorage.setItem(
			'tableFiltersState',
			JSON.stringify({
				...tableFiltersState,
				[filterKey]: filterValues,
			})
		);
	} catch {
		return;
	}
};

export const getFilterValueFromLocalStorage = (filterKey: string) => {
	try {
		const filterValuesString = localStorage.getItem('tableFiltersState');
		const filterValues = filterValuesString && JSON.parse(filterValuesString)[filterKey];
		return filterValues || undefined;
	} catch {
		return undefined;
	}
};

export const clearColumnFilters = (
	filterKey: string | undefined,
	clearSearchDataAction: () => void,
	rerenderAction?: () => void
) => {
	try {
		clearSearchDataAction();
		const tableFiltersStateString = localStorage.getItem('tableFiltersState');
		let tableFiltersState: Record<string, any> = {};
		if (tableFiltersStateString) {
			tableFiltersState = JSON.parse(tableFiltersStateString);
			if (filterKey) {
				delete tableFiltersState[filterKey];
			}
			localStorage.setItem(
				'tableFiltersState',
				JSON.stringify({
					...tableFiltersState,
				})
			);
		}
		rerenderAction?.();
	} catch {
		return;
	}
};
