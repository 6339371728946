import { createBrowserRouter } from 'react-router-dom';

import { RouterErrorBoundary } from '~shared/components/ErrorBoundary/RouterErrorBoundary';
import { makeRoutes } from '~shared/utils/makeRoutes';

import Main from './components/Main';
import Root from './components/Root';
import samplesRoutes from './components/Samples/routes';
import StoresMap from './components/StoresMap';
import supportRoutes from './components/SupportSearch/routes';
import adjustmentsRoutes from './routes/adjustments';
import analyticsRoutes from './routes/analytics';
import assortmentsRoutes from './routes/assortments';
import authRoutes from './routes/auth';
import barcodeRoutes from './routes/barcode';
import bonusesRoutes from './routes/bonuses';
import businessCardRoutes from './routes/businessCard';
import checkProjectsRoutes from './routes/checkProjects';
import clustersRoutes from './routes/clusters';
import clustersEquipment from './routes/clustersEquipment';
import companiesRoutes from './routes/companies';
import configsRoutes from './routes/configs';
import couriersRoutes from './routes/couriers';
import courierServicesRoutes from './routes/courierServices';
import dxgyRoutes from './routes/dxgy';
import equipmentDictionary from './routes/equipmentDictionary';
import healthRoutes from './routes/health';
import legalEntitiesRoutes from './routes/legalEntities';
import mobileTableauRoutes from './routes/mobileTableau';
import ordersRoutes from './routes/orders';
import ordersNewRoutes from './routes/ordersNew';
import priceListsRoutes from './routes/priceLists';
import priceListsDraftsRoutes from './routes/priceListsDrafts';
import productGroupsRoutes from './routes/productGroups';
import productsRoutes from './routes/products';
import schetsRoutes from './routes/schets';
import shelvesRoutes from './routes/shelves';
import shiftExpansionsRoutes from './routes/shiftExpansions';
import shiftPreferencesRoutes from './routes/shiftPreferences';
import shiftsRoutes from './routes/shifts';
import shiftTagsRoutes from './routes/shiftTags';
import storesRoutes from './routes/stores';
import storesEquipment from './routes/storesEquipment';
import tableauRoutes from './routes/tableau';
import tariffsRoutes from './routes/tariffs';
import usersRoutes from './routes/users';
import usersLogRoutes from './routes/usersLog';
import walletRoutes from './routes/wallet';
import NotFound from './shared/components/ErrorPage/NotFound';

const routes = makeRoutes([
	{
		path: '/',
		element: <Main />,
	},
	{
		path: '/map',
		element: <StoresMap />,
		permit: ['out_of_store', 'stores_load', 'zones_seek'],
	},
	{
		path: '*',
		element: <NotFound />,
	},
]);

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <RouterErrorBoundary />,
		children: [
			routes,
			authRoutes,
			barcodeRoutes,
			businessCardRoutes,
			usersRoutes,
			usersLogRoutes,
			couriersRoutes,
			courierServicesRoutes,
			productGroupsRoutes,
			assortmentsRoutes,
			productsRoutes,
			priceListsRoutes,
			priceListsDraftsRoutes,
			ordersRoutes,
			shelvesRoutes,
			storesRoutes,
			checkProjectsRoutes,
			companiesRoutes,
			shiftsRoutes,
			shiftTagsRoutes,
			clustersRoutes,
			walletRoutes,
			schetsRoutes,
			analyticsRoutes,
			healthRoutes,
			configsRoutes,
			tableauRoutes,
			samplesRoutes,
			supportRoutes,
			shiftExpansionsRoutes,
			adjustmentsRoutes,
			tariffsRoutes,
			bonusesRoutes,
			dxgyRoutes,
			shiftPreferencesRoutes,
			clustersEquipment,
			storesEquipment,
			equipmentDictionary,
			legalEntitiesRoutes,
			mobileTableauRoutes,
			ordersNewRoutes,
		].flat(),
	},
]);
