import { blue } from '@ant-design/colors';

import { makeStyles } from '~styles/theme';

const FIELDS_GAP = 8;

export const useStyles = makeStyles()((theme) => ({
	fieldArray: {
		display: 'flex',
		flexDirection: 'column',
	},
	fieldArrayTitle: {
		marginBlockEnd: 0,
		fontWeight: 500,
	},
	fieldArrayContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	fieldArrayItem: {
		display: 'flex',
		alignItems: 'start',
		columnGap: FIELDS_GAP,
		minBlockSize: '42px',

		'&.fullWidth': {
			inlineSize: '100%',
		},
	},
	fieldArraySection: {
		flexGrow: 1,
	},
	fieldArrayFields: {
		display: 'flex',
		gap: FIELDS_GAP,
	},
	fieldArrayFieldsColumn: {
		flexDirection: 'column',
	},
	fieldArrayFieldsRow: {
		flexDirection: 'row',
		whiteSpace: 'nowrap',
	},
	fieldArrayItemInput: {
		alignItems: 'baseline',
		'&>.ant-col': {
			flex: 1,
			'& .ant-picker': {
				inlineSize: '100%',
			},
		},
	},
	fieldArrayAddButton: {
		'&.ant-btn:hover': {
			borderColor: blue[4],
			color: blue[4],
		},
		marginBlockEnd: 16,
		inlineSize: '100%',
	},
	fieldArrayAddButtonTooltip: {
		blockSize: '32px',
		inlineSize: '32px',
	},
	fieldArrayAddButtonTooltipIcon: {
		blockSize: '16px',
		inlineSize: '16px',
		color: theme.border.secondary,
		margin: '8px',
		'&:hover': {
			color: theme.text.btnLink,
		},
	},
	fieldArrayCheckbox: {
		span: {
			color: 'black',
		},
	},
}));
