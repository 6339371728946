import { notification } from 'antd';
import FileSaver from 'file-saver';
import yaml from 'js-yaml';

import api from '~/api';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const storeErrorsDictionary = makeDynamicTranslations({
	'assortment:none': tDynamic('нет ассортимента'),
	'price_list:none': tDynamic('нет прайс-листа'),
	'product_group:none': tDynamic('нет иерархии фронта меню'),
	location: tDynamic('нет точки склада'),
	zone: tDynamic('нет полигона зоны'),
	'zone:not_found': tDynamic('нет полигона зоны'),
	'shelf:incoming': tDynamic('нет ячейки "Входящее"'),
	'shelf:found': tDynamic('нет ячейки "Находки"'),
	'shelf:lost': tDynamic('нет ячейки "Потери"'),
	'shelf:trash': tDynamic('нет ячейки "Списание"'),
	'shelf:store': tDynamic('нет ячейки "Склад"'),
	'shelf:out': tDynamic('нет ячейки "Отгрузка"'),
	'shelf:markdown': tDynamic('нет ячейки "Уценка"'),
	'shelf:office': tDynamic('нет ячейки "Внутренние нужды"'),
	'shelf:kitchen_components': tDynamic('нет ячейки "Ингредиенты кухни"'),
	'shelf:kitchen_menu': tDynamic('нет ячейки "Меню кухни"'),
	'shelf:kitchen_on_demand': tDynamic('нет ячейки "Выдача кухни"'),
	'store:slug': tDynamic('нет slug'),
});

export const eZonesColorDictionary = {
	1: '#1E98FF',
	2: '#595959',
	3: '#722ED1',
	4: '#EB2F96',
	5: '#2F54EB',
};

export const deliveryTypeColorDictionary = {
	foot: '#41c604',
	foot_external: '#fda50a',
	foot_night: '#ffdd02',
	rover: '#1E98FF',
	car: '#595959',
	yandex_taxi: '#722ED1',
	yandex_taxi_remote: '#EB2F96',
	yandex_taxi_night: '#2F54EB',
};

export const printerTokenLoad = async (storeId: string) => {
	const { data } = await api.stores.printerTokenLoad({
		store_id: storeId,
	});
	const config = {
		client: {
			event_url: '/',
			url: '/api/print-client',
			store_id: storeId,
			token: data.result,
		},
	};
	const dump = yaml.dump(config, { lineWidth: 400 });
	const blob = new Blob([dump], {
		type: 'text/plain;charset=utf-8',
	});
	FileSaver.saveAs(blob, 'printer.yml');
	notification.success({
		message: 'Конфиг принтера загружен',
	});
};

export const changedUsers = ({
	initialIds,
	allowed,
	disallowed,
}: {
	initialIds?: string[];
	allowed?: string[];
	disallowed?: string[];
}) => {
	const addedUsers = allowed?.filter((userId) => !initialIds?.includes(userId));
	const deletedUsers = disallowed?.filter((userId) => initialIds?.includes(userId));
	return { addedUsers, deletedUsers };
};
