import { notification } from 'antd';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { ReactComponent as PencilRegular } from '~images/icons/pencil-regular.svg';
import ModalComponent from '~shared/components/ModalComponent';
import Icon from '~shared/utils/icons';
import type { Orders } from '~types/orders';

import { useStyles } from '../../styles';
import WaybillForm from './WaybillForm';

interface Props {
	order: Orders.Order;
}

export type WaybillFormValues = { waybill: Orders.Order['attr']['waybill'] };

const SetPackingList = ({ order }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [showModal, toggleModal] = useState<boolean>(false);

	const onSubmit = async (value: WaybillFormValues) => {
		try {
			await api.orders.updateAttr({
				order_id: order.order_id,
				type: order.type,
				attr: { waybill: value.waybill },
			});
			notification.success({
				message: t('Номер ТТН сохранен'),
			});
			toggleModal(false);
		} catch {
			notification.error({
				message: t('Не удалось сохранить номер ТТН'),
			});
		}
	};

	const form = useForm<WaybillFormValues>({
		values: {
			waybill: order.attr.waybill,
		},
	});

	const { control, handleSubmit, reset } = form;

	const value = useWatch({ control, name: 'waybill' });

	return (
		<>
			<div onClick={() => toggleModal(true)} data-test="change picking list" className={classes.blockEditBtn}>
				<Icon component={PencilRegular} className={classes.blockEditBtn} />
			</div>
			<ModalComponent
				className={`data-test-change-order-user-modal-${showModal ? 'visible' : 'hidden'}`}
				title={t('Изменить номер ТТН')}
				open={showModal}
				onOk={handleSubmit(onSubmit)}
				okButtonProps={{
					className: 'data-test-submit-picking-list',
					disabled: value === order.attr.waybill,
				}}
				onCancel={() => {
					reset();
					toggleModal(false);
				}}
			>
				<WaybillForm onSubmit={onSubmit} hookForm={form} />
			</ModalComponent>
		</>
	);
};

export default SetPackingList;
