import type { TabsContentProps } from '.';

export const filteredData = (data: TabsContentProps) =>
	data.filter((tab) => tab.condition || !tab.hasOwnProperty('condition'));

export const prepareTabBarItems = (data: TabsContentProps) =>
	data.map((item) => ({
		id: item.id,
		label: item.label,
		'data-testid': item.dataTest,
		badge: item.badge,
	}));
