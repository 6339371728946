import api from '~/api';
import courierName from '~shared/utils/courierName';
import type { Assortments } from '~types/assortments';
import type { CheckProjects } from '~types/checkProjects';
import type { Companies } from '~types/companies';
import type { Couriers } from '~types/couriers';
import type { CourierServices } from '~types/courierServices';
import type { EquipmentsItem, EquipmentTypesItem } from '~types/equipment';
import type { LegalEntities } from '~types/legalEntities';
import type { Orders } from '~types/orders';
import type { PriceLists } from '~types/priceLists';
import type { ProductGroups } from '~types/productGroups';
import type { Products } from '~types/products';
import type { Shelves } from '~types/shelves';
import type { Shifts } from '~types/shifts';
import type { Stores } from '~types/stores';
import type { User } from '~types/user';
import type { Wallets } from '~types/wallets';

export type EntityName = keyof EntityMap;

export type EntityMap = {
	shelves: Shelves.Shelf;
	assortments: Assortments.Assortment;
	priceLists: PriceLists.PriceList;
	priceListDrafts: PriceLists.PriceList;
	stores: Stores.Store;
	products: Products.Product;
	productGroups: ProductGroups.ProductGroup;
	items: Products.Item;
	orders: Orders.Order;
	couriers: Couriers.CourierBrief;
	couriersBrief: Couriers.CourierBrief;
	companies: Companies.Company;
	userExecutors: User.Executor;
	courierShiftTags: Shifts.Tag;
	clusters: Stores.Cluster;
	checkProjects: CheckProjects.CheckProject;
	courierServices: CourierServices.CourierService;
	wallets: Wallets.Wallet;
	courierShifts: Shifts.Shift;
	courierShiftSchedules: Shifts.ShiftScheduleObject;
	samples: Stores.Sample;
	equipments: EquipmentsItem;
	equipmentTypes: EquipmentTypesItem;
	legalEntities: LegalEntities.LegalEntity;
};

export type ApiLoadProperties = typeof apiLoadProperties;

export const apiLoadProperties = {
	shelves: {
		idField: 'shelf_id',
		loadFunc: api.shelves.load,
		loadPermit: 'shelves_load',
		listFunc: api.shelves.list,
		listPermit: 'shelves_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	assortments: {
		idField: 'assortment_id',
		loadFunc: api.assortments.load,
		loadPermit: 'assortments_load',
		listFunc: api.assortments.list,
		listPermit: 'assortments_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	priceLists: {
		idField: 'price_list_id',
		loadFunc: api.priceLists.load,
		loadPermit: 'price_lists_load',
		listFunc: api.priceLists.list,
		listPermit: 'price_lists_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	priceListDrafts: {
		idField: 'price_list_id',
		loadFunc: api.priceListDrafts.load,
		loadPermit: 'draft_price_lists_load',
		listFunc: api.priceListDrafts.list,
		listPermit: 'draft_price_lists_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	stores: {
		idField: 'store_id',
		loadFunc: api.stores.load,
		loadPermit: 'stores_load',
		listFunc: api.stores.list,
		listPermit: 'stores_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	products: {
		idField: 'product_id',
		loadFunc: api.products.load,
		loadPermit: 'products_load',
		listFunc: api.products.list,
		listPermit: 'products_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	productGroups: {
		idField: 'group_id',
		loadFunc: api.productGroups.load,
		loadPermit: 'product_groups_load',
		listFunc: api.productGroups.list,
		listPermit: 'product_groups_seek',
		shouldHaveLink: true,
		hierarchy: true,
		title: 'title',
	},
	items: {
		idField: 'item_id',
		loadFunc: api.items.load,
		loadPermit: 'items_load',
		listFunc: api.items.load,
		listPermit: 'couriers_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	orders: {
		idField: 'order_id',
		loadFunc: api.orders.load,
		loadPermit: 'dispatcher',
		listFunc: api.orders.list,
		listPermit: 'dispatcher',
		shouldHaveLink: true,
		title: 'doc_number',
	},
	couriers: {
		idField: 'courier_id',
		loadFunc: api.couriers.loadBrief,
		loadPermit: 'couriers_load',
		listFunc: api.couriers.list,
		listPermit: 'couriers_seek',
		shouldHaveLink: true,
		title: (courier: Couriers.Courier | undefined) => courierName(courier),
	},
	couriersBrief: {
		idField: 'courier_id',
		loadFunc: api.couriers.loadBrief,
		loadPermit: 'couriers_load',
		listPermit: '',
		shouldHaveLink: false,
		title: (courier: Couriers.CourierBrief | undefined) => courierName(courier),
	},
	companies: {
		idField: 'company_id',
		loadFunc: api.companies.load,
		loadPermit: 'companies_load',
		listFunc: api.companies.list,
		listPermit: 'companies_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	userExecutors: {
		idField: 'user_id',
		loadFunc: api.users.executors,
		loadPermit: 'executors_load',
		listFunc: api.users.list,
		listPermit: 'executors_load',
		shouldHaveLink: true,
		title: 'fullname',
	},
	courierShiftTags: {
		idField: 'courier_shift_tag_id',
		loadFunc: api.courierShiftTags.load,
		loadPermit: 'courier_shift_tags_load',
		listFunc: api.courierShiftTags.list,
		listPermit: 'courier_shift_tags_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	clusters: {
		idField: 'cluster_id',
		loadFunc: api.clusters.load,
		loadPermit: 'clusters_load',
		listFunc: api.clusters.list,
		listPermit: 'clusters_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	checkProjects: {
		idField: 'check_project_id',
		loadFunc: api.checkProjects.load,
		loadPermit: 'check_projects_load',
		listFunc: api.checkProjects.list,
		listPermit: 'check_projects_load',
		shouldHaveLink: true,
		title: 'title',
	},
	courierServices: {
		idField: 'courier_service_id',
		loadFunc: api.courierServices.load,
		loadPermit: 'courier_services_load',
		listFunc: api.courierServices.list,
		listPermit: 'courier_services_seek',
		shouldHaveLink: true,
		title: 'courier_service_name',
	},
	wallets: {
		idField: 'wallet_id',
		loadFunc: api.wallets.load,
		loadPermit: 'wallets_load',
		listFunc: api.wallets.list,
		listPermit: 'wallets_load',
		shouldHaveLink: true,
		title: 'wallet_id',
	},
	courierShifts: {
		idField: 'courier_shift_id',
		loadFunc: api.courierShifts.load,
		loadPermit: 'courier_shifts_load',
		listFunc: api.courierShifts.list,
		listPermit: 'courier_shifts_seek',
		shouldHaveLink: true,
		title: 'courier_shift_id',
	},
	courierShiftSchedules: {
		idField: 'courier_shift_schedule_id',
		loadFunc: api.courierShiftSchedules.load,
		loadPermit: 'courier_shift_schedules_load',
		listFunc: api.courierShiftSchedules.list,
		listPermit: 'courier_shift_schedules_seek',
		shouldHaveLink: true,
		title: 'courier_shift_schedule_id',
	},
	samples: {
		idField: 'sample_id',
		loadFunc: api.samples.load,
		loadPermit: 'samples_load',
		listFunc: api.samples.list,
		listPermit: 'samples_seek',
		shouldHaveLink: true,
		title: 'title',
	},
	equipments: {
		idField: 'equipment_id',
		loadFunc: api.equipment.items.load,
		loadPermit: 'equipments_load',
		listFunc: api.equipment.items.list,
		listPermit: 'equipments_seek',
		shouldHaveLink: false,
		title: 'title',
	},
	equipmentTypes: {
		idField: 'equipment_type_id',
		loadFunc: api.equipment.types.load,
		loadPermit: 'equipment_types_load',
		shouldHaveLink: true,
		listPermit: 'equipment_types_seek',
		listFunc: api.equipment.types.list,
		title: 'title',
	},
	legalEntities: {
		idField: 'legal_entity_id',
		loadFunc: api.legalEntities.load,
		loadPermit: 'legal_entities_load',
		listFunc: api.legalEntities.list,
		shouldHaveLink: false,
		listPermit: 'legal_entities_list',
		title: 'title',
	},
};
