import dayjs from 'dayjs';
import { type Dispatch, type SetStateAction, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import IdReplacer from '~shared/components/IdReplacer';
import ModalComponent from '~shared/components/ModalComponent';
import useSetTableScroll from '~shared/hooks/useSetTableScroll';
import type { ProColumns } from '~shared/ui-kit/ProTable';
import { ProTable } from '~shared/ui-kit/ProTable';
import columnSearch from '~shared/utils/columnSearch/';

import { criticalEvents, levelDictionary, sourceByType } from '../../constants';
import { HealthContext } from '../../context';
import type { Trouble } from '../../healthTypes';
import { getCritViewData } from '../../utils/getCritViewData';
import CritOrderLink from '../CriticalBlock/CritOrderLink';
import type { ViewTrouble } from '../CriticalBlock/getTroubleView';
import { useStyles } from './styles';

type Props = {
	data?: Trouble[];
	loadingTable: boolean;
	visible: boolean;
	setModalOpen: Dispatch<SetStateAction<boolean>>;
};

const CriticalEventsModal = ({ data, loadingTable, visible, setModalOpen }: Props) => {
	const { classes } = useStyles();
	const [t] = useTranslation();
	const { levelState } = useContext(HealthContext);

	const { setOrderIdToShow, userStoreId } = useContext(HealthContext);

	const [searchData, setSearchData] = useState<{ event?: string[] }>({
		event: undefined,
	});

	const searchProps = columnSearch(
		searchData,
		setSearchData,
		'checkbox',
		Object.entries(criticalEvents).map(([key, value]) => ({
			label: value,
			value: key,
		}))
	)('event');

	const viewData = useMemo(() => getCritViewData({ data, searchData }), [data, searchData]);

	const tableScroll = useSetTableScroll({ selector: '#critical-events-table .ant-table-body', minHeight: 700 });

	const columns: ProColumns<ViewTrouble>[] = [
		{
			title: t('Дата и время начала'),
			key: 'time',
			dataIndex: 'time',
			width: 300,
			render: (time, trouble) => <span className={classes.timeCell}>{`${trouble.date} ${time}`}</span>,
			sorter: (a, b) => (dayjs(`${a.date} ${a.time}`).isAfter(dayjs(`${b.date} ${b.time}`)) ? 1 : -1),
		},
		{
			title: t('Склад'),
			key: 'store',
			render: (_, data) => (
				<IdReplacer
					id={data.storeId}
					type="stores"
					keyReplaceOn="title"
					cutSymbols={40}
					link={`/stores/${data.storeId}`}
					dataTest={`address ${data.storeId}`}
				/>
			),
		},
		{
			title: t('Событие'),
			key: 'event',
			render: (_, data) => {
				const words = data.viewTroubleType.split('.');
				return (
					<div className={classes.eventCell}>
						<span>{words[0]}</span>
						<span className={classes.eventCellReason}>{words.slice(1).join('.')}</span>
					</div>
				);
			},
			...searchProps,
		},
		{
			key: 'action',
			dataIndex: 'action',
			align: 'left' as const,
			width: 70,
			render: (action, trouble) => (
				<CritOrderLink
					action={action}
					trouble={trouble}
					setOrderIdToShow={setOrderIdToShow}
					userStoreId={userStoreId}
				/>
			),
		},
	];

	const cache = useCache({
		stores: [],
		companies: [],
		clusters: [],
		userExecutors: [],
	});

	const sourceProps = sourceByType[levelState.type];
	const currentEntity = levelState.id ? cache[sourceProps.id_slice][levelState.id] : undefined;

	const Title = () => {
		const titleString = `${currentEntity?.[sourceProps.titleProp] ?? '—'} · ${levelDictionary[levelState.type]}`;
		return (
			<div className={classes.titleContainer}>
				<span>{t('Все события')}</span>
				<div className={classes.userInfoBlock}>
					<span className={classes.titleText}>{titleString}</span>
				</div>
			</div>
		);
	};

	return (
		<ModalComponent
			title={<Title />}
			width={1500}
			modalId="graphs"
			open={visible}
			onCancel={() => setModalOpen(false)}
			destroyOnClose={true}
			footer={null}
			data-test="critical events modal"
		>
			<div className={classes.graphsModalContent}>
				{/* TODO: Фильтры по дате и типу, нужна ручка бэка */}
				{/* <FiltersHeader
					period={period}
					setPeriod={setPeriod}
					comparisonType={comparisonType}
					setComparisonType={setComparisonType}
					loadGraphs={loadGraph}
				/> 
				<div className={classes.chartContainer}>
					<Chart loading={loadingGraph} type="area" data={chartData ?? []} unit="" config={config} />
				</div>
				*/}
				<ProTable
					data-test="health critical table"
					tableLayout="auto"
					columns={columns}
					dataSource={viewData}
					scroll={tableScroll.scrollObj}
					loading={loadingTable}
					rowKey="index"
				/>
			</div>
		</ModalComponent>
	);
};

export default CriticalEventsModal;
