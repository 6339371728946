import type { ApiLoadProperties, EntityMap, EntityName } from '~cache/apiLoadProperties';

export let apiLoadProperties: ApiLoadProperties | EmptyObject = {};

export let getEntityTitle:
	| ((entityName: EntityName, entity: EntityMap[EntityName] | undefined) => string | undefined)
	| null = null;

export const initMultiSelect = (obj: typeof apiLoadProperties) => {
	apiLoadProperties = obj;

	getEntityTitle = (entityName, entity) => {
		const title = apiLoadProperties[entityName]?.title ?? 'idField';

		if (typeof title === 'function') {
			// @ts-expect-error
			return title(entity);
		}

		return entity?.[title];
	};
};
