import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

import EnterTokenPage from '../components/EnterTokenPage';
import Login from '../components/Login';
import AuthDone from '../components/Login/auth-done';

const Logout = lazy(() => import('../components/Login/logout'));

const authRoutes = makeRoutes([
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/login-done',
		element: <AuthDone />,
	},
	{
		path: '/token',
		element: <EnterTokenPage />,
	},
	{
		path: '/logout',
		element: <Logout />,
	},
]);

export default authRoutes;
