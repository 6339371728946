import type { AxiosResponse } from 'axios';
import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';

import type { Keys, PartialEntity } from '../../apis/types';

export function makeListFn<TReq extends object, TRes extends object>(url: string) {
	return <TFields extends Keys<TRes>>(
		data: TReq & { cursor?: string | null; _fields?: TFields } = {} as TReq,
		config?: AxiosAdditionalConfig
	): Promise<
		AxiosResponse<{
			results: PartialEntity<TRes, TFields>[];
			cursor: string | null;
		}>
	> => {
		return axios
			.post<{
				result?: PartialEntity<TRes, TFields>[];
				results: PartialEntity<TRes, TFields>[];
				cursor: string | null;
			}>(url, data, config)
			.then((resp) => ({
				...resp,
				data: { results: resp.data.result ?? resp.data.results, cursor: resp.data.cursor },
			}));
	};
}
