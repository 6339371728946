import { Button } from 'antd';
import type { FunctionComponent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDirection } from '~i18n/hooks';
import { LeftOutlined, RightOutlined } from '~shared/utils/icons';

import { useStyles } from './styles';

interface Props {
	loadPage?: (cursor: string) => any;
	currentCursor?: string | null;
	loading?: boolean;
	itemsAmount?: number;
	hideItemsAmount?: boolean;
}

const TablePagination: FunctionComponent<Props> = ({
	loadPage,
	currentCursor,
	loading,
	itemsAmount,
	hideItemsAmount,
}: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const [cursors, addCursor] = useState<string[]>(['']);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [prevValues, setPrevValues] = useState<{
		page: number;
		cursor: string | null | undefined;
		items: number | undefined;
	}>({ page: 0, cursor: '', items: 0 });

	useEffect(() => {
		if (loadPage) {
			loadPage(cursors[currentPage]);
		}
	}, [currentPage]);

	useEffect(() => {
		if (currentPage === prevValues.page && itemsAmount !== prevValues.items && prevValues.items !== itemsAmount) {
			addCursor(currentCursor ? ['', currentCursor] : ['']);
			setCurrentPage(0);
		}
	}, [itemsAmount]);

	useEffect(() => {
		if (currentPage === prevValues.page && currentCursor !== prevValues.cursor) {
			addCursor(currentCursor ? ['', currentCursor] : ['']);
			setCurrentPage(0);
		} else if (currentPage === 0 && currentCursor) {
			addCursor(['', currentCursor]);
		} else if (currentPage === cursors.length - 1 && currentCursor) {
			addCursor([...cursors, currentCursor]);
		}
		setPrevValues({ page: currentPage, cursor: currentCursor, items: itemsAmount });
	}, [currentCursor]);

	const infoString = useMemo(() => {
		return itemsAmount !== undefined
			? t('Стр. {{currentPage}}; эл. {{itemsAmount}}', {
					currentPage: currentPage + 1,
					itemsAmount,
				})
			: t('Стр. {{currentPage}}', {
					currentPage: currentPage + 1,
					itemsAmount,
				});
	}, [currentPage, itemsAmount]);

	const PrevIcon = useDirection(LeftOutlined, RightOutlined);
	const NextIcon = useDirection(RightOutlined, LeftOutlined);

	return (
		<div className={classes.block}>
			<div className={classes.buttonContainer}>
				<Button
					data-test="pagination prev button"
					onClick={() => setCurrentPage(currentPage - 1)}
					disabled={loading || currentPage === 0}
					className={cx(classes.button, classes.buttonPrev)}
				>
					<PrevIcon />
				</Button>
				{!hideItemsAmount && <div className={classes.paginationInfo}>{infoString}</div>}
				<Button
					data-test="pagination next button"
					onClick={() => setCurrentPage(currentPage + 1)}
					disabled={loading || !currentCursor}
					className={cx(classes.button, classes.buttonNext)}
				>
					<NextIcon />
				</Button>
			</div>
		</div>
	);
};

export default TablePagination;
