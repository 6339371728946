import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import TreeView from '~shared/components/Fields/TreeSelectField/TreeView';
import type { Products } from '~types/products';

import { RegradingContext } from './context';
import { useStyles } from './styles';
import { getImportedValues, getProductFieldValue, priceTypes } from './utils';

interface Props {
	fieldKey: string;
	productKey: string;
}

export const ProductField = ({ fieldKey, productKey }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { products, inAssortments, price } = useContext(RegradingContext);
	const product = products[productKey];
	const imported = getImportedValues(product.vars?.imported);

	const cache = useCache({
		products: [],
	});

	let fieldValue = getProductFieldValue(
		product,
		fieldKey as keyof Products.Product,
		productKey,
		cache.products,
		inAssortments
	);

	if (
		['sizes', 'private_label', 'is_meta', 'calorie', 'fat', 'carbohydrate', 'protein', 'warehouse_group_list'].includes(
			fieldKey
		)
	) {
		fieldValue = imported && imported[fieldKey]?.length ? imported[fieldKey] : undefined;
	}

	if (
		[
			'vars.imported.netto_weight',
			'vars.imported.brutto_weight',
			'vars.imported.true_mark',
			'vars.imported.nomenclature_type, vars.imported.logistic_tags',
		].includes(fieldKey)
	) {
		const currentKey = fieldKey.split('.').pop() ?? '';
		const key = product.vars?.imported ? product.vars?.imported[currentKey] : undefined;
		fieldValue = key;

		if (currentKey === 'true_mark') {
			fieldValue = key ? t('Да') : t('Нет');
		}
	}

	if (fieldKey === 'groups') {
		return (
			<TreeView
				key={fieldKey}
				path="product_groups"
				showAllParents={true}
				value={product[fieldKey] ?? []}
				showFrontCategory={false}
			/>
		);
	}

	if (fieldKey === 'price') {
		return (
			<p>
				{Object.keys(priceTypes).map((key) => (
					<div className={classes.fieldContainer} key={key}>
						<div
							className={cx(classes.fieldLabel, {
								transparent: productKey === 'regrade',
							})}
						>
							{priceTypes[key]}
						</div>
						<div className={classes.fieldValuesContainer}>
							{price[productKey][key] ?? <span className={classes.noValue}>{t('Не заполнено')}</span>}
						</div>
					</div>
				))}
			</p>
		);
	}

	return <p key={fieldKey}>{fieldValue ?? <span className={classes.noValue}>{t('Не заполнено')}</span>}</p>;
};
