import { PageHeaderButton } from '@lavka/ui-kit';
import { notification } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import ModalComponent from '~shared/components/ModalComponent';

interface Props {
	schetTaskId: string;
	setSchetUpdate: () => void;
}

const ApproveChanges = ({ schetTaskId, setSchetUpdate }: Props) => {
	const [t] = useTranslation();

	const [loading, toggleLoading] = useState<boolean>(false);
	const [dialogModal, toggleDialogModal] = useState<boolean>(false);

	const approve = useCallback(async () => {
		toggleLoading(true);
		try {
			await api.schets.approve({
				schet_task_id: schetTaskId,
			});

			toggleDialogModal(false);
			setSchetUpdate();
			notification.success({
				message: t('Изменения подтверждены'),
			});
		} catch {
			notification.error({
				message: t('Не удалось подтвердить изменения'),
			});
		} finally {
			toggleLoading(false);
		}
	}, []);

	return (
		<>
			<ModalComponent
				open={dialogModal}
				title={t('Подтверждение расписания')}
				onCancel={() => toggleDialogModal(false)}
				okText={t('Да')}
				cancelText={t('Отмена')}
				onOk={approve}
				okButtonProps={{
					className: 'data-test-schet-accept-button',
					disabled: loading,
				}}
				className={`data-test-approve-schet-accept-modal-${dialogModal ? 'visible' : 'hidden'}`}
			>
				<p>{t('Вы уверены, что хотите подтвердить новое расписание задания?')}</p>
			</ModalComponent>
			<PageHeaderButton data-test="schet approve btn" onClick={() => toggleDialogModal(true)} view="header-accent">
				{t('Подтвердить изменения')}
			</PageHeaderButton>
		</>
	);
};

export default ApproveChanges;
