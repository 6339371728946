import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useStyles } from '~shared/components/Charts/styles';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

interface RechartsProps {
	active?: boolean;
	label: string;
	payload: [
		{
			value: number;
			[key: string]: any;
		},
	];
}

interface OwnProps {
	size: 'small' | 'big' | 'huge';
	detailLabels: Record<string, string>;
	unit?: string;
	detailsList?: string[];
	valueFormatter?: any;
}

const defaultDetailLabels = makeDynamicTranslations({
	check_valid: tDynamic('КСГ'),
	damage: tDynamic('Брак'),
	recount: tDynamic('Недостачи'),
	refund: tDynamic('Возвраты'),
	not_delivered: tDynamic('Не привезли товар'),
	wrong_product: tDynamic('Перепутали товар'),
	wrong_order: tDynamic('Полностью неверный заказ'),
	bad_product: tDynamic('Испорченный продукт'),
	overripe_product: tDynamic('Перезревший продукт'),
	freeze_product: tDynamic('Продукт заморожен'),
	unfreeze_product: tDynamic('Продукт разморожен'),
	lifetime_limit: tDynamic('Срок годности истёк'),
	damaged_product: tDynamic('Товар повреждён'),
	overcooked: tDynamic('Переготовка'),
	reorder: tDynamic('Остальное'),
	total: tDynamic('Всего'),
});

const classMap = {
	small: 'smallTooltipContainer',
	big: 'bigTooltipContainer',
	huge: 'hugeTooltipContainer',
} as const;

type Props = RechartsProps & OwnProps;

const WMSTooltip = ({
	active,
	label,
	payload,
	size,
	detailLabels = defaultDetailLabels,
	unit,
	detailsList,
	valueFormatter,
}: Props) => {
	const { cx, classes } = useStyles();
	const [t] = useTranslation();
	const content = (payload && payload[0]) || {};

	const detailsVisible = detailsList ?? Object.keys(content.payload?.details || {});

	const hasDetailsInfo = !!(
		content.payload?.details && detailsVisible.filter((detail) => !!content.payload.details[detail].value).length
	);

	const isValueFine = (detail: { value?: number; threshold_max?: number; threshold_min?: number }) => {
		const { value, threshold_max, threshold_min } = detail;
		if (value && threshold_max && threshold_min) {
			return value < threshold_max && value > threshold_min;
		} else if (value && threshold_max) {
			return value < threshold_max;
		} else if (value && threshold_min) {
			return value > threshold_min;
		} else return true;
	};

	return (
		active && (
			<div className={cx(classes.tooltipContainer, classes[classMap[size]])}>
				{size === 'huge' ? (
					<>
						<div className={classes.flexColumn}>
							<span className={classes.smallText}>{t('Дата')}</span>
							<span>
								{dayjs(content.payload?.fullDate)
									.locale('ru') // TODO: поддержать любую локаль
									.format('DD.MM.YYYY, dddd')}
							</span>
							<span className={classes.bigText}>{t('Всего {{ value }}%', { value: content.value })}</span>
						</div>
						<div className={cx(classes.flexRow, classes.hugeBottom)}>
							{content.payload?.details &&
								detailsVisible.map(
									(detail) =>
										detail !== 'total' && (
											<div key={detail} className={cx(classes.flexColumn, classes.detailBlock)}>
												<span>{detailLabels[detail]}</span>
												<div className={cx(classes.flexRow, classes.hugeDetailValue)}>
													{content.payload.details[detail]?.threshold_max && (
														<div className={classes.flexColumn}>
															<span className={classes.smallText}>{t('Норма')}</span>
															<span className={classes.normText}>{content.payload.details[detail].threshold_max}%</span>
														</div>
													)}
													<div className={classes.flexColumn}>
														<span className={classes.smallText}>{t('Значение')}</span>
														<span
															className={cx({
																[classes.badValue]: !isValueFine(content.payload.details[detail]),
															})}
														>
															{content.payload.details[detail]?.value}%
														</span>
													</div>
												</div>
											</div>
										)
								)}
						</div>
					</>
				) : (
					<>
						<div
							className={cx({
								[classes.verticalDataBlock]: size === 'small',
								[classes.topDataBlock]: size === 'big',
							})}
						>
							<p className={classes.smallDataBlock}>
								<span className={classes.smallLabel}>{t('Дата')}</span>
								<span className={classes.smallData}>
									{content.payload?.fullDate
										? dayjs(content.payload?.fullDate)
												.locale('ru') // TODO: поддержать любую локаль
												.format('DD.MM.YYYY')
										: label}
								</span>
							</p>
							{/*
							{typeof content.payload?.threshold === 'number' && (
								<p className={classes.smallDataBlock}>
									<span className={classes.smallLabel}>{t('Норма')}</span>
									<span className={cx(classes.smallData, classes.normText)}>
										{typeof content.payload?.threshold === 'number'
											? `${content.payload.threshold}${unit}`
											: '—'}
									</span>
								</p>
							)}
							*/}
							<p className={classes.smallDataBlock}>
								<span className={classes.smallLabel}>{t('Значение')}</span>
								<span
									className={cx(classes.smallData, {
										[classes.aboveNormText]:
											content?.payload?.threshold && content?.value && content.value > content.payload.threshold,
									})}
								>
									{typeof content?.value === 'number'
										? `${valueFormatter ? valueFormatter(content.value) : content.value}${unit}`
										: '—'}
								</span>
							</p>
						</div>
						{!!(size === 'big' && content.payload?.details && hasDetailsInfo) && (
							<div>
								<h3 className={classes.tooltipSubtitle}>{t('Подробности')}</h3>
								{Object.keys(content.payload.details)
									.filter((detail) => !!content.payload.details[detail].value)
									.map((detail, i) => (
										<p className={classes.bottomDataBlock} key={i}>
											<span className={classes.smallLabel}>{detailLabels[detail]}</span>
											<span className={classes.dotSeparator} />
											<span className={classes.smallLabel}>{content.payload.details[detail].value}</span>
										</p>
									))}
							</div>
						)}
					</>
				)}
			</div>
		)
	);
};

export default WMSTooltip;
