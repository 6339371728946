import * as z from 'zod';

import { languages } from '~i18n/consts';
import { areArraysIntersect } from '~shared/utils/arrayDiff';
import { phoneRegex } from '~shared/utils/fieldsValidation';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { User } from '~types/user';

import { userNameFields } from './utils';

const EMAIL_FORMAT_ERROR = makeDynamicTranslations({
	message: tDynamic('Некорректный e-mail'),
});
const PHONE_FORMAT_ERROR = makeDynamicTranslations({
	message: tDynamic('Некорректный номер телефона'),
});

export const getSchema = (addMode: boolean, canEditPersonalDataFields: boolean, dirtyFieldsArray: string[]) => {
	const isUserNameDirty = areArraysIntersect(userNameFields, dirtyFieldsArray);
	return addMode
		? (z.object({
				role: z.string(),
				store_id: z.string().optional(),
				email: z.string().email(EMAIL_FORMAT_ERROR).optional().or(z.literal('')),
				phone: z
					.string()
					.refine((val) => val && phoneRegex.test(val.replaceAll(/\-| |\(|\)/g, '')), PHONE_FORMAT_ERROR)
					.optional()
					.or(z.literal('')),
				email_pd_id: z.string().optional(),
				phone_pd_id: z.string().optional(),
				nick: z.string().nullish(),
				last_name: z.string(),
				first_name: z.string(),
				middle_name: z.string().nullish(),
				external_id: z.string().optional(),
				employee_number: z.string().nullish(),
			}) satisfies z.ZodType<User.UserCreate>)
		: (z.object({
				user_id: z.string(),
				role: z.string().optional(),
				nick: z.string().optional(),
				last_name: isUserNameDirty && canEditPersonalDataFields ? z.string().optional() : z.string().nullish(),
				first_name: isUserNameDirty && canEditPersonalDataFields ? z.string().optional() : z.string().nullish(),
				middle_name: z.string().nullish(),
				change_barcode: z.boolean().optional(),
				store_id: z.string().nullish(),
				status: z.string().optional(),
				company_id: z.string().optional(),
				stores_allow: z.array(z.string()).optional(),
				clusters_allow: z.array(z.string()).optional(),
				email: canEditPersonalDataFields
					? z.string().email(EMAIL_FORMAT_ERROR).optional().or(z.literal(''))
					: z.string().email(EMAIL_FORMAT_ERROR).nullish().or(z.literal('')),
				phone: canEditPersonalDataFields
					? z
							.string()
							.refine((val) => val && phoneRegex.test(val.replaceAll(' ', '')), PHONE_FORMAT_ERROR)
							.optional()
							.or(z.literal(''))
					: z
							.string()
							.refine((val) => val && phoneRegex.test(val.replaceAll(' ', '')), PHONE_FORMAT_ERROR)
							.nullish()
							.or(z.literal('')),
				email_pd_id: z.string().nullish(),
				phone_pd_id: z.string().nullish(),
				lang: z.enum(languages).optional(),
				employee_number: z.string().nullish(),
			}) satisfies z.ZodType<User.UserUpdate>);
};
