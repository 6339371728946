import { blue, gold, red, yellow } from '@ant-design/colors';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useMemo } from 'react';

import { gray, headerGrey, lightGrey } from '~styles/colors';
import { useUserTheme } from '~zustand/userSettings';

const themeCreator = (themeMode: 'light' | 'dark') => {
	const isLight = themeMode === 'light';
	return {
		themeMode,
		isLight,
		text: {
			default: isLight ? 'black' : 'white',
			primary: isLight ? gray[8] : gray[3],
			menuItem: { hover: isLight ? 'rgba(0,0,0, .04)' : red[4] },
			secondary: isLight ? gray[7] : gray[5],
			light: isLight ? lightGrey : gray[6],
			logo: isLight ? gray[8] : gray[2],
			disabled: isLight ? gray[5] : gray[6],
			selected: isLight ? blue[5] : gray[2],
			icon: isLight ? gray[6] : gray[4],
			btnLink: isLight ? '#1890ff' : '#177DDC',
			statusNeutral: isLight ? gray[8] : '#ACACAC',
			statusGreen: isLight ? '#389e0d' : '#49AA19',
			statusVolcano: isLight ? '#d4380d' : '#D84A1B',
			statusBlue: isLight ? '#096dd9' : '#177DDC',
			statusPurple: isLight ? '#531dab' : '#854ECA',
			statusRed: isLight ? '#cf1322' : '#D32029',
			statusGold: isLight ? '#d48806' : '#D89614',
			statusMagneta: isLight ? '#c41d7f' : '#CB2B83',
			statusLime: isLight ? '#7cb305' : '#8BBB11',
			statusCyan: isLight ? '#08979c' : '#13A8A8',
			statusGeekblue: isLight ? '#1d39c4' : '#2B4ACB',
			radioButton: isLight ? '#1890FF' : '#177DDC',
			textField: {
				errorMessage: isLight ? red[5] : red[5],
			},
			// Для темной темы цвета остаются такими же
			deviation: {
				positive: gold[5],
				negative: red[5],
			},
			// Для темной темы цвет остается таким же
			dropdownCalendar: {
				text: '#000000d9',
			},
			white: '#fff',
			black: '#000',
			label: '#874D00',
			tooltipLabel: '#BFBFBF',
			tooltipData: gray[2],
			metricsValue: isLight ? '#858585' : '#858585',
			widgetAmount: isLight ? '#1c1d20' : '#1c1d20',
			ordersText: isLight ? '#1c1d20' : gray[3],
			surge: '#5500cc',
			docNumber: gray[6],
			error: '#ff3d2b',
			blockHeader: isLight ? 'rgba(0, 0, 0, .8)' : '#fff',
			ordersTabs: isLight ? '#1C1D20' : '#858585',
			tableau: {
				labelColor: '#29313B',
			},
		},
		border: {
			primary: isLight ? gray[2] : gray[7],
			secondary: isLight ? gray[4] : gray[6],
			disabled: isLight ? gray[3] : gray[6],
			card: isLight ? gray[0] : gray[7],
			button: isLight ? '#D9D9D9' : gray[0],

			statusNeutral: isLight ? gray[4] : '#434343',
			statusGreen: isLight ? '#b7eb8f' : '#274916',
			statusVolcano: isLight ? '#d4380d' : '#582518',
			statusBlue: isLight ? '#91d5ff' : '#15395B',
			statusPurple: isLight ? '#d3adf7' : '#301C4D',
			statusRed: isLight ? '#ffa39e' : '#581F19',
			statusGold: isLight ? '#ffe58f' : '#594014',
			statusMagneta: isLight ? '#ffadd2' : '#551C3B',
			statusLime: isLight ? '#eaff8f' : '#3E4F13',
			statusCyan: isLight ? '#87e8de' : '#15474D',
			statusGeekblue: isLight ? '#adc6ff' : '#1C2755',
			metricsTale: isLight ? '#f2f2f1' : '#f2f2f1',
			ordersFilters: isLight ? '#f2f2f1' : gray[9],
			widgetContent: isLight ? 'rgba(227, 227, 224, .5)' : 'rgba(227, 227, 224, .5)',
			controlsHeader: isLight ? '#E3E3E0' : gray[4],
		},
		bgColor: {
			main: isLight ? gray[0] : gray[8],
			header: isLight ? gray[0] : headerGrey,
			secondary: isLight ? gray[1] : gray[7],
			activeSelect: isLight ? gray[3] : gray[6],
			sort: isLight ? gray[1] : headerGrey,
			button: isLight ? gray[0] : headerGrey,
			loginButton: isLight ? '#f2f2f1' : '#2b2d2e',
			disabled: isLight ? gray[2] : headerGrey,
			loader: isLight ? 'rgba(0, 0, 0, 0.01%)' : gray[8],
			switch: isLight ? gray[5] : gray[7],
			notification: isLight ? gray[0] : gray[7],
			badge: isLight ? '#ff4d4f' : '#d4380d',
			label: '#FFEC3D',
			sider: process.env.REACT_APP_MODE === 'testing' ? (isLight ? gray[4] : gray[9]) : isLight ? gray[0] : gray[8],
			popover: isLight ? gray[1] : gray[9],
			card: isLight ? gray[1] : headerGrey,
			tooltip: 'black',
			table: {
				hover: isLight ? gray[2] : gray[7],
				header: isLight ? gray[1] : headerGrey,
				problem: isLight ? red[0] : 'rgba(211, 32, 41, 0.05)',
				warning: isLight ? yellow[0] : '#fffbe6',
			},
			field: {
				option: {
					hover: isLight ? 'rgba(245, 245, 245, .4)' : gray[7],
					active: isLight ? '#fafafa' : gray[7],
				},
			},
			menu: {
				itemSelected: isLight ? blue[0] : gray[7],
			},
			statusNeutral: isLight ? gray[1] : gray[8],
			statusLightBlue: isLight ? '#00bfff' : '#00bfff',
			statusGreen: isLight ? '#f6ffed' : '#1A2411',
			statusVolcano: isLight ? '#fff2e8' : '#2A1712',
			statusBlue: isLight ? '#e6f7ff' : '#111C28',
			statusPurple: isLight ? '#f9f0ff' : '#1F193C',
			statusRed: isLight ? '#fff1f0' : '#291517',
			statusGold: isLight ? '#fffbe6' : '#2B2311',
			statusMagneta: isLight ? '#fff0f6' : '#291321',
			statusLime: isLight ? '#fcffe6' : '#1E2511',
			statusCyan: isLight ? '#e6fffb' : '#121D24',
			statusGeekblue: isLight ? '#f0f5ff' : '#111C28',
			radioButton: isLight ? '#E6F7FF' : '#15395B',
			icon: {
				active: blue[5],
				inviteLink: {
					disabled: isLight ? gray[5] : gray[6],
					active: isLight ? '#fff' : '#fff',
				},
			},
			indicator: {
				active: '#1aac02',
				disabled: red[5],
			},
			hover: isLight ? blue[1] : gray[7],
			mobileTableau: {
				section: '#ffffff',
				fillRate: '#eaeae7',
				gradient: isLight
					? 'linear-gradient(180deg, #99deff 0%, #ffffff 45.69%)'
					: 'linear-gradient(180deg, #99deff 0%, #ffffff 45.69%)',
				surgeGradient: isLight
					? 'linear-gradient(180deg, #c699ff 0%, #ffffff 45.69%)'
					: 'linear-gradient(180deg, #c699ff 0%, #ffffff 45.69%)',
				operationsIcon: isLight ? '#ffa60d' : '#ffa60d',
				couriersIcon: isLight ? '#00c24a' : '#00c24a',
				staffIcon: isLight ? '#265cff' : '#265cff',
				expandIcon: '#f7f7f6',
				modeSwitcher: '#363f4a',
				modeSwitcherContainer: '#29313b',
				openDesktop: 'rgba(92, 90, 87, 0.1)',
			},
			couriersList: isLight ? '#F7F7F6' : gray[8],
			closeCouriersListButton: isLight ? '#FFFFFF' : gray[5],
			buttonDispatchActive: isLight ? '#E3E3E0' : gray[4],
			buttonDispatchHover: isLight ? '#EAEAE7' : gray[5],
			equipmentCard: isLight ? '#fff' : '#000',
		},
		bgColorTesting: {
			main: isLight ? gray[2] : '#a96666',
		},
		shadow: isLight ? 'rgba(0, 0, 0, 0.15) 0px 2px 8px 0px' : 'rgba(255, 255, 255, 0.15) 0px 2px 8px 0px',
		softShadow: '0px 2px 16px 0px rgba(89, 89, 89, 0.08)',
		popoverShadow: isLight
			? '0px 2px 4px rgba(89, 89, 89, 0.04), 0px 2px 16px rgba(89, 89, 89, 0.08)'
			: '0px 2px 4px rgba(225, 225, 225, 0.15), 0px 2px 16px rgba(225, 225, 225, 0.3)',
		calendarDayPickerShadow: isLight ? '0px 4px 16px rgba(0, 0, 0, 0.04)' : '0px 4px 16px rgba(0, 0, 0, 0.04)',
		transparentColor: 'transparent',
		metricsTaleShadow: isLight ? '0px 4px 16px 0px #2b29240f' : '0px 4px 16px 0px #2b29240f',
		widgetShadow: isLight ? '0px 4px 16px rgba(43, 41, 36, 0.06)' : '0px 4px 16px rgba(43, 41, 36, 0.06)',
		modeSwitcherShadow: '0px 2px 25px 0px rgba(121, 121, 121, .3)',
		uiKit: {
			semantic: {
				textMinor: 'var(--lavka-ui-kit-color-text-minor)',
				textOnError: 'var(--lavka-ui-kit-color-text-on-error)',
				error: 'var(--lavka-ui-kit-color-error)',

				statusColors: {
					waitingText: 'var(--lavka-ui-kit-color-status-waiting-text)',
					waitingBackground: 'var(--lavka-ui-kit-color-status-waiting-bg)',
					errorBackground: 'var(--lavka-ui-kit-color-status-error-bg)',
				},
				typography: {
					caption1: 'var(--lavka-ui-kit-typography-font-size-caption1)', // 13px
					caption2: 'var(--lavka-ui-kit-typography-font-size-caption2)', // 11px
					lineHeightCaption1: 'var(--lavka-ui-kit-typography-line-height-caption1-normal)', // 15px
					lineHeightCaption2: 'var(--lavka-ui-kit-typography-line-height-caption2-normal)', // 13px
				},
			},
		},
	};
};

export type Theme = ReturnType<typeof themeCreator>;

export const ThemeContext = createContext<Theme>(themeCreator('light'));

const ThemeComponent: FC<PropsWithChildren<unknown>> = ({ children }) => {
	const themeMode = useUserTheme();
	const theme = useMemo(() => themeCreator(themeMode), [themeMode]);

	return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};

export default ThemeComponent;
