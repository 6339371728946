import { Spin } from 'antd';
import type { JSX } from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import type { EntityMap, EntityName } from '~cache/apiLoadProperties';
import { useCache } from '~cache/useCache';
import { EmptyValue } from '~shared/components/Fields/utils';

export type Props = {
	id?: string;
	dictionary?: Record<string, string>;
	component?: (data: any) => JSX.Element;
	cutSymbols?: number;
	dataTest?: string;
	loading?: boolean;
	className?: string;
	link?: string;
	isLinkAccessible?: boolean;
	textEmpty?: boolean;
} & { [K in EntityName]: { type: K; keyReplaceOn: keyof EntityMap[K] | Array<keyof EntityMap[K]> } }[EntityName];

const IdReplacer = ({
	type,
	id = '',
	keyReplaceOn,
	dictionary,
	component,
	cutSymbols,
	dataTest,
	loading,
	className,
	link,
	isLinkAccessible = true,
	textEmpty,
}: Props) => {
	// @ts-expect-error
	const cache = useCache({
		[type]: {
			ids: id,
			_fields: Array.isArray(keyReplaceOn) ? keyReplaceOn : [keyReplaceOn],
			skip: true,
		},
	});

	const data = cache[type]?.[id];

	let text = id;
	if (data) {
		if (Array.isArray(keyReplaceOn)) {
			text = keyReplaceOn
				.map((key) => data[key])
				.filter(Boolean)
				.join(' ');
		} else {
			text = data[keyReplaceOn];
		}
	}

	if (component) {
		return <>{component(data ? text : undefined)}</>;
	}
	const value = dictionary ? dictionary[text] : text;
	const truncated = cutSymbols && value?.length > cutSymbols;
	const valueToShow = truncated ? `${value.slice(0, cutSymbols)}...` : value;
	if (loading) {
		return <Spin size="small" />;
	}
	if (value) {
		if (link && isLinkAccessible) {
			return (
				<Link to={link} className={className} data-test={dataTest ? dataTest : `replaced id ${value}`}>
					{valueToShow}
				</Link>
			);
		} else {
			return (
				<span className={className} data-test={dataTest ? dataTest : `replaced id ${value}`}>
					{valueToShow}
				</span>
			);
		}
	} else {
		return <span data-test={dataTest}>{textEmpty ? <EmptyValue /> : '—'}</span>;
	}
};

export default memo(IdReplacer);
