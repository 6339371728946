import { Input, Select } from 'antd';
import type { Dispatch, KeyboardEventHandler, SetStateAction } from 'react';

import type { FieldConfig, searchFieldsConfig } from './searchFieldsConfig';

interface Props {
	kwarg: keyof typeof searchFieldsConfig;
	fieldConfig: FieldConfig;
	onChange: Dispatch<SetStateAction<Record<string, string>>>;
	value: string;
	onKeyDown: KeyboardEventHandler<HTMLInputElement>;
}

const SearchField = ({ kwarg, fieldConfig, onChange, value, onKeyDown }: Props) => {
	const render = () => {
		switch (fieldConfig.type) {
			case 'text':
				return (
					<Input
						placeholder={fieldConfig.placeholder}
						onChange={(e) => onChange((pre) => ({ ...pre, [kwarg]: e.target.value }))}
						value={value}
						onKeyDown={onKeyDown}
					/>
				);
			case 'select':
				return (
					<Select
						options={fieldConfig.options}
						onChange={(value) => onChange((pre) => ({ ...pre, [kwarg]: value }))}
						value={value}
						defaultValue={fieldConfig.options?.[0].value}
					/>
				);
		}
	};
	return <div>{render()}</div>;
};

export default SearchField;
