import { notification } from 'antd';
import type { AxiosRequestConfig, CancelToken as CancelTokenType } from 'axios';
import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { appSessionId, appVersion } from '~/appConfig';
import i18n from '~/i18n';
import { availableWithoutAuth } from '~constants/availableWithoutAuth';
import { errorsCode } from '~constants/errorsCode';
import { axiosEv } from '~shared/utils/ev';
import { isTvClientMode } from '~shared/utils/isTvClientMode';
import { getToken, removeCredentialsFromLocalStorage } from '~shared/utils/localStorage';
import { logout } from '~shared/utils/loginFunctions';
import { logAxiosError } from '~shared/utils/logs';
import { orderEventsUnsubscribe } from '~zustand/actions/userData';
import { getIsOnline, turnAppOffline, turnAppOnline } from '~zustand/metaInfo';
import { checkPermit, getUserData } from '~zustand/userData';

import assortmentProduct from './apis/assortmentProduct';
import assortments from './apis/assortments';
import bonuses from './apis/bonuses';
import checkProjects from './apis/checkProjects';
import clusters from './apis/clusters';
import companies from './apis/companies';
import compensationsAdjustments from './apis/compensationsAdjustments';
import configs from './apis/configs';
import courierBio from './apis/courierBio';
import couriers from './apis/couriers';
import courierServices from './apis/courierServices';
import courierShifts from './apis/courierShifts';
import courierShiftSchedules from './apis/courierShiftSchedules';
import courierShiftTags from './apis/courierShiftTags';
import devices from './apis/devices';
import dxgy from './apis/dxgy';
import equipment from './apis/equipment';
import falcon from './apis/falcon';
import file from './apis/file';
import files from './apis/files';
import financeWallets from './apis/financeWallets';
import ciInterceptor from './apis/interceptors/ci';
import logTvDeviceInterceptor from './apis/interceptors/logTvDevice';
import items from './apis/items';
import legalEntities from './apis/legalEntities';
import orderFiles from './apis/orderFiles';
import orders from './apis/orders';
import ordersMinified from './apis/ordersMinified';
import priceListDrafts from './apis/priceListDrafts';
import priceListDraftsProducts from './apis/priceListDraftsProducts';
import priceListProducts from './apis/priceListProducts';
import priceLists from './apis/priceLists';
import productGroups from './apis/productGroups';
import products from './apis/products';
import profile from './apis/profile';
import qrActions from './apis/qrActions';
import reportData from './apis/reportData';
import samples from './apis/samples';
import schets from './apis/schets';
import search from './apis/search';
import shelves from './apis/shelves';
import stashes from './apis/stashes';
import stocks from './apis/stocks';
import stocksLog from './apis/stocksLog';
import storePrintTasks from './apis/storePrintTasks';
import storeProblems from './apis/storeProblems';
import stores from './apis/stores';
import suggests from './apis/suggests';
import support from './apis/support';
import supTools from './apis/suptools';
import switchForZones from './apis/switchForZones';
import tariffPlans from './apis/tariffPlans';
import tvClient from './apis/tvClient';
import users from './apis/users';
import userSearch from './apis/userSearch';
import usersLog from './apis/usersLog';
import wallets from './apis/wallets';
import weather from './apis/weather';
import yandexMaps from './apis/yandexMaps';
import zones from './apis/zones';
import { router } from './router';

axiosBetterStacktrace(axios);

const CancelToken = axios.CancelToken;
let cancel: any;

export type AxiosAdditionalConfig = {
	disableDefaultNotification?: number[];
	disableExternalLog?: number[];
	cancelToken?: CancelTokenType;
	signal?: AbortSignal;
	strict?: boolean;
	force?: boolean;
};

function handleApiErrors(error: any) {
	if (axios.isCancel(error)) {
		console.log(`Request cancelled: ${error.message}`); // пока будем логгировать все сбросы запросов
		return {
			data: {},
			status: 'CANCELED',
		};
	}

	const status = error?.response?.status as number | undefined;
	const config = (error?.response?.config ?? {}) as AxiosRequestConfig<unknown> & AxiosAdditionalConfig;

	if (isTvClientMode()) {
		// Обработка авторизационных ошибок для табло, авторизованного на мониторе
		if (status === 401) {
			removeCredentialsFromLocalStorage();
			void router.navigate('/tv-client/tableau/auth');
		} else if (error) {
			// любые другие ошибки будут проигнорированы
			return error.response;
		}
	} else if (status === 401 && !availableWithoutAuth.includes(window.location.pathname)) {
		void logout(true);
	} else if (status === 408) {
		removeCredentialsFromLocalStorage();
	} else if (!axios.isCancel(error)) {
		const shouldRedirect = !!(config.strict && status === 403);

		if (config.url?.includes('/disp/orders/load') && status === 403 && !shouldRedirect) {
			// если в диспетчерской, то переподкрепляемся к другому складу, в остальных случаях просто переподписываемся
			if (window.location.pathname.includes('/orders') && checkPermit('out_of_store')) {
				void getUserData(true);
			} else {
				void orderEventsUnsubscribe();
			}
			return;
		}

		let showNotification = true;
		let externalLog = true;

		if (status && config.disableDefaultNotification?.includes(status)) {
			showNotification = false;
		}
		if (status && config.disableExternalLog?.includes(status)) {
			externalLog = false;
		}

		if (showNotification && !isTvClientMode() && !shouldRedirect) {
			(async () => {
				let errorData = error?.response?.data ?? {};
				if (errorData instanceof Blob) {
					try {
						errorData = JSON.parse(await errorData.text());
					} catch {
						errorData = {};
					}
				}

				notification.error({
					message: errorsCode[errorData.code] ?? errorData.code,
					description: errorData.message ?? i18n.t('Ошибка запроса'),
				});
			})();
		}
		if (externalLog) {
			logAxiosError(error);
		}
		if (shouldRedirect) {
			cancel();
			error.response.strict = true;
		}
	}
	return error.response;
}

axios.defaults.baseURL = '/';

axios.defaults.headers.common = {
	'X-WMS-UI-Version': appVersion,
	'X-Request-Id': appSessionId,
};

ciInterceptor(axios);
logTvDeviceInterceptor(axios);

axios.interceptors.request.use(
	(config) => {
		if (!config.cancelToken) {
			config.cancelToken = new CancelToken((c) => {
				cancel = c;
			});
		}
		return config;
	},
	() => turnAppOffline()
);

axios.interceptors.response.use(
	(response) => {
		const isOnline = getIsOnline();
		if (!isOnline) {
			turnAppOnline();
		}

		return response;
	},
	(error) => {
		const isOnline = getIsOnline();
		// этот способ стабильнее остальных -
		// другие выкидывают в офлайн, когда никакого офлайна нет
		error.message?.startsWith('Network Error') ? turnAppOffline() : !isOnline && turnAppOnline();
		const errors = handleApiErrors(error);
		if (errors) {
			throw errors;
		}
	}
);

export default {
	profile,
	falcon,
	stores,
	zones,
	shelves,
	users,
	userSearch,
	usersLog,
	productGroups,
	assortments,
	products,
	items,
	assortmentProduct,
	orders,
	priceLists,
	priceListProducts,
	priceListDrafts,
	priceListDraftsProducts,
	storePrintTasks,
	stocks,
	stocksLog,
	companies,
	support,
	search,
	courierShifts,
	couriers,
	courierShiftTags,
	courierServices,
	courierShiftSchedules,
	clusters,
	configs,
	compensationsAdjustments,
	reportData,
	ordersMinified,
	storeProblems,
	yandexMaps,
	stashes,
	switchForZones,
	schets,
	tariffPlans,
	bonuses,
	dxgy,
	tvClient,
	devices,
	weather,
	qrActions,
	checkProjects,
	financeWallets,
	files,
	file,
	wallets,
	samples,
	supTools,
	equipment,
	suggests,
	courierBio,
	legalEntities,
	orderFiles,
} as const;

function setHeader(header: string, value?: string) {
	if (value) {
		axios.defaults.headers.common[header] = value;
		axiosEv.defaults.headers.common[header] = value;
	} else {
		delete axios.defaults.headers.common[header];
		delete axiosEv.defaults.headers.common[header];
	}
}

export function setUserRoleHeader(role?: string) {
	setHeader('X-Request-User-Role', role);
}

export function setAuthorizationHeader(token?: string) {
	setHeader('Authorization', token ? `Bearer ${token}` : undefined);
}

setAuthorizationHeader(getToken());

if (process.env.REACT_APP_MODE === 'ci') {
	setHeader('X-Forwarded-Host', 'yandex.localhost');
}
