import { PageHeaderButton } from '@lavka/ui-kit';
import { Badge, Button, notification, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { ReactComponent as QuestionBubble } from '~images/icons/question-bubble.svg';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import useSubscribe from '~shared/hooks/useSubscribe';
import { CommentDotRegularIcon, CommentRegularIcon } from '~shared/ui-kit/icons';
import { Audioplayer } from '~shared/utils/audio';
import type { Event } from '~shared/utils/ev';
import { getSupportChatIframeUrl } from '~shared/utils/getSupportChatIframeUrl';
import Icon from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';
import { useCheckExp, useUser } from '~zustand/userData';

import ModalComponent from '../ModalComponent';
import { useStyles } from './styles';

interface Props {
	orderId?: string;
	externalId?: string;
	inHeader?: boolean;
}

const NotificationBtn = ({ orderId, externalId, inHeader }: Props) => {
	const [t] = useTranslation();
	const { cx, classes: commonClasses } = useCommonStyles();
	const user = useUser();
	const [amount, setAmount] = useState<number>(0);
	const [initialAmount, setInitialAmount] = useState<number | undefined>();
	const [chatUrl, setChatUrl] = useState('');
	const [showModal, setShowModal] = useState(false);
	const { classes } = useStyles();

	const isEnabled = useCheckExp('exp_socrates');

	useAsyncEffect(async () => {
		if (process.env.REACT_APP_MODE !== 'production') return;

		try {
			const { data } = await api.stores.getMessagesCount();
			setInitialAmount(data.count);
			setAmount(data.count);
		} catch {
			notification.error({
				message: t('Не удалось получить сообщения'),
			});
		}
	}, [user.store_id]);

	const clickHandler = async (listenerOne: any, listenerTwo: any) => {
		removeEventListener('keydown', listenerOne);
		removeEventListener('keyup', listenerTwo);
		try {
			const { data } = await api.support.url();
			setChatUrl(data.url);
			setShowModal(true);
		} catch {
			notification.error({
				message: 'Ошибка открытия чата поддержки',
			});
		}
	};

	const subscribeHandler = (eventData: Event) => {
		if (eventData) {
			const notificationData = eventData.data.filter((item: any) => item.type === 'messages_count');
			setAmount(notificationData[notificationData.length - 1]?.count);
		}
	};

	useSubscribe(
		{
			key: ['store', user.store_id as string],
			cb: subscribeHandler,
			unSub: false,
			single: true,
			name: 'message_count',
		},
		[user.store_id],
		!user.store_id
	);

	useEffect(() => {
		if (amount > 0 && amount !== initialAmount) {
			Audioplayer.play('new_message_notice.mp3');
			setInitialAmount(amount);
		}
	}, [amount]);

	const combination = new Set();

	const onKeyPressIncreaseCombination = (evt: any) => {
		if (evt.keyCode === 17 || (evt.keyCode === 83 && isEnabled)) {
			combination.add(evt.keyCode);
		}
		if (combination.size > 1) {
			void clickHandler(onKeyPressIncreaseCombination, onKeyPressDecreaseCombination);
		}
	};

	const onKeyPressDecreaseCombination = (evt: any) => {
		if (evt.keyCode === 17 || (evt.keyCode === 83 && isEnabled)) {
			combination.delete(evt.keyCode);
		}
	};

	useEffect(() => {
		addEventListener('keydown', onKeyPressIncreaseCombination);
		addEventListener('keyup', onKeyPressDecreaseCombination);
		return () => {
			removeEventListener('keyup', onKeyPressIncreaseCombination);
			removeEventListener('keyup', onKeyPressDecreaseCombination);
		};
	}, []);

	return (
		<>
			<Tooltip title={!isEnabled ? t('Чат с поддержкой (функция еще недоступна)') : t('Чат с поддержкой')}>
				{inHeader ? (
					<PageHeaderButton
						variant="icon"
						view="header-accent"
						disabled={!isEnabled}
						onClick={() => {
							void clickHandler(onKeyPressIncreaseCombination, onKeyPressDecreaseCombination);
						}}
						data-test="notifications"
						iconLeft={
							amount > 0 ? (
								<CommentDotRegularIcon width={20} height={20} />
							) : (
								<CommentRegularIcon width={20} height={20} />
							)
						}
					/>
				) : (
					<Badge count={amount} size="small">
						<Button
							disabled={!isEnabled}
							className={cx(commonClasses.btnIconBordered, {
								[commonClasses.btnIconDisabled]: !isEnabled,
							})}
							onClick={() => {
								clickHandler(onKeyPressIncreaseCombination, onKeyPressDecreaseCombination);
							}}
							data-test="notifications"
						>
							<Icon component={QuestionBubble} />
						</Button>
					</Badge>
				)}
			</Tooltip>
			<ModalComponent
				title={t('Служба поддержки')}
				width={920}
				className={commonClasses.supportChatModal}
				open={showModal}
				onCancel={() => {
					addEventListener('keydown', onKeyPressIncreaseCombination);
					addEventListener('keyup', onKeyPressDecreaseCombination);
					return setShowModal(false);
				}}
				footer={null}
			>
				<iframe
					src={getSupportChatIframeUrl({ url: chatUrl, orderId, externalId })}
					width="100%"
					height="95%"
					frameBorder="0"
					className={classes.inverter}
				/>
			</ModalComponent>
		</>
	);
};

export default NotificationBtn;
