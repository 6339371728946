import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	buttonsBar: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBlock: '24px',
		paddingInline: '0',
	},
	button: {
		marginInlineStart: 12,
	},
	progress: {
		inlineSize: '100%',
		blockSize: 4,
		display: 'flex',
		alignItems: 'center',
		'&>div': {
			inlineSize: '100%',
		},
	},
	errorString: {
		whiteSpace: 'normal',
	},
	modalForm: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
	},
	modalField: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		'& > *': {
			flexBasis: '50%',
		},
	},
	devicesHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		inlineSize: '50%',
		margin: 0,
		marginBlockEnd: 20,
	},
	devicesList: {
		display: 'flex',
		flexDirection: 'column',
		inlineSize: '50%',
		gap: 10,
		marginBlock: '20px',
		marginInline: '0',
		'& > *': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
	listTitle: {
		display: 'flex',
		marginBlock: '20px',
		marginInline: '0',
		fontWeight: 600,
		fontSize: 16,
		alignItems: 'center',
		color: theme.text.primary,
	},
	modalCentered: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	productSchedulesButtons: {
		marginBlock: '15px',
		marginInline: '0',
		display: 'flex',
		gap: 10,
		flexWrap: 'wrap',
	},
	addScheduleTypeButton: {
		marginInlineEnd: 10,
		paddingInline: '5px',
	},
	scheduleTypeHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBlock: '10px',
		marginInline: '0',
	},
	configsLink: {
		alignItems: 'center',
		paddingInlineStart: 10,
		fontWeight: 500,
		position: 'absolute',
		zIndex: 2,
		insetBlockStart: 22,
		insetInlineStart: 200,
	},
	configsLinkIcon: {
		marginInlineStart: 10,
	},
	storeImageContainer: {
		'& .ant-image': {
			display: 'block',
			marginBlockEnd: 8,
		},
		'& .ant-image-mask': {
			alignContent: 'start',
		},
		'& .ant-spin': {
			display: 'flex',
			justifyContent: 'center',
		},
	},
	storeImageDeleteButton: {
		position: 'absolute',
		insetBlockStart: 10,
		insetInlineEnd: 10,
	},
	storeImageEmptyState: {
		maxInlineSize: 'none',
		backgroundColor: gray[2],
	},
	draggerText: {
		margin: 8,
	},
	row: {
		marginBlockEnd: 10,
		alignItems: 'baseline',
	},
}));
