/* eslint-disable max-lines */

import { Tooltip } from 'antd';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import type * as FieldTypes from '~constants/dataPageFields/types';
import { ReactComponent as TooltipIcon } from '~images/question-circle-solid.svg';
import HiddenField from '~shared/components/Fields/HiddenField';
import MultipleStoresSelectField from '~shared/components/Fields/MultipleEntitySelect/MultipleStoresSelectField';
import UnifiedMultiSelect from '~shared/components/Fields/MultipleEntitySelect/UnifiedMultiSelect';
import { Field } from '~shared/components/Forms';
import { FieldArray } from '~shared/components/Forms/FieldArray';
import { useCheckFieldPermit } from '~zustand/userData';

import { CheckboxField } from '../Fields/CheckboxField';
import { NormalCheckboxLayout, TwoColumnCheckboxLayout } from '../Fields/CheckboxField/utils';
import DateField from '../Fields/DateField';
import DateRangeField from '../Fields/DateRangeField';
import IdReplacerField from '../Fields/IdReplacerField';
import JsonField from '../Fields/JsonField';
import LocationTree from '../Fields/LocationTreeField';
import MultipleProductSelectField from '../Fields/MultipleEntitySelect/MultipleProductSelectField';
import ProductSelectField from '../Fields/ProductSelectField';
import RadioField from '../Fields/RadioField';
import SelectField from '../Fields/SelectField';
import { useStyles } from '../Fields/styles';
import SwitchField from '../Fields/SwitchField';
import TagField from '../Fields/TagField';
import TextField from '../Fields/TextField';
import TimeField from '../Fields/TimeField';
import TimetableComponent from '../Fields/Timetable';
import TreeSelectField from '../Fields/TreeSelectField';
import { FieldArrayComponent } from './FieldArrayComponent';

interface Props {
	field: FieldTypes.Field;
	parentName?: string | null;
	pageName?: string;
	disabled?: boolean;
	editMode?: boolean;
	addMode?: boolean;
	classes?: Record<string, string>;
	colspan?: FieldTypes.BaseField['colspan'];
	canEditFieldGroup?: boolean | undefined;
	loading?: boolean;
}

const DataField = ({
	field,
	parentName,
	pageName,
	disabled,
	editMode,
	addMode,
	classes,
	colspan,
	canEditFieldGroup = true,
	loading,
}: Props) => {
	const { classes: fieldsClasses } = useStyles();

	let fieldToCheck: string;
	if (parentName) {
		fieldToCheck = parentName;
	} else if (field.key?.includes('.')) {
		fieldToCheck = field.key?.split('.')?.[0];
	} else {
		fieldToCheck = field.key;
	}

	const isCheckFieldPermit = useCheckFieldPermit(fieldToCheck, pageName ?? '');
	const canEditPermit = canEditFieldGroup && pageName && isCheckFieldPermit; // если указано имя страницы, то мы проверяем ее поля по пермитам

	let canEdit = !disabled && field.canEdit;
	if (canEditPermit !== undefined) {
		canEdit = canEditPermit ? canEditPermit && field.canEdit : false;
	}

	if (field.element) {
		return typeof field.element === 'function'
			? field.element(`${parentName ? `${parentName}.` : ''}${field.key}`, loading ?? false, field.dataTest)
			: field.element;
	}

	const parseFloatValue = (value: any) => (isNaN(parseFloat(value)) ? null : parseFloat(value));
	const parseInteger = (value: any) => (isNaN(parseInt(value, 10)) ? null : parseInt(value, 10));

	switch (field.inputType) {
		case 'number':
			field = field as FieldTypes.TextField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					component={TextField}
					disabled={!canEdit}
					hasLock={canEdit && field.hasLock}
					addMode={addMode}
					parse={field.isFloatAllowed ? parseFloatValue : parseInteger}
					editMode={editMode}
					normalize={(value: any, previousValue: any) => (isNaN(Number(value)) ? previousValue || '' : value)}
					{...field}
					hidden={false}
				/>
			);
		case 'phone':
			field = field as FieldTypes.TextField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					component={TextField}
					addMode={addMode}
					hasLock={canEdit && field.hasLock}
					editMode={editMode}
					format={(value: any) => formatPhoneNumberIntl(value)}
					normalize={(value: any) => value && value.replace(/ /g, '')}
					{...field}
					disabled={!canEdit}
					hidden={false}
				/>
			);
		case 'JSON':
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					component={JsonField}
					addMode={addMode}
					hasLock={canEdit && field.hasLock}
					editMode={editMode}
					{...field}
					hidden={false}
					disabled={!canEdit}
				/>
			);
		case 'text':
		case 'password_box':
		case 'textarea':
			field = field as FieldTypes.TextField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					component={TextField}
					addMode={addMode}
					hasLock={canEdit && field.hasLock}
					editMode={editMode}
					normalize={
						field.pattern
							? (value: any, previousValue: any) => {
									if (value === '') return value;
									if (new RegExp(field.pattern as string).test(value)) {
										return value;
									}
									return previousValue;
								}
							: (value: any) => (value ? value : null)
					}
					{...field}
					hidden={false}
					disabled={!canEdit}
				/>
			);
		case 'select':
			field = field as FieldTypes.SelectField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					addMode={addMode}
					component={SelectField}
					disabled={!canEdit}
					hasLock={canEdit && field.hasLock}
					editMode={editMode}
					rowClassName={classes?.select}
					disabledOptions={field.disabledOptions}
					onlyView={field.onlyView}
					{...field}
					afterLabel={
						field.tooltip ? (
							<Tooltip title={field.tooltip}>
								<TooltipIcon width="12" height="12" className={fieldsClasses.labelTooltip} />
							</Tooltip>
						) : null
					}
					hidden={false}
				/>
			);
		case 'radio':
			field = field as FieldTypes.RadioField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					addMode={addMode}
					component={RadioField}
					disabled={!canEdit}
					editMode={editMode}
					{...field}
					hidden={false}
				/>
			);
		case 'checkbox':
			field = field as FieldTypes.CheckBoxField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					className={classes?.checkbox}
					colspan={colspan}
					addMode={addMode}
					defaultChecked={field.defaultChecked}
					component={CheckboxField}
					disabled={!canEdit}
					editMode={editMode}
					leftSide={field.leftSide}
					layout={field.isNormal ? NormalCheckboxLayout : TwoColumnCheckboxLayout}
					{...field}
					hidden={false}
				>
					{field.label}
				</Field>
			);
		case 'switch':
			field = field as FieldTypes.CheckBoxField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					addMode={addMode}
					defaultChecked={field.defaultChecked}
					component={SwitchField}
					disabled={!canEdit}
					editMode={editMode}
					leftSide={field.leftSide}
					{...field}
					hidden={false}
				/>
			);
		case 'tag':
			field = field as FieldTypes.TagField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					component={TagField}
					disabled={!canEdit}
					editMode={editMode}
					tagView={field.tagView}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'calendar':
			field = field as FieldTypes.CalendarField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					addMode={addMode}
					component={DateField}
					disabled={!canEdit}
					editMode={editMode}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					className={classes?.calendar}
					hidden={false}
				/>
			);
		case 'calendar_range':
			field = field as FieldTypes.CalendarRangeField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					addMode={addMode}
					component={DateRangeField}
					popupClassName={field.pickerParams?.popupClassName}
					disabled={!canEdit}
					editMode={editMode}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'timetable':
			field = field as FieldTypes.TimeTableField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					minuteStep={field.minuteStep}
					timezone={field.timezone}
					component={TimetableComponent}
					editMode={editMode}
					disabled={!canEdit}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'time':
			field = field as FieldTypes.TimeField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					component={TimeField}
					editMode={editMode}
					disabled={!canEdit}
					colspan={colspan}
					rowClassName={classes?.time}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'tree':
			field = field as FieldTypes.TreeField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					component={TreeSelectField}
					editMode={editMode}
					disabled={!canEdit}
					colspan={colspan}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'product':
			field = field as FieldTypes.ProductField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					component={ProductSelectField}
					colspan={colspan}
					editMode={editMode}
					disabled={!canEdit}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'products':
			field = field as FieldTypes.MultipleProductField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					component={MultipleProductSelectField}
					editMode={editMode}
					colspan={colspan}
					disabled={!canEdit}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'stores':
			field = field as FieldTypes.MultipleStoreField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					component={MultipleStoresSelectField}
					editMode={editMode}
					colspan={colspan}
					disabled={!canEdit}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'location':
			field = field as FieldTypes.LocationTreeField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					component={LocationTree}
					editMode={editMode}
					colspan={colspan}
					disabled={!canEdit}
					data-test={`data form ${field.label}${!canEdit ? ' disabled' : ''}`}
					{...field}
					hidden={false}
				/>
			);
		case 'id':
			field = field as FieldTypes.IdReplacerField;
			return (
				<Field
					name={`${parentName ? parentName + '.' : ''}${field.key}`}
					component={IdReplacerField}
					editMode={editMode}
					colspan={colspan}
					dataTest={`data form ${field.label}`}
					loading={loading}
					onlyView={true}
					{...field}
					hidden={editMode}
					canEdit={false}
				/>
			);
		case 'multiple-select':
			field = field as FieldTypes.MultipleSelectField;
			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					component={UnifiedMultiSelect}
					editMode={editMode}
					colspan={colspan}
					disabled={canEdit === false}
					{...field}
					hidden={false}
				/>
			);

		case 'fieldGroup':
			field = field as FieldTypes.ObjectField;
			if (field.fields.find((field) => !field.hidden)) {
				return (
					<div className={classes?.fieldGroup} key={field.key} data-test={`data form section ${field.label}`}>
						<p className={classes?.fieldGroupLabel}>{field.label}</p>
						{field.fields.map(
							(nestedField) =>
								!nestedField.hidden && (
									<DataField
										key={nestedField.key}
										field={nestedField}
										parentName={field.key}
										disabled={disabled}
										pageName={pageName}
										colspan={colspan}
										addMode={addMode}
										editMode={editMode}
										classes={classes}
										canEditFieldGroup={canEditPermit !== false}
									/>
								)
						)}
					</div>
				);
			}
			break;
		case 'fieldArray':
			field = field as FieldTypes.ArrayField;
			return (
				// @ts-expect-error
				<FieldArray
					key={field.key}
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					component={FieldArrayComponent}
					field={field}
					editMode={editMode}
				/>
			);

		case 'hidden':
			field = field as FieldTypes.HiddenField;

			return (
				<Field
					name={`${parentName ? `${parentName}.` : ''}${field.key}`}
					colspan={colspan}
					addMode={addMode}
					component={HiddenField}
					disabled={!canEdit}
					editMode={editMode}
					{...field}
					hidden={false}
				/>
			);
	}

	return null;
};

export default DataField;
