import { Button, Tooltip } from 'antd';

import { getDefaultValues } from '~constants/dataPageFields/fieldHelper';
import type * as FieldTypes from '~constants/dataPageFields/types';
import { ReactComponent as TooltipIcon } from '~images/question-circle-solid.svg';
import DeleteButton from '~shared/components/DeleteButton';
import type { WrappedFieldArrayProps } from '~shared/components/Forms/types';

import DataSection from '../DataSection';
import { useStyles } from './styles';

export function FieldArrayComponent({
	fields,
	field,
	editMode,
}: WrappedFieldArrayProps & { field: FieldTypes.ArrayField; editMode?: boolean }) {
	const { cx, classes } = useStyles();
	const defaultValues = getDefaultValues([field.elementType]);

	field.canEdit ??= true;

	return (
		<div className={classes.fieldArray} data-test={field.dataTest} style={{ inlineSize: field.width }}>
			{field.label && <p className={classes.fieldArrayTitle}>{field.label}</p>}
			<div className={classes.fieldArrayContent} style={{ flexDirection: field.direction }}>
				{fields.map((fieldName, index) => (
					<div
						key={fields.get(index).id}
						className={classes.fieldArrayItem}
						data-test={`${field.dataTestItem} ${index + 1}`}
					>
						<DataSection
							key={fieldName}
							parentName={fieldName}
							editMode={editMode}
							colspan={undefined}
							parentIndex={index + 1}
							classes={{
								section: classes.fieldArraySection,
								fields: cx(classes.fieldArrayFields, {
									[classes.fieldArrayFieldsColumn]: field.direction === 'column',
									[classes.fieldArrayFieldsRow]: field.direction !== 'column',
								}),
								select: classes.fieldArrayItemInput,
								time: classes.fieldArrayItemInput,
								calendar: classes.fieldArrayItemInput,
								checkbox: classes.fieldArrayCheckbox,
							}}
							section={field.elementType}
						/>
						{editMode && field.canEdit && <DeleteButton onClick={() => fields.remove(index)} />}
					</div>
				))}
			</div>
			{editMode && field.canEdit && (
				<div className={classes.fieldArrayItem}>
					<Button className={classes.fieldArrayAddButton} type="dashed" onClick={() => fields.push(defaultValues)}>
						{field.addButtonText ?? '+'}
					</Button>
					{field.tooltip && (
						<div className={classes.fieldArrayAddButtonTooltip}>
							<Tooltip overlayInnerStyle={{ padding: 16 }} title={field.tooltip}>
								<TooltipIcon className={classes.fieldArrayAddButtonTooltipIcon} />
							</Tooltip>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
