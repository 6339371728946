import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const AdjustmentsDataPage = lazy(() => import('../components/Adjustments/AdjustmentsDataPage/index'));
const AdjustmentsPage = lazy(() => import('../components/Adjustments'));
const AdjustmentsImportDataPage = lazy(() => import('../components/Adjustments/Import/ImportDataPage'));

const adjustmentsRoutes = makeRoutes([
	{
		path: '/compensations_adjustments',
		permit: 'adjustments_load',
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsPage />,
	},
	{
		path: '/compensations_adjustments/add',
		permit: 'adjustments_create',
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsDataPage editMode addMode />,
	},
	{
		path: '/compensations_adjustments/edit/:adjustment_id',
		permit: 'adjustments_save',
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsDataPage editMode />,
	},
	{
		path: '/compensations_adjustments/:adjustment_id',
		permit: 'adjustments_load',
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsDataPage />,
	},
	{
		path: '/compensations_imports/:adjustments_import_id',
		permit: 'adjustments_imports_load',
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsImportDataPage />,
	},
]);

export default adjustmentsRoutes;
