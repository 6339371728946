import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { FileInfo } from '~types/common';
import type { Schets } from '~types/schets';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<Schets.ListSchetsType, Schets.Schet>('/api/admin/schets/list'),
	handlers: () => axios.get('/api/admin/schets/handlers'),
	load: makeLoadFn<Schets.Schet, 'schet_task_id'>('/api/admin/schets/load', 'schet_task_id'),
	save: (data: Schets.SchetTaskCreate) => axios.post<{ result: Schets.Schet }>('/api/admin/schets/save', data),
	approve: (data: { schet_task_id: string | string[] }) => axios.post<Schets.Schet>('/api/admin/schets/approve', data),
	start: (data: { schet_task_id: string | string[] }) => axios.post<Schets.Schet>('/api/admin/schets/start', data),
	pause: (data: { schet_task_id: string | string[] }) => axios.post<Schets.Schet>('/api/admin/schets/pause', data),
	force_run: (data: { schet_task_id: string | string[] }) =>
		axios.post<Schets.Schet>('/api/admin/schets/force_run', data),
	delete: (data: { schet_task_id: string }) => axios.post('/api/admin/schets/delete', data),
	export_csv: (data: { storeId: string }, config?: AxiosAdditionalConfig) =>
		axios.post<{ file_info?: FileInfo }>('/api/admin/schets/export_csv', data, config),
	import_csv: (data: { storeId: string; csv: string }, config?: AxiosAdditionalConfig) =>
		axios.post<EmptyObject>('/api/admin/schets/import_data', data, config),
	import_error: () => axios.get<{ csv: string }>('api/admin/file/download'),
} as const;
