import { filterOrder } from '~shared/utils/filterOrder';
import type { Orders } from '~types/orders';

import { sortOrdersData } from './sortOrdersData';

export interface WarehouseOperationsMetrics {
	processing: Record<string, number>;
	notProcessing: Record<string, number>;
	total: number;
}

export const prepareWarehouseOperationsMetrics = (ordersData: Orders.Order[]): WarehouseOperationsMetrics => {
	const sortedOrdersData: Orders.Order[] = sortOrdersData(ordersData, true);
	const notProcessing = { total: 0 };
	const processing = { total: 0 };
	let currentProcessingData = { total: 0 };
	let incrementValue = 1;

	sortedOrdersData.forEach((order) => {
		// Ордера с типом "заказ" должны совпадать с заказами в том же статусе из вкладки "Заказы" в Диспетчерской
		if (order.type === 'order') {
			incrementValue = filterOrder(order) ? 1 : 0;
			currentProcessingData = order.status === 'request' ? notProcessing : processing;
		} else {
			currentProcessingData = order.status === 'processing' ? processing : notProcessing;
		}

		currentProcessingData[order.type] = (currentProcessingData[order.type] ?? 0) + incrementValue;
		currentProcessingData.total += incrementValue;
	});

	return {
		notProcessing,
		processing,
		total: notProcessing.total + processing.total,
	};
};
