import { notification } from 'antd';
import { useCallback, useMemo, useState } from 'react';

import api from '~/api';
import type { Event, Key } from '~shared/utils/ev';
import type { FileInfo } from '~types/common';

import { downloadBlob } from '../utils/export';
import { useAsyncEffect } from './useAsyncEffect';
import useSubscribe from './useSubscribe';

type UseExportFileParams = {
	subscribeKey: Key;
	single?: boolean;
	eventType: string;
	loadFile: () => Promise<FileInfo | undefined | void>;
	fileName: string;
	notifications: {
		wait: string;
		success: string;
		error: string;
	};
	skip?: boolean;
};

export function useExportFile({
	subscribeKey,
	single = false,
	eventType,
	loadFile,
	fileName,
	notifications,
	skip = false,
}: UseExportFileParams) {
	const [loadingStatus, setLoadingStatus] = useState<
		'idle' | 'sendingSignal' | 'awaitEvent' | 'gotFileInfo' | 'loading'
	>('idle');
	const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
	const subscribeCallback = (data: Event) => {
		let exportEvent;
		if (Array.isArray(data.data)) {
			exportEvent = data.data.find((ev) => ev.type === eventType);
		}
		if (exportEvent && loadingStatus === 'awaitEvent') {
			setFileInfo(exportEvent.file_info);
			setLoadingStatus('gotFileInfo');
		}
	};

	useSubscribe(
		{
			key: subscribeKey,
			cb: subscribeCallback,
			unSub: true,
			single,
			name: eventType,
			needInit: false,
		},
		[loadingStatus],
		skip || loadingStatus === 'idle'
	);

	const sendStartSignal = useCallback(async () => {
		setLoadingStatus('sendingSignal');
		try {
			const fileInfo = await loadFile();
			if (fileInfo) {
				setLoadingStatus('gotFileInfo');
				setFileInfo(fileInfo);
				return;
			}

			notification.success({
				message: notifications.wait,
			});
			setLoadingStatus('awaitEvent');
		} catch (err) {
			notification.error({
				message: notifications.error,
				description: err?.message,
			});
			setLoadingStatus('idle');
		}
	}, [loadFile]);

	useAsyncEffect(async () => {
		if (loadingStatus !== 'gotFileInfo' || !fileInfo) {
			return;
		}

		setLoadingStatus('loading');
		try {
			const response = await api.files.get_object_s3(fileInfo);
			downloadBlob(new Blob([response.data]), fileName);

			notification.success({
				message: notifications.success,
			});
		} catch {
			notification.error({
				message: notifications.error,
			});
		} finally {
			setLoadingStatus('idle');
		}
	}, [loadingStatus, fileInfo]);

	return useMemo(
		() => ({
			sendStartSignal,
			loading: loadingStatus !== 'idle',
		}),
		[sendStartSignal, loadingStatus]
	);
}
