import { PlusOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import dayjs from 'dayjs';
import type { Dispatch, SetStateAction } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalComponent from '~shared/components/ModalComponent';

import type { Period } from '../../../AnalyticsPage';
import { defaultGraphsState } from '../../constants';
import { HealthContext } from '../../context';
import type { HealthLevelMetrics, HealthMetric, PeriodState, TimeIntervalDataObject } from '../../healthTypes';
import type { ThresholdDataEntry } from '../../hooks/useGetThresholds';
import { useMetricsStore } from '../../hooks/useMetricsStore';
import { loadHealthAnalytics } from '../../utils/loadHealthData';
import { getComparisonHealthRequests } from '../../utils/period';
import type { EnrichedGraphsPoint } from '../ChartBlock';
import ChartBlock from '../ChartBlock';
import ExtendedTooltip from '../ChartBlock/ExtendedTooltip';
import { getMetricGraphData } from '../ChartBlock/utils';
import FiltersHeader from './FiltersHeader';
import { useStyles } from './styles';

type Props = {
	visible: boolean;
	setModalOpen: Dispatch<SetStateAction<boolean>>;
	thresholds?: ThresholdDataEntry;
};

export type TickValue = 5 | 15 | 60;

const defaultPeriod = {
	begin: dayjs().subtract(1, 'day').format(),
	end: dayjs().format(),
};

const enrichWithComparisonType = (data: HealthLevelMetrics[], comparisonType: PeriodState['comparisonType']) =>
	data.map((entry) => ({ ...entry, comparisonType }));

const getDefaultTickValue = (period: Period): TickValue =>
	dayjs(period.end).diff(dayjs(period.begin), 'day', true) > 1 ? 60 : 5;

const ChartComparisonModal = ({ visible, setModalOpen, thresholds }: Props) => {
	const { classes } = useStyles();
	const [t] = useTranslation();
	const { levelState } = useContext(HealthContext);

	const metrics = useMetricsStore((state) => state.metrics);
	const addMetric = useMetricsStore((state) => state.addMetric);

	const [period, setPeriod] = useState<Period>(defaultPeriod);
	const [tickValue, setTickValue] = useState<TickValue>(5);
	const [isDataByHour, setIsDataByHour] = useState(false);
	const [comparisonType, setComparisonType] = useState<PeriodState['comparisonType']>('day');
	const [graphsData, setGraphsData] = useState<{ now: EnrichedGraphsPoint[]; past: EnrichedGraphsPoint[] }>(
		defaultGraphsState
	);
	const [loading, setLoading] = useState(false);

	const loadGraphs = async () => {
		setLoading(true);

		const defaultTickValue = getDefaultTickValue(period);
		setIsDataByHour(defaultTickValue === 60);
		setTickValue(defaultTickValue);

		const { now, past } = getComparisonHealthRequests(period.begin, period.end, comparisonType, levelState);
		try {
			const data = await loadHealthAnalytics({ now, past });
			const graphsNow = data.now.graphs?.[levelState.type]?.[levelState.id ?? ''];
			const graphsPast = data.past?.graphs?.[levelState.type]?.[levelState.id ?? ''];
			setGraphsData({
				now: graphsNow ? enrichWithComparisonType(graphsNow, comparisonType) : [],
				past: graphsPast ? enrichWithComparisonType(graphsPast, comparisonType) : [],
			});
		} catch {
			notification.error({ message: t('Не удалось загрузить данные') });
		} finally {
			setLoading(false);
		}
	};

	// Открываем модалку с периодом в день
	useEffect(() => {
		if (!visible) return;
		setPeriod({
			begin: defaultPeriod.begin,
			end: defaultPeriod.end,
		});
		void loadGraphs();
	}, [visible]);

	const metricGraphData: Partial<Record<HealthMetric, TimeIntervalDataObject[]>> = useMemo(() => {
		const data = metrics.reduce(
			(acc, { metric }) => ({
				...acc,
				[metric]: getMetricGraphData(metric, graphsData.now, graphsData.past, comparisonType, thresholds),
			}),
			{}
		);
		return data;
	}, [metrics, graphsData, thresholds]);

	const customTooltip = <ExtendedTooltip data={metricGraphData} isDataByHour={isDataByHour} tickValue={tickValue} />;

	return (
		<ModalComponent
			className={classes.graphsModal}
			title={t('Аналитика')}
			width={800}
			modalId="graphs"
			open={visible}
			onCancel={() => setModalOpen(false)}
			destroyOnClose={true}
			footer={null}
			data-test="health analytics modal"
		>
			<div className={classes.graphsModalContent}>
				<FiltersHeader
					period={period}
					tickValue={tickValue}
					setPeriod={setPeriod}
					isDataByHour={isDataByHour}
					setTickValue={setTickValue}
					comparisonType={comparisonType}
					setComparisonType={setComparisonType}
					loadGraphs={loadGraphs}
				/>
				{metrics.map(({ metric, id }) => (
					<ChartBlock
						key={id}
						id={id}
						data={graphsData.now}
						retroData={graphsData.past}
						chartLoading={loading}
						comparisonType={comparisonType}
						dataTest={`health modal chart ${metric}`}
						thresholds={thresholds}
						syncId="health modal"
						customTooltip={customTooltip}
						tickValue={tickValue}
						isDataByHour={isDataByHour}
					/>
				))}
				<Button icon={<PlusOutlined />} onClick={() => addMetric('orders_count')}>
					{t('Добавить график')}
				</Button>
			</div>
		</ModalComponent>
	);
};

export default ChartComparisonModal;
