import type { CancelToken, CancelTokenSource } from 'axios';
import axios from 'axios';
import type { DependencyList } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { AppState } from '~redux/reducers';

export interface UseLoadRedux<D> {
	data: D;
	loading: boolean;
	loaded: boolean;
	error: string | boolean;
}

export default function (
	reduxParams: {
		asyncAction: ((cancelToken?: CancelToken) => any) | ((cancelToken?: CancelToken) => any)[]; // redux action
		withCancelToken?: boolean; // токен сброса запроса
		dataKey?: string; // массив ключей из редакса, данные которых хотим получить
	},
	deps: DependencyList = [],
	skip = false
): UseLoadRedux<any> {
	const [isLoading, toggleLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);
	const data = useSelector<AppState, any>((state) => {
		if (reduxParams.dataKey) {
			let reduxData = {};
			if (Array.isArray(reduxParams.dataKey)) {
				reduxParams.dataKey.forEach((key) => {
					reduxData[key] = state[key];
				});
			} else {
				reduxData = state[reduxParams.dataKey as any];
			}
			return reduxData || {};
		} else {
			return state || {};
		}
	});
	const dispatch = useDispatch();
	const cancelTokens = useRef<CancelTokenSource[]>([]);
	useEffect(() => {
		if (!skip) {
			setLoaded(false);
			toggleLoading(true);
			void (async function () {
				try {
					if (Array.isArray(reduxParams.asyncAction)) {
						await Promise.all(
							reduxParams.asyncAction.map(async (action, index) => {
								if (reduxParams.withCancelToken) {
									cancelTokens.current[index] = axios.CancelToken.source();
								}
								return await dispatch(action(cancelTokens.current[index]?.token));
							})
						);
					} else {
						if (reduxParams.withCancelToken) {
							cancelTokens.current[0] = axios.CancelToken.source();
						}
						await dispatch(reduxParams.asyncAction(cancelTokens.current[0]?.token));
					}
					toggleLoading(false);
					setLoaded(true);
				} catch (e) {
					toggleLoading(false);
					setError(e);
				}
			})();
		}
		return () => {
			if (cancelTokens.current.length) {
				// прерываем запросы при анмаунте
				cancelTokens.current.forEach((cancelTokenSource) =>
					cancelTokenSource.cancel('Request from useReduxData cancelled on unmount')
				);
			}
		};
	}, deps);
	return {
		data,
		loading: isLoading,
		loaded,
		error,
	};
}
