import dayjs from 'dayjs';
import type { AnyAction } from 'redux';

import mapById from '~shared/utils/mapById';
import type { Orders } from '~types/orders';

import { checkIsAcceptanceToApprove, checkIsDocumentToScan } from '../../components/OrdersPage/Utils/filterDocuments';
import {
	ADD_ORDERS,
	CLEAR_ORDERS,
	PARTNER_PICKUP_LIST_UPDATE,
	SUPPLEMENT_ORDERS_HISTORY,
	SUPPLEMENT_ORDERS_HISTORY_UPDATE,
	UPDATE_ORDERS,
	UPDATE_ORDERS_DATA,
} from '../actions/ordersData';

type State = {
	orders: Orders.Order[];
	supplement?: Orders.Order[];
	partnerPickupOrders: Orders.Order[];
	ordersToUpdate: string[];
	loaded: boolean;
	cursor?: string | null;
};

const initialState: State = {
	orders: [],
	ordersToUpdate: [],
	supplement: [],
	partnerPickupOrders: [],
	loaded: false,
	cursor: '',
};

const dataToReplace = (eventOrderData: Record<string, any>) => {
	const result = {};
	Object.keys(eventOrderData).forEach((key) => {
		if (!['order_id', 'type'].includes(key)) {
			result[key] = eventOrderData[key];
		}
	});
	return result;
};

export default function ordersData(state = initialState, { type, payload }: AnyAction): State {
	const ordersToUpdate: string[] = payload?.ordersToUpdate ?? state.ordersToUpdate;

	switch (type) {
		case ADD_ORDERS:
			return {
				...state,
				orders: payload.orders,
				ordersToUpdate: payload.orders.map((order: any) => order.order_id) ?? [],
				loaded: true,
			};
		case UPDATE_ORDERS_DATA: {
			let newOrdersData = [...state.orders];
			payload.ordersData.forEach((eventOrderData: any) => {
				newOrdersData = newOrdersData.map((orderItem) => {
					if (orderItem.order_id === eventOrderData.order_id) {
						return {
							...orderItem,
							...dataToReplace(eventOrderData),
						};
					}
					return orderItem;
				});
			});

			return {
				...state,
				orders: newOrdersData,
				ordersToUpdate: [],
			};
		}
		case UPDATE_ORDERS: {
			const newOrders = [...state.orders];
			payload.orders.forEach((order: any) => {
				const index = newOrders.findIndex((o) => o.order_id === order.order_id);

				if (index !== -1) {
					newOrders[index] = order;
				} else {
					newOrders.unshift(order);
				}
			});
			return {
				...state,
				orders: newOrders,
				ordersToUpdate,
			};
		}

		case SUPPLEMENT_ORDERS_HISTORY: {
			const newStateSupplementOrders = state.supplement?.length
				? state.supplement.reduce((obj: Record<string, Orders.Order>, order: Orders.Order) => {
						obj[order.order_id] = order;
						return obj;
					}, {})
				: {};

			payload.supplement.forEach((order: Orders.Order) => (newStateSupplementOrders[order.order_id] = order));

			return {
				...state,
				supplement: Object.values(newStateSupplementOrders),
				ordersToUpdate,
				cursor: payload.cursor,
			};
		}

		case SUPPLEMENT_ORDERS_HISTORY_UPDATE: {
			const {
				supplement,
				settings: { mayApprove, timezone, agutinIsOn, falconIsOn, chuganinIsOn },
			} = payload;

			const newSupplement = mapById(state.supplement ?? [], 'order_id');

			supplement.forEach((order: Orders.Order) => {
				if (
					checkIsAcceptanceToApprove({ order, mayApprove, timezone, agutinIsOn }) ||
					checkIsDocumentToScan({ order, falconIsOn, chuganinIsOn })
				) {
					newSupplement[order.order_id] = order;
				} else {
					delete newSupplement[order.order_id];
				}
			});

			const result: Orders.Order[] = Object.values(newSupplement).map((value) => value);

			return {
				...state,
				supplement: result.sort((a, b) => (dayjs(b.doc_date).isAfter(a.doc_date) ? 1 : -1)),
				ordersToUpdate,
			};
		}

		case PARTNER_PICKUP_LIST_UPDATE: {
			// Фильтруем заказы партнеров
			const updatedPartnerPickupOrders: Orders.Order[] = payload.partnerPickupOrders;
			const currentPartnerOrders = state.partnerPickupOrders;

			const newPartnerOrders: Orders.Order[] = [
				...currentPartnerOrders.map((order) => ({
					...order,
					...(updatedPartnerPickupOrders.find((e) => e.order_id === order.order_id) ?? {}),
				})),
				...updatedPartnerPickupOrders.filter(
					(order) => !currentPartnerOrders.some((e) => e.order_id === order.order_id)
				),
			];

			return {
				...state,
				partnerPickupOrders: newPartnerOrders.filter((e) => !e.vars?.partner_taken),
			};
		}

		case CLEAR_ORDERS:
			return { ...state, orders: [], loaded: false };
		default:
			return state;
	}
}
