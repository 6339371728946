import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const AssortmentsDataPage = lazy(() => import('../components/AssortmentsPage/AssortmentsDataPage'));
const AssortmentsTable = lazy(() => import('../components/AssortmentsPage'));

const assortmentsRoutes = makeRoutes([
	{
		path: '/assortments',
		permit: 'assortments_seek',
		element: <AssortmentsTable />,
	},
	{
		path: '/assortments/add',
		permit: 'assortments_create',
		element: <AssortmentsDataPage editMode addMode />,
	},
	{
		path: '/assortments/edit/:assortment_id',
		permit: 'assortments_save',
		element: <AssortmentsDataPage editMode />,
	},
	{
		path: '/assortments/:assortment_id',
		permit: 'assortments_load',
		element: <AssortmentsDataPage />,
	},
	{
		path: '/stores/:store_id/assortment',
		permit: 'assortments_load',
		element: <AssortmentsDataPage loadStoreAssortment />,
	},
	{
		path: '/stores/:store_id/assortment/edit',
		permit: 'assortments_save',
		element: <AssortmentsDataPage editMode loadStoreAssortment />,
	},
]);

export default assortmentsRoutes;
