import { notification, Spin } from 'antd';
import cloneDeep from 'clone-deep';
import { useEffect, useMemo, useState } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import api from '~/api';
import { getField } from '~constants/dataPageFields/fieldHelper';
import type { MultipleSelectField } from '~constants/dataPageFields/types';
import { ReactComponent as TrashBin } from '~images/icons/trash-regular.svg';
import { equipmentModuleValues } from '~server-types/doc/api/models/equipment';
import type { EquipmentModule, EquipmentTypeStatuses } from '~server-types/doc/api/models/equipment_type';
import DataForm from '~shared/components/DataForm';
import DataFormControls from '~shared/components/DataForm/DataFormControls';
import SelectField from '~shared/components/Fields/SelectField';
import { Field } from '~shared/components/Forms';
import useEditMode from '~shared/hooks/useEditMode';
import useGetShelves from '~shared/hooks/useGetShelves';
import useLoadData from '~shared/hooks/useLoadData';
import useQueryParams from '~shared/hooks/useQueryParams';
import useRerender from '~shared/hooks/useRerender';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import useSubscribe from '~shared/hooks/useSubscribe';
import useUuid from '~shared/hooks/useUuid';
import { arrayUniq } from '~shared/utils/arrayUniq';
import getAllRecursive from '~shared/utils/getAllRecursive';
import { isObjectEmpty } from '~shared/utils/isObjectEmpty';
import type { EquipmentSave } from '~types/equipment';
import { useCheckPermit, useUser } from '~zustand/userData';

import DeleteModal from '../DeleteEquipmentModal';
import { equipmentsFields } from './equipmentsFields';
import { useStyles } from './styles';

interface Props {
	addMode?: boolean;
	editMode?: boolean;
}

const EquipmentsDataPage = ({ addMode = false, editMode = false }: Props) => {
	const navigate = useNavigate();
	const params = useParams<{ equipment_id: string }>();
	const [t] = useTranslation();
	const user = useUser();
	const storeId = user.store_id;
	const isPermitEquipmentsRm = useCheckPermit('equipments_rm');
	const isPermitEquipmentsLoad = useCheckPermit('equipments_load');
	const isPermitEquipmentsSave = useCheckPermit('equipments_save');
	const [equipmentToRemove, setEquipmentToRemove] = useState<{ title?: string; id?: string }>({
		title: undefined,
		id: undefined,
	});
	const [shelvesLoaded, setShelvesLoaded] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);

	const [equipmentModuleType, setEquipmentModuleType] = useState<string>();
	const isStorage = equipmentModuleType === 'storage';
	const isKitchen = equipmentModuleType === 'kitchen';

	const { classes } = useStyles();

	const reasonBlockedShelves: Record<string, string> = {};
	const equipmentId = params.equipment_id!;
	const [formSending, toggleFormSending] = useState<boolean>(false);
	const uuid = useUuid();
	const { rerenderKey: shelvesRerenderKey, updateRerenderKey: updateShelvesRerenderKey } = useRerender();

	const {
		data: equipmentData,
		loaded: equipmentDataLoaded,
		loading: equipmentDataLoading,
	} = useLoadData(() => api.equipment.items.load({ equipment_id: equipmentId }), [equipmentId, editMode], addMode);
	const equipmentPlacementType = useQueryParams('type')?.active ?? equipmentData?.result.module;

	const {
		data: typesData,
		loading,
		loaded: typesDataLoaded,
	} = useLoadData(
		() =>
			getAllRecursive(api.equipment.types.list, {
				store_id: storeId,
				module: equipmentPlacementType as EquipmentModule,
				status: 'active' as EquipmentTypeStatuses,
			}),
		[storeId],
		!storeId
	);

	const { shelves: shelvesData, loading: shelvesDataLoaded } = useGetShelves({}, [shelvesRerenderKey]);

	const equipmentDataToDisplay = useMemo(() => {
		const equipmentsRacksMap = shelvesData?.reduce((res: Record<string, string[]>, shelf) => {
			if (!!shelf.equipment_id && res[shelf.equipment_id]?.length) {
				res[shelf.equipment_id].push(shelf.rack);

				return res;
			}

			if (!!shelf.equipment_id) {
				res[shelf.equipment_id] = [shelf.rack];
			}
			return res;
		}, {});

		const result = cloneDeep(equipmentData?.result);

		if (!!result) {
			result.racks = arrayUniq(equipmentsRacksMap?.[result.equipment_id] ?? []);
		}

		return result;
	}, [shelvesDataLoaded, typesDataLoaded, equipmentDataLoaded]);

	shelvesData?.forEach((shelf) => {
		if (shelf.equipment_id && shelf.equipment_id !== params.equipment_id) {
			if (shelf.shelf_id in reasonBlockedShelves) {
				reasonBlockedShelves[shelf.shelf_id] =
					reasonBlockedShelves[shelf.shelf_id] + ' · ' + t('Занят другим оборудованием');
			} else {
				reasonBlockedShelves[shelf.shelf_id] = t('Занят другим оборудованием');
			}
		}
	});
	const disabledRacksList =
		(shelvesData ?? [])
			.filter((shelf) => {
				return shelf.shelf_id in reasonBlockedShelves;
			})
			.map(
				(shelf) =>
					`${shelf.rack}${shelf.shelf_id in reasonBlockedShelves ? ' · ' + reasonBlockedShelves[shelf.shelf_id] : ''}`
			) ?? [];

	const checkedRacks = new Set();
	const racksList =
		(shelvesData ?? [])
			.filter((shelf) => {
				if (checkedRacks.has(shelf.rack)) {
					return false;
				}
				checkedRacks.add(shelf.rack);
				return true;
			})
			.sort((a, b) => a.order - b.order)
			.map(
				(shelf) =>
					`${shelf.rack}${shelf.shelf_id in reasonBlockedShelves ? ' · ' + reasonBlockedShelves[shelf.shelf_id] : ''}`
			) ?? [];

	const equipmentTypes: Record<string, string> | undefined = typesData?.results.reduce((result, element) => {
		result[element.equipment_type_id] = element.title;
		return result;
	}, {});

	const equipmentTypesSorted = Object.keys(equipmentTypes ?? {}).sort((a: string, b: string) => {
		// Что тут происходит:
		// Типы оборудования имеют формат "Морозильная камера (1)", "Морозильная камера (2)", "Холодильник (1)", "Холодильник (2)" и тд.
		// Мы разбиваем тайтлы на текстовую и числовую часть и сортируем по алфавиту и по числам внутри скобок.
		// Если просто сделать localCompare, то будет "Морозильная камера (1)", "Морозильная камера (10)", "Морозильная камера (11)" или что-то такое.

		const equipmentATitle = (equipmentTypes ? equipmentTypes : {})[a] ?? '';
		const equipmentBTitle = (equipmentTypes ? equipmentTypes : {})[b] ?? '';

		const aSliceNumber = equipmentATitle?.indexOf('(');
		const bSliceNumber = equipmentBTitle.indexOf('(');

		const aNumbers = Number(equipmentATitle?.slice(aSliceNumber + 1, -1));
		const bNumbers = Number(equipmentBTitle.slice(bSliceNumber + 1, -1));

		const aText = equipmentATitle?.slice(0, aSliceNumber);
		const bText = equipmentBTitle.slice(0, bSliceNumber);

		const textCompare = aText.localeCompare(bText);

		if (textCompare === 0) {
			return aNumbers - bNumbers;
		}

		return textCompare;
	});

	const form = useForm<EquipmentSave>({
		values: equipmentDataToDisplay,
		resolver: async (values) => {
			const errors: FieldErrors<typeof values> = {};

			if (!values.equipment_type_id) {
				errors.equipment_type_id = {
					type: 'required',
					message: t('Обязательное поле'),
				};
			}

			return {
				values: isObjectEmpty(errors) ? values : {},
				errors,
			};
		},
	});

	const { handleSubmit, reset } = form;
	const sendForm = async (values: EquipmentSave) => {
		toggleFormSending(true);
		setShelvesLoaded(false);
		try {
			const dataObject: EquipmentSave = { ...values };
			if (addMode) {
				dataObject.external_id = uuid;
			} else {
				dataObject.equipment_id = equipmentId;
				dataObject.external_id = undefined;
			}
			dataObject.store_id = user.store_id ?? '';

			await api.equipment.items.save(dataObject);

			notification.success({
				message: t('Оборудование сохранено'),
			});

			navigate(
				equipmentId ? `/stores/${user.store_id}/shelves/equipments/${equipmentId}` : `/stores/${user.store_id}/shelves`
			);
		} catch (e) {
			notification.error({
				message: t('Не удалось сохранить оборудование'),
				description: e.errors?.message,
			});
		} finally {
			toggleFormSending(false);
		}
	};

	const equipmentTypeField = getField(equipmentsFields, t('Основное'), 'equipment_type_id');
	if (equipmentTypeField) {
		equipmentTypeField.element = (
			<Field
				label={t('Оборудование')}
				key="equipment_type_id"
				name="equipment_type_id"
				editMode={editMode}
				disabled={loading}
				simpleSearch
				options={equipmentTypesSorted}
				dictionary={equipmentTypes ?? {}}
				component={SelectField}
			/>
		);
	}

	useSubscribe({
		key: ['shelf', 'store', storeId],
		cb: () => {
			setShelvesLoaded(true);
			updateShelvesRerenderKey();
		},
		unSub: true,
		single: false,
	});

	const equipmentType = equipmentData?.result.module || equipmentPlacementType;

	useEffect(() => {
		if (equipmentModuleValues.includes(equipmentType)) {
			setEquipmentModuleType(equipmentType);
		}
	}, [equipmentType]);

	const racksField = getField(equipmentsFields, t('Стеллажи в этом оборудовании'), 'racks') as MultipleSelectField;

	if (racksField && isKitchen) {
		racksField.hidden = true;
	}

	if (racksField && isStorage) {
		racksField.options = racksList;
		racksField.disabledOptions = disabledRacksList;
		racksField.loading = !shelvesLoaded;
		racksField.hidden = false;
	}

	const temperatureField = getField(equipmentsFields, t('Основное'), 'temperature');
	if (temperatureField && isKitchen) {
		temperatureField.hidden = true;
	}

	if (temperatureField && isStorage) {
		temperatureField.hidden = false;
	}

	const voltageField = getField(equipmentsFields, t('Основное'), 'voltage');
	if (voltageField && isKitchen) {
		voltageField.hidden = true;
	}

	if (temperatureField && isStorage) {
		temperatureField.hidden = false;
	}

	useEditMode(editMode || addMode);

	const titles = {
		edit: isStorage ? t('Редактирование оборудования склада') : t('Редактирование оборудования кухни'),
		add: isStorage ? t('Добавление оборудования склада') : t('Добавление оборудования кухни'),
		view: isStorage ? t('Оборудование склада') : t('Оборудование кухни'),
	};

	useSetTitle(undefined, titles, { addMode, editMode });
	return (
		<>
			{!equipmentType ? (
				<Spin />
			) : (
				<>
					<DeleteModal
						equipmentToRemove={equipmentToRemove}
						setIsRemoving={setIsRemoving}
						isRemoving={isRemoving}
						setEquipmentToRemove={setEquipmentToRemove}
						storeId={storeId}
						onAfterRemove={() => navigate(`/stores/${storeId}/shelves`)}
					/>
					<DataFormControls
						editMode={editMode}
						addMode={addMode}
						inProgress={formSending}
						disabled={formSending}
						showHeader={true}
						submit={handleSubmit(sendForm)}
						resetForm={reset}
						additionalButtons={[
							{
								type: 'button',
								className: classes.deleteEquipmentButton,
								additionalProps: {
									danger: true,
								},
								action: () => {
									setEquipmentToRemove({
										title: equipmentData?.result.type,
										id: equipmentData?.result.equipment_id,
									});
								},
								icon: <TrashBin />,
								condition: isPermitEquipmentsRm && editMode && !addMode,
							},
						]}
						saveBtnTitle={addMode ? t('Добавить') : t('Сохранить')}
						testSelectors={{
							back: 'equipments data page back button',
							exitEdit: 'equipments data page cancelEdit button',
							enterEdit: 'equipments data page edit button',
							save: 'equipments data page save button',
						}}
						title={titles}
						quitEditModeLink={
							isPermitEquipmentsLoad
								? addMode
									? `/stores/${storeId}/shelves`
									: `/stores/${storeId}/shelves/equipments/${equipmentId}`
								: undefined
						}
						enterEditModeLink={
							isPermitEquipmentsSave ? `/stores/${storeId}/shelves/equipments/edit/${equipmentId}` : undefined
						}
						editModeDisabledReason={
							equipmentData?.result.status !== 'active' ? t('Нельзя редактировать отключенное оборудование') : undefined
						}
					>
						<DataForm
							onSubmit={sendForm}
							editMode={editMode}
							addMode={addMode}
							loading={equipmentDataLoading}
							formFields={equipmentsFields}
							dataTest={`courier services data page form ${editMode ? 'edit' : 'view'}`}
							hookForm={form}
						/>
					</DataFormControls>
				</>
			)}
		</>
	);
};

export default EquipmentsDataPage;
