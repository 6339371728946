import { notification } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { ReactComponent as PencilRegular } from '~images/icons/pencil-regular.svg';
import ModalComponent from '~shared/components/ModalComponent';
import Icon from '~shared/utils/icons';
import type { User } from '~types/user';
import { useUser } from '~zustand/userData';

import { useStyles } from '../../styles';
import ChangeOrderUsersForm from './ChangeOrderUsersForm';

interface Props {
	orderId: string;
	users: string[];
	onlyChange?: boolean;
}

const ChangeOrderUsers = ({ orderId, users, onlyChange = false }: Props) => {
	const user = useUser();

	useCache({
		userExecutors: [user.user_id],
	});

	const form = useForm<User.AdvancedUser>({});

	const [t] = useTranslation();
	const { classes } = useStyles();
	const [showModal, toggleModal] = useState<boolean>(false);

	const sendForm = async (values: User.AdvancedUser) => {
		try {
			await api.orders.setUsers({
				order_id: orderId,
				users: values.add_user && !onlyChange ? [...users, values.user_id] : [values.user_id],
			});

			notification.success({
				message: t('Исполнители изменены'),
			});
			toggleModal(false);
		} catch {
			notification.error({
				message: t('Не удалось изменить исполнителей'),
			});
		}
	};

	return (
		<>
			<div onClick={() => toggleModal(true)} data-test="change_user_button" className={classes.blockEditBtn}>
				<Icon component={PencilRegular} className={classes.blockEditBtn} />
			</div>
			<ModalComponent
				className={`data-test-change-order-user-modal-${showModal ? 'visible' : 'hidden'}`}
				title={t('Изменить исполнителя документа')}
				open={showModal}
				onOk={form.handleSubmit(sendForm)}
				okButtonProps={{
					disabled: user?.user_id === undefined,
					className: 'data-test-submit-change-order-user',
				}}
				onCancel={() => toggleModal(false)}
			>
				<ChangeOrderUsersForm hookForm={form} onSubmit={sendForm} onlyChange={onlyChange} />
			</ModalComponent>
		</>
	);
};

export default ChangeOrderUsers;
