import {
	checkProjectStatusesNames,
	checkProjectTypesNames,
	checkProjectTypesValues,
} from '~server-types/doc/api/models/check_project';
import { repeatTypeDictionary } from '~shared/components/Fields/RepeatScheduleField/utils';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { CheckProjects } from '~types/checkProjects';

import type { SectionConfig } from './types';

export const productTabLabels = makeDynamicTranslations({
	schedule: tDynamic('Товары и категории'),
	threshold: tDynamic('Товары'),
	regrading: tDynamic('Товары'),
});

export const checkProjectsFields = makeDynamicTranslations<SectionConfig[]>([
	{
		label: tDynamic('Проект ЛИ'),
		fields: [
			{
				key: 'check_project_id',
				label: tDynamic('Идентификатор проекта ЛИ'),
				type: 'string',
				inputType: 'text',
				hidden: true,
				canEdit: false,
			},
			{
				key: 'external_id',
				label: tDynamic('Внешний идентификатор'),
				type: 'string',
				inputType: 'text',
				hidden: true,
				canEdit: false,
			},
			{
				key: 'title',
				label: tDynamic('Название проекта'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
			},
			{
				key: 'check_project_type',
				label: tDynamic('Тип проекта'),
				type: 'string',
				inputType: 'select',
				options: checkProjectTypesValues,
				defaultValue: 'schedule',
				dictionary: checkProjectTypesNames,
				canEdit: true,
			},
			{
				key: 'status',
				label: tDynamic('Статус'),
				type: 'string',
				inputType: 'text',
				hidden: true,
				canEdit: false,
				renderLog: (status: CheckProjects.CheckProject['status']): string => checkProjectStatusesNames[status],
			},
			{
				key: 'company_id',
				label: tDynamic('Компания'),
				type: 'string',
				inputType: 'id',
				path: 'companies',
				entity: 'companies',
				titleField: 'title',
			},
			{
				key: 'threshold',
				label: tDynamic('Порог остатка товара, ед.'),
				type: 'number',
				inputType: 'number',
				canEdit: true,
				tooltip: tDynamic('Штучные товары измеряются в штуках, весовые товары измеряются в граммах и миллилитрах'),
				conditions: {
					visible: [
						{
							check_project_type: 'threshold',
						},
					],
				},
			},
			{
				key: 'repeat_type',
				label: tDynamic('Повторять'),
				type: 'string',
				inputType: 'select',
				options: Object.keys(repeatTypeDictionary),
				defaultValue: 'no',
				dictionary: repeatTypeDictionary,
				canEdit: true,
				conditions: {
					visible: [
						{
							check_project_type: 'schedule',
						},
					],
				},
			},
			{
				key: 'repeat_weekdays',
				label: tDynamic('Повтор'),
				type: 'other',
				inputType: 'other',
				hidden: false,
				canEdit: true,
				defaultValue: [],
				conditions: {
					visible: [
						{
							repeat_type: 'weekday',
						},
					],
				},
			},
			{
				key: 'repeat_interval',
				label: tDynamic('Повтор'),
				type: 'other',
				inputType: 'other',
				hidden: false,
				canEdit: true,
				defaultValue: 1,
				conditions: {
					visible: [
						{
							repeat_type: 'day_interval',
						},
					],
				},
			},
			{
				key: 'timetable_time',
				label: tDynamic('Время исполнения'),
				type: 'string',
				inputType: 'time',
				pickerParams: {
					format: 'HH',
				},
				wordHour: true,
				canEdit: true,
				isMinutesReadOnly: true,
				conditions: {
					visible: [
						{
							check_project_type: 'schedule',
							repeat_type: (value: string) => value !== 'no',
						},
					],
				},
			},
			{
				key: 'once_date_time',
				label: tDynamic('Время исполнения'),
				type: 'string',
				inputType: 'calendar',
				canEdit: true,
				hidden: true,
				params: {
					onlyDate: false,
					withSeconds: false,
					alwaysShowYear: true,
				},
				pickerParams: {
					// Так как бэк не создает локалку минута в минуту - там отрабатывает скрипт и локалка может создаваться в любую минуту в рамках часа, поэтому нет смысла задавать минуты и секунды. Оставляем только выбор часа.
					showTime: { format: 'HH' },
				},
				isMinutesReadOnly: true,
				conditions: {
					visible: [
						{
							check_project_type: 'schedule',
							repeat_type: 'no',
						},
						{
							check_project_type: 'regrading',
							repeat_type: 'no',
						},
					],
				},
			},
			{
				key: 'has_finish',
				label: tDynamic('Окончание'),
				type: 'string',
				inputType: 'select',
				options: ['no', 'yes'],
				defaultValue: 'no',
				dictionary: { no: tDynamic('Нет'), yes: tDynamic('Да') },
				canEdit: true,
				conditions: {
					visible: [
						{
							repeat_type: (value: string) => value && value !== 'no',
						},
						{
							check_project_type: 'threshold',
						},
					],
				},
			},
			{
				key: 'begin_date',
				label: tDynamic('Дата начала'),
				type: 'string',
				inputType: 'calendar',
				canEdit: true,
				isMinutesReadOnly: true,
				pickerParams: {
					format: 'DD.MM.YYYY',
				},
				conditions: {
					visible: [
						{
							check_project_type: 'schedule',
							repeat_type: (value: string) => value !== 'no',
						},
						{
							check_project_type: 'threshold',
						},
					],
				},
			},
			{
				key: 'end_date',
				label: tDynamic('Дата окончания'),
				type: 'string',
				inputType: 'calendar',
				canEdit: true,
				hidden: true,
				isMinutesReadOnly: true,
				pickerParams: {
					format: 'DD.MM.YYYY',
				},
				conditions: {
					visible: [
						{
							has_finish: 'yes',
						},
					],
				},
			},
			{
				key: 'next_time',
				label: tDynamic('Следующая инвентаризация'),
				type: 'string',
				inputType: 'calendar',
				canEdit: false,
				onlyView: true,
				params: {
					onlyDate: false,
					withSeconds: false,
					alwaysShowYear: true,
				},
				conditions: {
					visible: [
						{
							repeat_type: (value: string) => value && value !== 'no',
						},
					],
				},
			},
			{
				key: 'stores',
				label: tDynamic('Локация'),
				type: 'other',
				inputType: 'location',
				defaultValue: {
					store_id: [],
					cluster_id: [],
					company_id: [],
				},
			},
			{
				key: 'products.regrading_map',
				label: tDynamic('Выбрать товар через'),
				type: 'other',
				inputType: 'other',
				hidden: false,
				canEdit: true,
				defaultValue: [],
			},
			{
				key: 'products.product_id',
				label: tDynamic('Продукты'),
				type: 'select-multi',
				inputType: 'products',
				defaultValue: [],
				hidden: false,
				canEdit: true,
			},
			{
				key: 'products.product_group_id',
				label: tDynamic('Группы продуктов'),
				type: 'select-multi',
				inputType: 'tree',
				canEdit: true,
				parentPropName: 'parent_group_id',
				idPropName: 'group_id',
				titlePropName: 'name',
				path: 'product_groups',
				showAllParents: true,
				params: {
					allowClear: true,
					multiple: true,
					showSearch: true,
				},
				activeStatuses: ['active'],
				defaultValue: [],
				options: [],
				data: [],
			},
			{
				key: 'vars.created_by',
				label: tDynamic('Автор проекта'),
				type: 'string',
				inputType: 'id',
				path: 'users',
				entity: 'userExecutors',
				titleField: ['last_name', 'first_name'],
			},
			{
				key: 'vars.last_edited_by',
				label: tDynamic('Изменено'),
				type: 'string',
				inputType: 'id',
				path: 'users',
				entity: 'userExecutors',
				titleField: ['last_name', 'first_name'],
			},
			{
				key: 'vars.approved_by',
				label: tDynamic('Одобрено'),
				type: 'string',
				inputType: 'id',
				path: 'users',
				entity: 'userExecutors',
				titleField: ['last_name', 'first_name'],
			},
			{
				key: 'vars.declined_by',
				label: tDynamic('Отклонено'),
				type: 'string',
				inputType: 'id',
				path: 'users',
				entity: 'userExecutors',
				titleField: ['last_name', 'first_name'],
			},
		],
	},
]);
