import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const StoresEquipment = lazy(() => import('../components/Equipment/StoresEquipment'));

const storesEquipment = makeRoutes([
	{
		path: '/stores_equipment',
		permit: 'equipments_seek',
		element: <StoresEquipment />,
		exp: 'exp_lavka4',
	},
]);

export default storesEquipment;
