import dayjs from 'dayjs';
import type { TFunction } from 'i18next';

import i18nInstance from '~/i18n';

class I18nDynamicKey {
	constructor(public args: Parameters<TFunction>) {}

	toString() {
		return i18nInstance.t(...this.args);
	}
}

// @ts-expect-error
export const tDynamic: typeof i18nInstance.t = (...args) => {
	// @ts-expect-error
	return new I18nDynamicKey(args);
};

export function makeDynamicTranslations<T extends object>(obj: T, recursionIndex = 0): T {
	// На случай, если вдруг попали в циклический объект, то обрываем рекурсию
	if (recursionIndex > 10) {
		return obj;
	}
	// Преобразуем только объекты или массивы
	if (typeof obj !== 'object' || obj == null) {
		return obj;
	}
	// Проверяем, что это не реакт элемент
	if ('$$typeof' in obj) {
		return obj;
	}
	// Не ходим вглубь дат
	if (dayjs.isDayjs(obj)) {
		return obj;
	}

	const res = (Array.isArray(obj) ? [] : {}) as T;

	const descriptors = Object.getOwnPropertyDescriptors(obj);

	for (const [key, descriptor] of Object.entries(descriptors)) {
		let newDescriptor: PropertyDescriptor = {
			enumerable: descriptor.enumerable,
			configurable: descriptor.configurable,
		};

		if (
			typeof descriptor.value === 'object' &&
			descriptor.value != null &&
			descriptor.value instanceof I18nDynamicKey
		) {
			const args = makeDynamicTranslations((descriptor.value as I18nDynamicKey).args);
			newDescriptor.get = () => {
				return i18nInstance.t(...args);
			};
		} else if (!descriptor.get) {
			newDescriptor.value = makeDynamicTranslations(descriptor.value, recursionIndex + 1);
			newDescriptor.writable = descriptor.writable;
		} else {
			newDescriptor = { ...descriptor };
		}

		Object.defineProperty(res, key, newDescriptor);
	}

	return res;
}

export function mergeFirstLevelTranslations(...objs: Record<string, string>[]): Record<string, string> {
	const res = {};

	for (const obj of objs) {
		const descriptors = Object.getOwnPropertyDescriptors(obj);

		for (const [key, descriptor] of Object.entries(descriptors)) {
			Object.defineProperty(res, key, descriptor);
		}
	}

	return res;
}
