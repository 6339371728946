import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import reducer from './reducers/index';

const isProduction = process.env.NODE_ENV === 'production';

const enhancer = isProduction ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk));

const store = createStore(reducer, enhancer);

export default store;
