import { TabBar } from '@lavka/ui-kit';
import type { TabProps } from '@lavka/ui-kit/lib/esm/components/TabBar/types';
import { Spin } from 'antd';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { useStyles } from './styles';
import { TabContent } from './TabContent';
import { filteredData, prepareTabBarItems } from './utils';

export type TabsContentProps = PropsWithChildren<
	TabProps & { condition?: boolean; forceRender?: boolean; dataTest?: string }
>[];

export type TabBarProps = {
	onSelectTab: (value: string) => void;
	activeTab: string | null;
	className?: string;
	tabsContent: TabsContentProps;
	destroyInactiveTabPane?: boolean;
};

const Tabs = ({ onSelectTab, activeTab, className, tabsContent, destroyInactiveTabPane }: TabBarProps) => {
	const { classes } = useStyles();
	const [mountedTabs, setMountedTabs] = useState<Record<string, boolean>>({});
	const [loading, setLoading] = useState<Record<string, boolean>>({});

	useEffect(() => {
		if (activeTab && !mountedTabs[activeTab]) {
			setLoading((prev) => ({
				...prev,
				[activeTab]: true,
			}));
		}
	}, [activeTab]);

	useEffect(() => {
		if (activeTab && loading[activeTab]) {
			setMountedTabs((prev) => ({
				...prev,
				[activeTab]: true,
			}));

			setLoading((prev) => ({
				...prev,
				[activeTab]: false,
			}));
		}
	}, [activeTab, loading]);

	const filteredTabs = filteredData(tabsContent);

	return (
		<div className={classes.container}>
			<TabBar
				onSelectTab={onSelectTab}
				activeTabId={activeTab}
				tabs={prepareTabBarItems(filteredTabs)}
				className={className}
			/>
			{filteredTabs.map((tab) => {
				const isActive = activeTab === tab.id;
				const shouldRender = destroyInactiveTabPane ? isActive : mountedTabs[tab.id];

				return loading[tab.id] ? (
					<Spin />
				) : (
					<div style={{ display: activeTab === tab.id ? 'block' : 'none' }} key={tab.id}>
						{shouldRender || tab.forceRender ? <TabContent visible={isActive}>{tab.children}</TabContent> : null}
					</div>
				);
			})}
		</div>
	);
};

export default Tabs;
