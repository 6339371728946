import { useEffect, useMemo, useState } from 'react';

import { useCache } from '~cache/useCache';
import OrderModal from '~shared/components/OrdersHistory/OrderModal';
import useGetCluster from '~shared/hooks/useGetCluster';
import useOrdersQueque from '~shared/hooks/useOrdersQueque';
import { loadAverageMetrics } from '~shared/utils/loadAverages';
import { sortOrdersData } from '~shared/utils/sortOrdersData';
import { calculateOrdersMetrics } from '~shared/utils/tableauOrdersMetrics';
import type { Orders } from '~types/orders';

import addTagsToOrder from '../../../../../MonitorTableau/utils/addTagsToOrder';
import { filterOrders } from '../../../../../OrdersPage';
import { useStyles as healthTableauStyles } from '../../styles';
import { OrdersCards } from './OrdersCards';
import OrdersMetrics from './OrdersMetrics';

interface Props {
	storeId?: string | null;
	orders: Orders.Order[];
}

const OrdersColumn = ({ storeId, orders }: Props) => {
	const { classes: healthTableauClasses } = healthTableauStyles();
	const cache = useCache({
		shelves: {
			ids: orders.flatMap((order) => order.shelves),
			_fields: ['shelf_id', 'type', 'tags'],
		},
	});
	const { cluster } = useGetCluster();
	const sortedOrders = sortOrdersData(filterOrders(orders), false);
	orders = sortedOrders.map((order) => addTagsToOrder(order, cluster, cache.shelves));
	const columnsData = useOrdersQueque(orders);
	const [orderIdToShow, setOrderIdToShow] = useState<string | undefined>();
	const [averagesData, setAveragesData] = useState<any>({});

	useEffect(() => {
		if (storeId) {
			void loadAverageMetrics(storeId, setAveragesData);
		}
	}, []);

	const { orders: calculatedOrders, ...calculatedMetrics } = useMemo(
		() => calculateOrdersMetrics(columnsData, averagesData.orders_count),
		[columnsData]
	);

	return (
		<div data-test="ordersColumn" className={healthTableauClasses.column}>
			<OrderModal setOrderIdToShow={setOrderIdToShow} orderIdToShow={orderIdToShow} />
			<OrdersMetrics calculatedMetrics={calculatedMetrics} storeId={storeId} />
			<OrdersCards setOrderIdToShow={setOrderIdToShow} columnsData={calculatedOrders} />
		</div>
	);
};

export default OrdersColumn;
