import { toKML } from '@placemarkio/tokml';
import { notification } from 'antd';

import i18n from '~/i18n';
import { eZoneDeliveryTypesValues } from '~shared/components/Fields/StoreGeoData/StoreMap/utils';
import { geoJsonGenerator, zoneStatusColorDictionary } from '~shared/utils/mapFunctions';
import type { Stores } from '~types/stores';
import type { Zones } from '~types/zones';

import { deliveryTypeColorDictionary, eZonesColorDictionary } from '../utils';
import type { SingleZoneFormValuesProps } from '../Zones/types';

declare let ymaps: any;
export const zonesSorterByDeliveryType = (zoneA: any, zoneB: any) => {
	return eZoneDeliveryTypesValues.indexOf(zoneA.delivery_type) - eZoneDeliveryTypesValues.indexOf(zoneB.delivery_type);
};

export const generatePolygonColor = (
	deliveryType: Zones.Zone['delivery_type'],
	storeStatus: Stores.Store['status'],
	polygonIndex = 0
): string => {
	let color = '';

	if (deliveryType) {
		color =
			deliveryType === 'foot' ? zoneStatusColorDictionary[storeStatus] : deliveryTypeColorDictionary[deliveryType];
	} else {
		color = polygonIndex === 0 ? zoneStatusColorDictionary[storeStatus] : eZonesColorDictionary[polygonIndex];
	}

	return color;
};

export const getPlacemarkCoords = (placemark: Stores.Location) => {
	let placemarkCoords: { lat: number; lng: number } | undefined = undefined;

	if (placemark) {
		const coords = placemark.geometry.coordinates;
		placemarkCoords = { lat: coords[1], lng: coords[0] };
	}
	return placemarkCoords;
};

export const googleMapsGeocoding = async (text: string): Promise<[number, number] | undefined> => {
	if (!google?.maps) {
		return;
	}

	const geocoder = new google.maps.Geocoder();
	let coords: [number, number] | undefined = undefined;

	try {
		await geocoder.geocode({ address: text }, (results) => {
			if (results) {
				coords = [results[0].geometry.location.lng(), results[0].geometry.location.lat()];
			}
		});
	} catch {
		notification.error({
			message: i18n.t('Ошибка поиска по адресу'),
		});
	}

	return coords;
};

// геокодинг
export const yandexMapsGeocode = async (text: string): Promise<number[] | undefined> => {
	try {
		const response = await ymaps.geocode(text);
		const obj = response.geoObjects.get(0);
		const chords = obj.geometry.getCoordinates();
		if (chords) {
			return chords;
		}
		return undefined;
	} catch {
		notification.error({
			message: i18n.t('Ошибка поиска по адресу'),
		});
		return undefined;
	}
};

export const exportGeoObjects = (
	format: 'geojson' | 'kml',
	zoneValue: SingleZoneFormValuesProps['map']['polygons'],
	storeData?: Partial<Stores.Store>
) => {
	const geoJson = geoJsonGenerator(zoneValue, {
		external_id: storeData?.external_id,
		name: storeData?.title,
		address: storeData?.address,
	});
	let blob;
	switch (format) {
		case 'geojson':
			blob = new Blob([JSON.stringify(geoJson)], {
				type: 'application/json',
			});
			break;
		case 'kml': {
			const kml = toKML(geoJson);
			blob = new Blob([kml], {
				type: 'kml',
			});
			break;
		}
	}
	void (async function () {
		const fileName = `${storeData?.title}`;
		const href = await URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = href;
		link.download = `${fileName}.${format}`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	})();
};
