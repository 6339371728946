import { notification } from 'antd';
import type { OptionProps } from 'antd/lib/select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCache } from '~cache/useCache';
import type { entityMap } from '~shared/components/SimpleFields/PersonalDataSelect/utils';
import {
	filterGroups,
	getActiveUsers,
	getNames,
	getSurnames,
	transformOptions,
} from '~shared/components/SimpleFields/PersonalDataSelect/utils';
import capitalize from '~shared/utils/capitalize';
import { checkIsId } from '~shared/utils/ids';
import type { Couriers } from '~types/couriers';
import { useUser, useUserConstants } from '~zustand/userData';

import type { User } from './../../types/user';
import { useAsyncEffect } from './useAsyncEffect';

export type SelectOption = {
	value: string;
	label: string;
	type: 'surname' | 'name' | 'fullname';
	surname?: string;
};

interface Props<T> {
	searchValue: string | undefined;
	value: string | undefined;
	setValue: (value: string | undefined) => void;
	updateValue: (value: null | string) => void;
	entityName: keyof typeof entityMap;
	additionalOptions?: {
		suggestEntities?: boolean;
		onlyActive?: boolean;
	};
	getUserValueFunc?: (id: string) => string;
	searchSurnames?: ({ searchText }: { searchText: string }) => Promise<T[] | undefined>;
	searchNames?: ({
		searchText,
		entityName,
		companyId,
		storeId,
		status,
	}: {
		searchText: string;
		entityName: keyof typeof entityMap;
		companyId: string;
		storeId?: string;
		status?: 'active';
		filter?: (item: Couriers.Courier | User.User) => boolean;
	}) => Promise<T[] | undefined>;
	filter?: (item: Couriers.Courier | User.User) => boolean;
}
export default function <T extends SelectOption | OptionProps>({
	searchValue,
	value,
	setValue,
	updateValue,
	entityName = 'couriersBrief',
	additionalOptions,
	getUserValueFunc,
	searchSurnames = getSurnames,
	searchNames = getNames,
	filter,
}: Props<T>) {
	const params = useParams();
	const storeId = params.store_id;
	const [t] = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const [optionNames, setOptionNames] = useState<Record<string, T[]>>({});
	const [optionSurnames, setOptionSurnames] = useState<T[]>([]);
	const [isLoading, setLoading] = useState<boolean>();
	const [dictionary, setDictionary] = useState<Record<string, string>>({});

	const user = useUser();
	const constants = useUserConstants();
	const cacheRequest = {};
	cacheRequest[entityName] = [];
	const cache = useCache(cacheRequest);

	const setInitialValue = async function (entity_id: string) {
		try {
			if (!entity_id || !getUserValueFunc) return;
			setLoading(true);

			const dict = {};
			const name = await getUserValueFunc(entity_id);

			if (name) {
				dict[entity_id] = name;
				setDictionary(dict);
			}

			const { last_name, display_name } = cache[entityName][entity_id] ?? {};
			if (display_name) {
				setOptionNames({
					[last_name]: [{ value: entity_id, label: display_name, type: 'name', surname: last_name } as T],
				});
			}
			if (last_name) {
				setOptionSurnames([{ value: last_name, label: last_name, type: 'surname' } as T]);
			}
		} catch {
			notification.error({ message: t('Не удалось загрузить данные') });
		} finally {
			setLoading(false);
		}
	};

	useAsyncEffect(async () => {
		setOptionNames({});
		setOptionSurnames([]);
		if (!searchValue || searchValue.length < 3) return;

		if (searchValue.includes(' ')) {
			setLoading(true);
			let surname = capitalize(searchValue);
			const splitedSurname = surname.split(' ');

			const surnameNameOptionsMap: Record<string, T[]> = {};
			for (let i = 1; i < splitedSurname.length; i++) {
				surname = splitedSurname.slice(0, i).join(' ');
				try {
					const surnameOptions = await searchSurnames({ searchText: surname });
					for (const surnameOption of surnameOptions ?? []) {
						const namesOptions = await searchNames({
							searchText: surnameOption.label, // Это фамилия
							entityName,
							companyId: user.company_id ?? '',
							storeId: storeId,
							status: additionalOptions?.onlyActive ? 'active' : undefined,
							filter,
						});

						if (namesOptions && namesOptions.length > 0) {
							surnameNameOptionsMap[surnameOption.label] = namesOptions;
						}
					}
					setOptionNames(surnameNameOptionsMap ?? {});
					setOptionSurnames(surnameOptions ?? []);
				} catch {
					notification.error({ message: t('Не удалось загрузить данные') });
				}
			}
			setLoading(false);
			setOpen(true);
		} else {
			setLoading(true);
			try {
				if (checkIsId(searchValue)) {
					const option = await searchNames({
						searchText: searchValue,
						entityName,
						companyId: user.company_id ?? '',
						storeId: storeId,
						status: additionalOptions?.onlyActive ? 'active' : undefined,
					});
					setOptionNames({ [searchValue]: option ?? [] });
				} else {
					setValue(searchValue);
					const surnameOptions = await searchSurnames({ searchText: searchValue });
					setOptionSurnames(surnameOptions ?? []);
				}
			} catch {
				notification.error({ message: t('Не удалось загрузить данные') });
			}
			setLoading(false);
			setOpen(true);
		}
	}, [searchValue]);

	const onSelect = async (value: string | null, option: T) => {
		if (option.type === 'surname') {
			setValue(value + ' ');
			setLoading(false);
		}
		if (['name', 'fullname'].includes(option.type)) {
			setOpen(false);
		}
		setLoading(false);
	};

	const onClear = () => {
		setOptionNames({});
		setOptionSurnames([]);
		setValue(undefined);
		updateValue(null);
	};

	const onFocus = async () => {
		if (!value && additionalOptions?.suggestEntities && entityName === 'userExecutors') {
			const userOptions = await getActiveUsers(user.store_id, constants.store?.tz);
			setOptionSurnames(userOptions as T[]);
		}
		setOpen(true);
	};

	const onBlur = () => {
		setOpen(false);
		if (value) {
			setInitialValue(value);
		} else {
			setValue(undefined);
			setOptionNames({});
			setOptionSurnames([]);
		}
	};

	const filterOption = (text: string, option: T | undefined) => filterGroups(text, option);

	return {
		open: open,
		optionNames: optionNames,
		optionSurnames: optionSurnames,
		isLoading: isLoading,
		setInitialValue: setInitialValue,
		filterOption: filterOption,
		onClear: onClear,
		onSelect: onSelect,
		onFocus: onFocus,
		onBlur: onBlur,
		dictionary: dictionary,
		optionGroup: isLoading ? [] : transformOptions(optionNames, optionSurnames),
	};
}
