import { Button, Spin } from 'antd';
import type { JSX } from 'react';
import { memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import i18n from '~/i18n';
import { ReactComponent as LockAltRegular } from '~images/icons/lock-alt-regular.svg';
import { ReactComponent as LockOpenAltRegular } from '~images/icons/lock-open-alt-regular.svg';
import Icon, { CheckCircleFilled } from '~shared/utils/icons';
import renderStatus from '~shared/utils/renderStatus';

import { useStyles } from './styles';

export const getViewValue = (value: any, dictionary: Record<string, string> = {}, tagView = false) => {
	if ((!value && value !== 0) || (Array.isArray(value) && !value.length)) {
		return i18n.t('Не заполнено');
	}

	if (Array.isArray(value)) {
		return tagView
			? value.map((tag) => renderStatus(tag, {}, {}))
			: value.map((value) => dictionary[value] ?? value).join(', ');
	}

	const valueFromDict = dictionary[value] ?? value;
	return tagView ? renderStatus(valueFromDict, {}, {}) : valueFromDict;
};

export const getViewText = (
	value: unknown,
	path: string | undefined,
	dictionary: Record<string, string> = {},
	loading = false,
	params?: {
		className?: string;
		tagView?: boolean;
		blank?: boolean;
		dataTest?: string;
	},
	viewIconRight?: JSX.Element
): JSX.Element => {
	if (!value && value !== 0) {
		return loading ? (
			<Spin size="small" />
		) : (
			<span className={params?.className}>
				<Trans i18nKey="Не заполнено" />
			</span>
		);
	}

	const valueArray = Array.isArray(value) ? value : [value];

	if (path) {
		return (
			<>
				{valueArray.map((value) => (
					<Link
						key={value}
						to={`/${path}/${value}`}
						target={params?.blank ? '_blank' : undefined}
						className={params?.className}
						data-test={params?.dataTest}
					>
						{getViewValue(value, dictionary, params?.tagView)}
					</Link>
				))}
			</>
		);
	}

	return (
		<>
			{valueArray.map((value) => (
				<span key={value} className={params?.className}>
					{getViewValue(value, dictionary, params?.tagView)}
					{viewIconRight && <span> {viewIconRight}</span>}
				</span>
			))}
		</>
	);
};

export const getColSpan = (
	colspan?:
		| Record<
				string,
				{
					span: number;
				}
		  >
		| number
) => {
	if (typeof colspan === 'number') {
		return {
			xl: { span: colspan },
			lg: { span: colspan },
			sm: { span: colspan },
			xs: { span: 24 },
		};
	}
	if (colspan) {
		return colspan;
	}
	return {
		xl: { span: 6 },
		lg: { span: 8 },
		sm: { span: 12 },
		xs: { span: 24 },
	};
};

export const useFieldChangeProps = (
	input: {
		value: string | number | null;
		onChange: (value: string | number | null) => void;
	},
	disabled: boolean | undefined,
	hasLock: boolean | undefined,
	editMode: boolean | undefined,
	dataTest: string,
	pattern: string | undefined = undefined,
	resetValue = false
) => {
	const { classes } = useStyles();
	const [locked, toggleLock] = useState(true);
	const [inputValue, changeInputValue] = useState<string | number | null>(input.value === '' ? null : input.value);
	const [prevValue, setPrevValue] = useState<string | number | null>(input.value);
	useEffect(() => {
		if (resetValue) {
			changeInputValue(input.value);
		}
	}, [resetValue]);
	const getChangeProps = (onChangeFunc: any, value: any) => {
		return {
			onChange: (e: any) => {
				if (pattern) {
					const regex = new RegExp(pattern);
					if (!regex.test(e.target.value)) {
						return;
					}
				}
				onChangeFunc(e?.target?.value ?? e ?? null);
			},
			value: value,
			disabled: hasLock ? locked || disabled : disabled,
		};
	};

	const changeProps = hasLock
		? getChangeProps(changeInputValue, inputValue)
		: getChangeProps(input.onChange, input.value === '' ? null : input.value);

	useEffect(() => {
		if (!editMode) {
			setPrevValue(input.value);
			changeInputValue(input.value);
		}
	}, [input.value, editMode]);

	useEffect(() => {
		if (inputValue !== input.value) {
			setPrevValue(inputValue);
			changeInputValue(input.value);
		}
	}, [input.value]);

	useEffect(() => {
		if (hasLock && locked) {
			input.onChange(inputValue);
		}
	}, [locked]);

	const lockButton = (
		<Button
			type={locked ? undefined : 'primary'}
			ghost={!locked}
			className={classes.lock}
			onClick={() => toggleLock(!locked)}
			data-test={dataTest}
			disabled={disabled}
		>
			{locked ? (
				<>
					<Icon component={LockAltRegular} />
					{prevValue !== input.value && <CheckCircleFilled className={classes.checkIcon} />}
				</>
			) : (
				<Icon component={LockOpenAltRegular} className={classes.unlockIcon} />
			)}
		</Button>
	);

	return {
		locked,
		lockButton,
		changeProps,
	};
};

export const EmptyValue = memo(() => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();

	return <span className={cx(classes.valueOnViewMode, classes.emptyValue)}>{t('Не заполнено')}</span>;
});
