import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { ProductGroups } from '~types/productGroups';

export default {
	list: makeListFn<{ ids?: string[]; hierarchy?: boolean }, any>('/api/admin/product_groups/list'),
	load: (data: ProductGroups.ProductGroupLoadRequest, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: ProductGroups.ProductGroup[] }>('/api/admin/product_groups/load', data, config),
	search: (data: { name: string; cursor?: string; limit?: number }) =>
		axios.post<{ product_groups: ProductGroups.ProductGroup[] }>('/api/admin/product_groups/search', data),
	save: (data: ProductGroups.ProductGroupSaveRequest) => axios.post('/api/admin/product_groups/save', data),
	hierarchy_list: (data: { parent_group_id: string | string[] }) =>
		axios.post<{ product_groups: Array<ProductGroups.ProductGroupChild> }>(
			'/api/admin/product_groups/hierarchy_list',
			data
		),
};
