import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useRouteError } from 'react-router-dom';

import { appSessionId } from '~/appConfig';
import { isTvClientMode } from '~shared/utils/isTvClientMode';
import { useUser } from '~zustand/userData';

import ErrorPage from '../ErrorPage';

export function RouterErrorBoundary() {
	const error = useRouteError();

	const [t] = useTranslation();
	const user = useUser();

	const isTv = isTvClientMode() || user.role === 'tv_device';

	useEffect(() => {
		// @ts-expect-error
		if (error.status) {
			return;
		}

		window.Ya?.Rum.logError(
			{
				message: isTv ? 'Front crush in Tableau' : 'Front crush in WMS',
				page: window.location.href,
				additional: {
					type: 'FRONT_CRUSH',
					error,
				},
			},
			// @ts-expect-error
			error
		);
	}, [error]);

	if (isTv) {
		return <Navigate to="/tv-client/tableau/crush" />;
	}

	// @ts-expect-error
	if (error.status === 403) {
		return (
			<ErrorPage
				error={{
					// @ts-expect-error
					status: error.status ?? null,
					// @ts-expect-error
					code: error?.data.code ?? 'API_ERROR',
					// @ts-expect-error
					text: error?.data.text ?? t('Ошибка запроса'),
					errorId: appSessionId,
					// @ts-expect-error
					details: error?.data.details,
				}}
			/>
		);
	}

	return (
		<ErrorPage
			error={{
				status: 599,
				code: 'FRONT_CRUSH',
				text: t('В данный момент что-то не так с сервером'),
				errorId: appSessionId,
			}}
		/>
	);
}
