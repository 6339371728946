import { useTranslation } from 'react-i18next';

import { ReactComponent as shoppingBag } from '~images/icons/warehouse-orders.svg';
import { extendedOrdersStatusTime } from '~shared/constants/tableauOrders';
import arrayToChunks from '~shared/utils/arrayToChunks';
import type { OrdersMetricsTypes } from '~shared/utils/tableauOrdersMetrics';

import ColumnContent from '../../ColumnContent/ColumnContent';
import { useStyles } from './styles';

interface Props {
	storeId?: string | null;
	calculatedMetrics: Omit<OrdersMetricsTypes, 'orders'>;
}

const OrdersMetrics = ({ storeId, calculatedMetrics }: Props) => {
	const { classes } = useStyles();
	const { t } = useTranslation();

	return (
		<ColumnContent
			link={`/stores/${storeId}/orders?tab=orders`}
			icon={shoppingBag}
			iconColor="darkGray"
			text={t('Заказы')}
		>
			<div data-test="metrics" className={classes.ordersMetricsWrapper}>
				{arrayToChunks(Object.keys(extendedOrdersStatusTime), 2).map((data, i) => (
					<div key={i} className={classes.metricsItem}>
						{data.map((metric) => (
							<div key={metric} className={classes.metric}>
								<p className={classes.metricName}>{t(extendedOrdersStatusTime[metric])}</p>
								<span data-test={metric} className={classes.metricValue}>
									{calculatedMetrics[metric]}
								</span>
							</div>
						))}
					</div>
				))}
			</div>
		</ColumnContent>
	);
};

export default OrdersMetrics;
