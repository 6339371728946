import { Button, Col, Row, Tag, Tooltip } from 'antd';
import { useContext, useState } from 'react';

import { ReactComponent as CurlyBracketLeft } from '~images/icons/bracket-curly-regular.svg';
import { ReactComponent as CurlyBracketRight } from '~images/icons/bracket-curly-right-regular.svg';
import { ReactComponent as CopyRegular } from '~images/icons/copy-regular.svg';
import { ReactComponent as EyeOpen } from '~images/icons/eye-regular.svg';
import { ReactComponent as EyeClosed } from '~images/icons/eye-slash-regular.svg';
import copyToClipboard from '~shared/utils/copyToClipboard';

import type { Suggestion } from '../Block';
import { entityTypes, serviceTypes } from '../constants';
import { SupportSearchContext } from '../context';
import JsonBlock from './Elements/JSONBlock';
import KeydataTable from './Elements/KeydataTable';
import { useStyles } from './styles';

interface Props {
	blockKey: string;
	type: keyof typeof entityTypes;
	service: keyof typeof serviceTypes;
	data: Record<string, any>;
	keydata: Record<string, string | number | boolean>;
	suggests?: Suggestion[];
	status: string;
}

const createSuggestsFromWms = (data: Record<string, any>): Suggestion[] => {
	const irrelevantKeys = ['user_id', 'external_id', 'parent_id'];
	const relevantKeys = Object.keys(data).filter((e) => e.endsWith('_id') && data[e] && !irrelevantKeys.includes(e));
	return relevantKeys.map((key) => ({
		name: key.replace('_id', ''),
		request: `wms ${key.replace('_id', '')} ${data[key]}`,
		run_on_click: true,
	}));
};

const SupportSearchCard = ({ blockKey, type, service, data, keydata, suggests, status }: Props) => {
	const { classes, cx } = useStyles();
	const [showJSON, setShowJSON] = useState(keydata ? !Object.keys(keydata).length : true);
	const { addBlock } = useContext(SupportSearchContext);

	const proButtonText = (
		<>
			<CurlyBracketLeft height={21} />
			{showJSON ? <EyeClosed height={21} /> : <EyeOpen height={21} />}
			<CurlyBracketRight height={21} />
		</>
	);

	const cardSuggests = suggests && suggests.length ? suggests : createSuggestsFromWms(data);

	const isError = status === 'error';

	return (
		<div className={classes.cardContainer}>
			<Row
				justify="space-between"
				className={cx(classes.padding, classes.cardHeader, {
					[classes.errorHeader]: isError,
					[classes.roundHeader]: !showJSON && !cardSuggests.length && !Object.keys(keydata).length,
				})}
				align="middle"
			>
				<Col>
					<div>
						<span className={classes.cardType}>{entityTypes[type] ?? type}</span>
						<Tag color="magenta">{serviceTypes[service] ?? service}</Tag>
					</div>
				</Col>
				<Col>
					<Button onClick={() => setShowJSON((pre) => !pre)}>{proButtonText}</Button>
					<Tooltip title="Скопировать JSON" placement="bottom">
						<Button onClick={() => copyToClipboard(JSON.stringify(data, null, 2))}>
							<CopyRegular height={21} />
						</Button>
					</Tooltip>
				</Col>
			</Row>
			{keydata && !!Object.keys(keydata).length ? <KeydataTable keydata={keydata} isError={isError} /> : null}
			{showJSON && <JsonBlock data={data} />}
			{cardSuggests.length ? (
				<Row className={cx(classes.padding, classes.suggestButtonsRow, { [classes.errorFooter]: isError })}>
					{cardSuggests.map((suggest) => (
						<Button key={suggest.request} onClick={() => addBlock(blockKey, suggest.request, suggest.run_on_click)}>
							{suggest.name}
						</Button>
					))}
					<Button onClick={() => addBlock(blockKey, 'special chatgpt', true)}>Обсудить</Button>
					<Button onClick={() => addBlock(blockKey, 'special giftcard', true)}>💌</Button>
					<Button onClick={() => addBlock(blockKey, 'special tamagochi', true)}>Покормить</Button>
				</Row>
			) : null}
		</div>
	);
};

export default SupportSearchCard;
