import { notification } from 'antd';

import api from '~/api';
import i18n from '~/i18n';
import type { EquipmentSave } from '~types/equipment';

export const save = async ({
	savingItem,
	onAfterSave,
	setModalVisible,
}: {
	savingItem: EquipmentSave;
	onAfterSave: () => void;
	setModalVisible: (value: boolean) => void;
}) => {
	try {
		await api.equipment.items.save(savingItem, { disableDefaultNotification: [400] });
		notification.success({
			message: i18n.t('Оборудование сохранено'),
		});
		onAfterSave();
	} catch {
		notification.error({
			message: i18n.t('Не удалось сохранить оборудование'),
		});
	} finally {
		setModalVisible(false);
	}
};

export const remove = async ({
	equipmentId,
	onAfterRemove,
	setIsRemoving,
}: {
	equipmentId: string;
	onAfterRemove: () => void;
	setIsRemoving: (value: boolean) => void;
}) => {
	try {
		await api.equipment.items.rm({ equipment_id: equipmentId }, { disableDefaultNotification: [400] });
		notification.success({
			message: i18n.t('Оборудование удалено'),
		});
		onAfterRemove();
	} catch {
		notification.error({
			message: i18n.t('Не удалось удалить оборудование'),
		});
	} finally {
		setIsRemoving(false);
	}
};
