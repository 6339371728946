import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Assortments } from '~types/assortments';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<{ title?: string }, Assortments.Assortment>('/api/admin/assortments/list'),
	load: makeLoadFn<Assortments.Assortment, 'assortment_id'>('/api/admin/assortments/load', 'assortment_id'),
	save: (data: { assortment_id: string; external_id: string; title: string; parent_id: string; status: string }) =>
		axios.post<{ result: Assortments.Assortment }>('/api/admin/assortments/save', data),
};
