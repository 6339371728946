import { useTranslation } from 'react-i18next';

import { ReactComponent as WarehouseStaffIcon } from '~images/icons/warehouse-staff.svg';
import { StorekeepersCard } from '~shared/components/StorekeepersCard';
import { storekeeperStatuses } from '~shared/constants/storekeepers';
import type { StorekeepersDataType } from '~shared/hooks/useStorekeepersData';

import ColumnContent from '../../ColumnContent/ColumnContent';
import { useStyles as healthTableauStyles } from '../../styles';

const Storekeepers = ({ data, storeId }: { data: StorekeepersDataType; storeId?: string | null }) => {
	const { classes: healthTableauClasses } = healthTableauStyles();
	const { t } = useTranslation();

	return (
		<div className={healthTableauClasses.column}>
			<ColumnContent icon={WarehouseStaffIcon} iconColor="brightBlue" text={t('Складской персонал')} />
			{Object.keys(storekeeperStatuses).map((storekeeperStatus) => (
				<ColumnContent
					key={storekeeperStatus}
					text={storekeeperStatuses[storekeeperStatus]}
					amount={data[storekeeperStatus]?.length ?? 0}
					isSubTitle={true}
					isColumn={true}
				>
					<StorekeepersCard data={data} status={storekeeperStatus} storeId={storeId} isHealthPage={true} />
				</ColumnContent>
			))}
		</div>
	);
};

export default Storekeepers;
