import { Col, Row } from 'antd';

import { ReactComponent as CreditCard } from '~images/icons/credit-card-regular.svg';
import { ReactComponent as MoneyBill } from '~images/icons/money-bill-regular.svg';
import type { Orders } from '~types/orders';

import { useStyles } from './styles';

type Props = {
	order: Orders.Order;
};

const PaymentCell = ({ order }: Props) => {
	const { classes } = useStyles();
	const invoice = order.attr?.invoices?.[0];
	let text = '';
	const paymentType = invoice?.invoice_payment_method_type;
	if (paymentType && ['cash', 'online'].includes(paymentType)) {
		text = invoice.invoice_sum ?? order.total_price;
	} else {
		text = '—';
	}

	return (
		<Row align="top" wrap={false}>
			<Col>{text}</Col>
			{paymentType && (
				<Col className={classes.paymentIconBlock}>
					{paymentType === 'cash' ? (
						<MoneyBill height={18} className={classes.paymentIcon} />
					) : paymentType === 'online' ? (
						<CreditCard height={18} className={classes.paymentIcon} />
					) : null}
				</Col>
			)}
		</Row>
	);
};

export default PaymentCell;
