import { notification } from 'antd';
import type { FunctionComponent } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalComponent from '~shared/components/ModalComponent';
import type { Stores } from '~types/stores';
import { changeJoinedStore } from '~zustand/actions/userData';

interface Props {
	visible: boolean;
	selectedStore: Stores.StoreShort;
	closeModal: () => void;
	usersStoreTitle: string | undefined;
}

const JoinStoreDialog: FunctionComponent<Props> = ({ visible, selectedStore, closeModal, usersStoreTitle }: Props) => {
	const [t] = useTranslation();
	const [loading, toggleLoading] = useState<boolean>(false);

	const joinStoreAction = async (store: { store_id: string; title: string }) => {
		toggleLoading(true);
		try {
			closeModal();
			await changeJoinedStore(store.store_id, usersStoreTitle, store.title);
		} catch (e) {
			notification.error({
				message: e.message,
			});
		} finally {
			toggleLoading(false);
		}
	};

	return (
		<ModalComponent
			open={visible}
			onOk={() => (selectedStore ? joinStoreAction(selectedStore) : null)}
			onCancel={() => closeModal()}
			okButtonProps={{
				disabled: loading,
				className: 'data-test-ok-button',
			}}
			className="data-test-join-modal"
			okText={t('Да')}
			cancelText={t('Нет')}
		>
			<p className="text-primary">
				{usersStoreTitle
					? `${t(
							'Хотите ли вы открепиться от склада «{{usersStoreTitle}}» и прикрепиться к складу «{{selectedStoreTitle}}»',
							{ usersStoreTitle, selectedStoreTitle: selectedStore?.title }
						)}?`
					: `${t('Хотите ли вы прикрепиться к складу «{{selectedStoreTitle}}»', {
							selectedStoreTitle: selectedStore?.title,
						})}?`}
			</p>
		</ModalComponent>
	);
};

export default memo(JoinStoreDialog);
