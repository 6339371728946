import type { Cluster, Renderer } from '@googlemaps/markerclusterer';
import { defaultOnClusterClickHandler, MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';

import { createDiv } from '../utils';
import type { GoogleBalloon, GoogleInfoWindows } from './GoogleOrdersMap';

declare const google: any;
const CLUSTER_ICON_SIZE = 52;

type ClusterInfoWindowClasses = {
	containerClasses?: string[];
	leftClasses?: string[];
	rightClasses?: string[];
};

type Props = {
	map: google.maps.Map | null;
	infoWindows: GoogleInfoWindows;
	classes: ClusterInfoWindowClasses;
};

const createGoogleMarkerClusterer = ({ map, infoWindows, classes }: Props) => {
	const onClusterClick = createOnClusterClickHandler(infoWindows, classes);
	const renderer = createRenderer();

	return new MarkerClusterer({
		map,
		markers: [],
		algorithm: new SuperClusterAlgorithm({ maxZoom: 22 }),
		onClusterClick,
		renderer,
	});
};

function createOnClusterClickHandler(infoWindows: GoogleInfoWindows, classes: ClusterInfoWindowClasses) {
	return (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => {
		infoWindows.closeAll();
		const zoom = map.getZoom();

		if (zoom && zoom >= 18 && cluster.markers) {
			// Создание и открытие InfoWindow при нажатии на иконку кластера
			const clusterInfoWindow = createClusterInfoWindow({
				markers: cluster.markers as GoogleBalloon[],
				classes,
			});

			infoWindows.add(clusterInfoWindow);
			clusterInfoWindow.open({
				anchor: cluster.marker,
			});
		} else {
			defaultOnClusterClickHandler(event, cluster, map);
		}
	};
}

type CreateClusterInfoWindowProps = {
	markers: GoogleBalloon[];
	classes: ClusterInfoWindowClasses;
};

function createClusterInfoWindow({
	markers,
	classes: { containerClasses = [], leftClasses = [], rightClasses = [] },
}: CreateClusterInfoWindowProps) {
	const right = createDiv({ classNames: rightClasses });
	const links: HTMLAnchorElement[] = [];

	markers.forEach((balloon, index) => {
		const link = document.createElement('a');
		link.textContent = balloon.docNumber;

		if (index === 0) {
			link.classList.add('active');
			right.innerHTML = balloon.infoWindowContent;
		}

		link.addEventListener('click', (e) => {
			e.preventDefault();

			if (!link.classList.contains('active')) {
				links.forEach((link) => {
					link.classList.remove('active');
				});

				link.classList.add('active');
				right.innerHTML = balloon.infoWindowContent;
			}
		});

		const refreshInfoWindowContent = () => {
			if (link.classList.contains('active')) {
				right.innerHTML = balloon.infoWindowContent;
			}
		};

		balloon.setClusterInfoWindowRefresher(refreshInfoWindowContent);
		links.push(link);
	});

	const left = createDiv({
		classNames: leftClasses,
		children: links,
	});

	const container = createDiv({
		classNames: containerClasses,
		children: [left, right],
	});

	return new google.maps.InfoWindow({ content: container });
}

function createRenderer(): Renderer {
	return {
		render: (cluster: Cluster) => {
			if (!cluster?.markers?.length) {
				return;
			}

			const { markers, count, position } = cluster;
			const reassignmentAmount = (markers as GoogleBalloon[]).filter(({ color }) => color === 'blue').length;

			const clusterIcon = window.btoa(`
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
					<circle r="9" cx="10" cy="10" fill="gray" />
					<circle r="4" cx="10" cy="10" fill="blue"
						stroke="blue"
						stroke-width="10"
						stroke-dasharray="calc(25 * ${reassignmentAmount / markers.length}) 31.4"
						transform="rotate(-90) translate(-20)"
					/>
					<circle r="4" cx="10" cy="10" fill="white" />
					<circle r="9" cx="10" cy="10" fill="none"
						stroke="white"
						stroke-width="1"
					/>
				</svg>
			`);

			return new google.maps.Marker({
				position,
				icon: {
					url: `data:image/svg+xml;base64,${clusterIcon}`,
					scaledSize: new google.maps.Size(CLUSTER_ICON_SIZE, CLUSTER_ICON_SIZE),
				},
				label: {
					text: String(count),
					color: 'black',
					fontSize: '13px',
				},
				zIndex: 1000 + count, // adjust zIndex to be above other markers
			});
		},
	};
}

export default createGoogleMarkerClusterer;
