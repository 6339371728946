import api from '~/api';
import type { ProductGroups } from '~types/productGroups';

function toProductGroupNode(
	productGroup: ProductGroups.ProductGroup,
	isLeaf?: boolean
): ProductGroups.ProductGroupNode & { isLeaf?: boolean } {
	return {
		...productGroup,
		isLeaf,
	};
}

export async function loadPath(nodeIds: string[]): Promise<ProductGroups.ProductGroupNode[]> {
	if (nodeIds.length === 0) {
		return [];
	}

	const { data } = await api.productGroups.load({
		group_id: nodeIds,
		hierarchy: true,
	});

	return data.result.map((e) => toProductGroupNode(e));
}

export async function loadNodeChildren(nodeIds?: string[]): Promise<ProductGroups.ProductGroupNode[]> {
	const result = await api.productGroups.hierarchy_list({
		parent_group_id: nodeIds ?? '',
	});

	return result.data.product_groups.map((e) => toProductGroupNode(e.product_group, !e.has_child));
}

export async function searchNodes(name: string): Promise<string[]> {
	const { data } = await api.productGroups.search({
		name,
	});

	return data.product_groups.map((e) => e.group_id);
}
