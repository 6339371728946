import type { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import NotFound from '~shared/components/ErrorPage/NotFound';
import { useCheckPermit, useUser } from '~zustand/userData';

export const RouteCheckerComponent = ({
	disableRouteChecker = false,
	children,
}: {
	disableRouteChecker?: boolean;
	children?: ReactNode;
}) => {
	const user = useUser();
	const isPermitOutOfStore = useCheckPermit('out_of_store');
	const isPermitOutOfCompany = useCheckPermit('out_of_company');

	const params = useParams<{ store_id: string; company_id: string }>();

	if (disableRouteChecker && (isPermitOutOfStore || isPermitOutOfCompany)) {
		return <>{children}</>;
	}

	if (params.store_id && user.store_id !== params.store_id) {
		if (!isPermitOutOfStore) {
			return <NotFound />;
		}

		throw {
			status: 403,
			data: {
				code: 'ER_ACCESS',
				details: {
					store_id: params.store_id,
				},
			},
		};
	}

	if (params.company_id && user.company_id !== params.company_id) {
		if (!isPermitOutOfCompany) {
			return <NotFound />;
		}

		throw {
			status: 403,
			data: {
				code: 'ER_ACCESS',
				details: {
					company_id: params.company_id,
				},
			},
		};
	}

	return <>{children}</>;
};
