import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { PathsChangeStatusPostReason } from '~server-types/doc/api/disp/orders';
import type { OrderType } from '~server-types/doc/api/models/order';
import type { OrdersHistorySearchData, OrdersHistoryShiftSearchData } from '~shared/components/OrdersHistory';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { AcceptanceMarketReport } from '~types/common';
import type { Kitchen } from '~types/kitchen';
import type { Orders } from '~types/orders';
import type { Stocks } from '~types/stocks';
import type { Suggests } from '~types/suggests';

import type { UnassignRequestData } from '../components/OrdersPage/UnassignCourier/context';
import { makeLoadFn } from './storage/makeLoadFn';
import type { OrdersProcessingRequired } from './types';

export default {
	setUsers: (data: { order_id: string; users: string[] }) => axios.post('/api/disp/orders/set_users', data),
	list: makeListFn<{ external_id?: string }, Orders.Order>('/api/disp/orders/list'),
	incomplete: makeListFn<EmptyObject, Orders.Order>('/api/disp/orders/incomplete'),
	load: makeLoadFn<Orders.Order, 'order_id'>('/api/disp/orders/load', 'order_id'),
	history: (data: OrdersHistorySearchData) =>
		axios.post<{ orders: any[]; cursor: string | null }>('/api/disp/orders/history', data),
	shift: (data: OrdersHistoryShiftSearchData) => axios.post<{ orders: Orders.Order[] }>('/api/disp/orders/shift', data),
	create: (data: any, config?: AxiosAdditionalConfig) => axios.post('/api/disp/orders/create', data, config),
	suggests: (data: { order_id: string }) =>
		axios.post<{ suggests: Suggests.Suggest[] }>('/api/disp/orders/suggests', data),
	cancel: (data: { order_id: string }) => axios.post('/api/disp/orders/cancel', data),
	retry: (data: { order_id: string }) => axios.post('/api/disp/orders/retry', data),
	ack: (data: { order_id: string }) => axios.post('/api/disp/orders/ack', data),
	log: (data: { order_id: string; cursor?: string; limit?: number }) =>
		axios.post<{ log: Orders.Log[]; cursor: string }>('/api/disp/orders/log', data),
	inventorySnapshot: (data: { order_id: string; cursor?: string | null; limit?: number; full?: boolean }) =>
		axios.post<{ snapshot: Orders.Snapshot[]; cursor: string | null }>('/api/disp/orders/inventory-snapshot', data),
	orderSignal: (data: { order_id: string; signal: Orders.Signal; data?: Orders.SignalData }) =>
		axios.post<EmptyObject>('/api/disp/orders/order-signal', data),
	updateAttr: (data: { order_id: string; type: OrderType; attr: { upd?: Orders.Attr[]; waybill?: string } }) =>
		axios.post('/api/disp/orders/update-attr', data),
	stocks_log: (data: { order_id: string; cursor?: string }) =>
		axios.post<{ stocks_log: Stocks.Stock[]; cursor: string }>('/api/disp/orders/stocks_log', data),
	suggestsDone: (data: Suggests.CloseSuggestData) => axios.post('/api/disp/orders/suggests-done', data),
	changeStatus: (
		data:
			| { order_id: string; status: 'complete' }
			| {
					order_id: string;
					status: 'failed';
					reason: PathsChangeStatusPostReason;
					comment?: string | null;
			  }
	) => axios.post('/api/disp/orders/change_status', data),
	set_courier: (data: {
		mode?: 'assign' | 'append';
		order_id: string;
		courier_id: string;
		delivery_promise?: string | null;
		en_route_timer?: number | null;
		wait_client_timer?: number | null;
	}) => axios.post('/api/disp/orders/set_courier', data),
	unassign_courier: (data: UnassignRequestData, config?: AxiosAdditionalConfig) =>
		axios.post('/api/disp/orders/unassign_courier', data, config),
	makeWave: (data: { limit: number }) => axios.post('/api/disp/orders/make-wave', data),
	importReport: (data: { order_id: string; csv: string }) => axios.post('/api/disp/orders/import_report', data),
	children: (data: { order_id: string; cursor?: string }) => axios.post('/api/disp/orders/children', data),
	export_report: (data: { order_id: string; report_type: 'inv3' | 'inv19' }) =>
		axios.post('/api/disp/orders/export_report', data, {
			responseType: 'blob',
		}),
	list_courier: (data: { order_id: string }) => axios.post('/api/disp/orders/list_courier', data),
	acceptance_report: (data: { order_id: string }, config?: AxiosAdditionalConfig) =>
		axios.post('/api/disp/orders/acceptance_report', data, config),
	acceptance_market_report: (data: { order_id: string }, config?: AxiosAdditionalConfig) =>
		axios.post<{
			code: string;
			parcels: AcceptanceMarketReport['parcels'];
		}>('/api/disp/orders/acceptance_market_report', data, config),
	acceptance_market_report_done: (
		data: { order_id: string; acceptance_documents: { file_meta_id: string }[] },
		config?: AxiosAdditionalConfig
	) => axios.post<{ code: string }>('/api/disp/orders/acceptance_market_report_done', data, config),
	updateAssortment: (data: { order_id: string }) => axios.post('/api/disp/orders/update_assortment', data),
	stowageOnDiff: (data: { order_id: string }) => axios.post('/api/disp/orders/stowage_on_diff', data),
	kitchen_suggest: (
		data: {
			suggest_id: string;
			action: Kitchen.SuggestAction;
			user_id?: string | null;
			count?: number;
		},
		config?: AxiosAdditionalConfig
	) =>
		axios.post<{ code: string; message: string; suggest?: Suggests.Suggest }>(
			'/api/disp/orders/kitchen_suggest',
			data,
			config
		),
	approveAcceptance: (data: { order_id: string }) => axios.post('/api/disp/orders/approve_acceptance', data),
	reopenAcceptance: (data: { order_id: string }) => axios.post('/api/disp/orders/reopen_acceptance', data),
	finishCollect: (data: { order_ids: string[] }) =>
		axios.post<{ code?: string }>('/api/disp/orders/finish_collect', data),
	returnSupplier: (data: { order_id: string }, config?: AxiosAdditionalConfig) =>
		axios.post<EmptyObject>('/api/disp/orders/return_supplier', data, config),
	partnerPickupList: () => axios.post<{ results: Orders.Order[] }>('/api/disp/orders/partner_pickup_list', {}),
	markAsPartnerTaken: (data: { order_id: string }) =>
		axios.post<{ order: Orders.Order }>('/api/disp/orders/mark_as_partner_taken', data),
	processingRequired: (data: OrdersProcessingRequired) => axios.post('/api/disp/orders/processing_required', data),
	loadCourierPhone: (data: { order_id: string }) =>
		axios.post<{ code: string; phones: string[] }>('/api/disp/orders/load_courier_phone', data),
	queue: () => axios.post<{ code: string; queue: string[] }>('/api/disp/orders/queue', {}),
};
