import { Skeleton } from 'antd';
import type { JSX, KeyboardEvent } from 'react';
import type { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';

import type { SectionConfig } from '~constants/dataPageFields/types';
import { useHandleFormChange } from '~shared/components/DataForm/useHandleFormChange';
import { Form } from '~shared/components/Forms';
import type { ColspanLayout } from '~types/common';

import { SectionsContent } from './SectionsContent';
import { useStyles } from './styles';

export interface DataFormProps {
	pageName?: string;
	className?: string;
	loading?: boolean;
	editMode?: boolean;
	addMode?: boolean;
	dataTest?: string;
	formFields?: SectionConfig[];
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	layout?: (sections: JSX.Element[]) => JSX.Element;
	renderSections?: (props: { editMode?: boolean; addMode?: boolean }) => JSX.Element;
	twoColLayout?: [ColspanLayout, ColspanLayout];
	twoColFullWidth?: boolean;
	additionalDataSecondCol?: JSX.Element;
	disabled?: boolean;
	parentName?: string;
	sectionClassName?: string;
}

export type DataFormType<TFieldValues extends FieldValues = FieldValues> = DataFormProps & {
	hookForm: UseFormReturn<TFieldValues>;
	onSubmit?: SubmitHandler<TFieldValues>;
};

const DataForm = <TFieldValues extends FieldValues = FieldValues>(props: DataFormType<TFieldValues>) => {
	const { loading, dataTest, className, formFields = [], onSubmit, hookForm } = props;
	const { cx, classes } = useStyles({});

	useHandleFormChange(formFields, hookForm);

	if (loading) {
		return <Skeleton active />;
	}

	return (
		<Form
			hookForm={hookForm}
			onSubmit={onSubmit}
			className={cx(classes.form, className)}
			data-test={dataTest}
			onKeyDown={(e: KeyboardEvent<HTMLFormElement>) => {
				if (e.key === 'Enter') e.preventDefault();
			}}
		>
			<SectionsContent {...props} />
		</Form>
	);
};

export default DataForm;
