/* eslint-disable import/first */

// Это должно быть первой строчкой основного js файла
// @ts-ignore
__webpack_nonce__ = window.__NONCE__;

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-splitter-layout/lib/index.css';
import '@lavka/ui-kit/lib/styles/fonts.css';
import '@lavka/ui-kit/lib/styles/index.css';
import '@lavka/ui-kit/lib/styles/themes/default.css';
import './index.css';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import type { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import Favicon from 'react-favicon';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { z } from 'zod';

import i18n from '~/i18n';
import { zodErrorMap } from '~constants/zodErrorMap';
import faviconUrl from '~images/favicon-32x32.png';
import faviconTestingUrl from '~images/favicon-testing-32x32.png';
import store from '~redux/store';
import { BlockstatDict, sendTimeMark } from '~shared/utils/rum';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekday);

import App from './components/App';

window.Ya.Rum.sendTimeMark(BlockstatDict.BeforeInit);
// Предотвращает возвращение undefined для window[accountListName] при инициализации яндекс метрики
// https://github.com/narkq/react-yandex-metrika/blob/master/src/index.js#L25
window.yandex_metrika_accounts = [];

// global-error-map: https://zod.dev/ERROR_HANDLING?id=global-error-map
z.setErrorMap(zodErrorMap);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<>
		<Favicon url={process.env.REACT_APP_MODE === 'production' ? faviconUrl : faviconTestingUrl} />
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>
				<App />
			</Provider>
		</I18nextProvider>
	</>
);

sendTimeMark(BlockstatDict.Init);

// В react 18 выкинули children из FC
// Можно обновить react-i18next, но тогда всплывает еще больше проблем с совместимостью типов с нашим кодом
declare module 'react-i18next' {
	export interface I18nextProviderProps {
		children?: ReactNode;
	}
}
