import i18n from '~/i18n';

const emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
export const phoneRegex = new RegExp(/^[\+][0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);

export const validateEmail = (value: string, warningMessage = 'Некорректный e-mail') =>
	value && !emailReg.test(value) ? i18n.t(warningMessage) : undefined;

export const validateNonNegative = (value?: number) =>
	value && Number(value) < 0 ? i18n.t('Значение не может быть меньше нуля') : undefined;

export const validateOneOrMore = (value?: number) =>
	typeof value === 'number' && value < 1 ? i18n.t('Значение не может быть меньше единицы') : undefined;

export const validateMoreThanZero = (value?: number) =>
	value && Number(value) <= 0 ? i18n.t('Значение не может быть меньше или равно нулю') : undefined;

export const validateDay = (value?: number) => {
	const minDayHours = 1;
	const maxDayHours = 24;

	return value !== undefined && value !== null && (value < minDayHours || value > maxDayHours)
		? i18n.t('Значение не может быть меньше {{min}} и больше {{max}}', { min: minDayHours, max: maxDayHours })
		: undefined;
};

export const validateMinutesUnderDay = (value?: number) => {
	const minMinutes = 1;
	const maxMinutes = 1440; // 24 часа * 60 минут

	return value !== undefined && value !== null && (value < minMinutes || value > maxMinutes)
		? i18n.t('Значение не может быть меньше {{min}} и больше {{max}}', { min: minMinutes, max: maxMinutes })
		: undefined;
};

export const validateHoursUnderWeek = (value?: number) => {
	const minWeekHours = 1;
	const maxWeekHours = 168;

	return value !== undefined && value !== null && (value < minWeekHours || value > maxWeekHours)
		? i18n.t('Значение не может быть меньше {{min}} и больше {{max}}', { min: minWeekHours, max: maxWeekHours })
		: undefined;
};
