import { Button, Col, Input, Row, Select } from 'antd';
import type { KeyboardEventHandler } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { SearchOutlined } from '~shared/utils/icons';

import type { SuptoolsSearchHandler } from '../Card/utils';
import { suggestLabels } from '../constants';
import SearchField from './SearchField';
import { searchFieldsConfig } from './searchFieldsConfig';
import { useStyles } from './styles';
import { getSearchFieldSpan } from './utils';

type Props = {
	handlers: SuptoolsSearchHandler[];
	handleChange: (value: string) => void;
	handleSend: () => void;
	onKeyDown: KeyboardEventHandler<HTMLInputElement>;
};

const SearchInput = ({ handlers, handleSend, handleChange, onKeyDown }: Props) => {
	const { classes } = useStyles();
	const [values, setValues] = useState<Record<string, string>>({});
	const [currentHandler, setCurrentHandler] = useState<string>(handlers[0].handler);

	useEffect(() => {
		handlers.push({
			handler: 'special',
			kwargs: { surprise: null },
		});
	}, []);

	useEffect(() => {
		setValues({});
	}, [currentHandler]);

	useEffect(() => {
		let resultString = currentHandler;
		for (const key in values) {
			resultString += ' ' + key + ' ' + values[key];
		}
		handleChange(resultString);
	}, [currentHandler, values]);

	const currentHandlerObject = useMemo(() => handlers.find((el) => el.handler === currentHandler), [currentHandler]);
	const currentKwargs = useMemo(() => Object.keys(currentHandlerObject?.kwargs ?? {}), [currentHandlerObject]);

	return (
		<div className={classes.searchLineContainer}>
			<Input.Group>
				<Row gutter={[5, 0]}>
					<Col span={9}>
						<Select
							options={handlers.map((el) => ({ value: el.handler, label: suggestLabels[el.handler] }))}
							onChange={(value: string) => setCurrentHandler(value)}
							value={currentHandler}
						/>
					</Col>
					{currentKwargs.map((kwarg, i) => (
						<Col key={kwarg} span={getSearchFieldSpan(currentKwargs.length, i === currentKwargs.length - 1)}>
							<SearchField
								kwarg={kwarg}
								fieldConfig={searchFieldsConfig[kwarg]}
								onChange={setValues}
								value={values[kwarg]}
								onKeyDown={onKeyDown}
							/>
						</Col>
					))}
					<Col span={1}>
						<Button onClick={handleSend} icon={<SearchOutlined />} />
					</Col>
				</Row>
			</Input.Group>
		</div>
	);
};

export default SearchInput;
