import { HEADER_HEIGHT, HEADER_PADDING } from '~shared/components/Layout/Header/constants';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		flex: 'auto',
		alignItems: 'center',
		minInlineSize: 375,
		textAlign: 'center',
		justifyContent: 'space-between',
		maxInlineSize: 446,
		marginBlock: '0',
		marginInline: 'auto',
		blockSize: `calc(100vh - ${HEADER_PADDING * 2 + HEADER_HEIGHT}px)`,
	},
	status: {
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '30px',
		lineHeight: '38px',
		marginBlockEnd: 20,
		color: theme.text.disabled,
	},
	code: {
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '24px',
		'&>div': {
			marginBlockEnd: 16,
		},
		marginBlockEnd: 20,
		color: theme.text.primary,
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '16px',
		lineHeight: '24px',
		color: theme.text.primary,
		marginBlockEnd: 32,
	},
	buttons: {
		display: 'flex',
		justifyContent: 'center',
	},
	layout: {
		marginBlock: '0',
		marginInline: '-22px',
		blockSize: '100vh',
		display: 'flex',
		inlineSize: '100%',
		'& main': {
			display: 'flex',
			justifyContent: 'center',
		},
	},
	versionData: {
		color: theme.text.secondary,
		fontSize: '14px',
		'&>div': {
			marginBlockEnd: 16,
		},
		'&>div:last-child': {
			marginBlockEnd: 32,
		},
		'& b': {
			fontWeight: 500,
		},
	},
	dataTitle: {
		color: theme.text.disabled,
		margin: 0,
	},
}));
