import { Alert } from 'antd';
import { memo } from 'react';

import { useStyles } from './styles';
import { storeErrorsDictionary } from './utils';

interface Props {
	errors: string[];
	type?: 'warning' | 'success' | 'info' | 'error';
	closable?: boolean;
	showIcon?: boolean;
}

const ErrorsAlert = ({ errors, type = 'warning', closable = false, showIcon = true }: Props) => {
	const { classes } = useStyles();

	const message = () => {
		return (
			<span className={classes.errorString}>
				{errors.map((error: string, index) => {
					let text = storeErrorsDictionary[error] || error;
					if (text !== error && index === 0) {
						text = text.charAt(0).toUpperCase() + text.slice(1);
					}
					return `${text}${index !== errors.length - 1 ? ', ' : '.'}`;
				})}
			</span>
		);
	};

	return (
		<Alert data-test="store errors alert" message={message()} type={type} closable={closable} showIcon={showIcon} />
	);
};

export default memo(ErrorsAlert);
