import { languages } from '~i18n/consts';
import { userRolesNames, userRolesValues } from '~server-types/doc/api/models/user';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

import { statusesDictionary } from './commonConstants';
import type { SectionConfig } from './types';

export const userStatusesColorDictionary = {
	active: 'green',
	disabled: '',
};

export const userFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'last_name',
				label: tDynamic('Фамилия'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
				placeholder: tDynamic('Введите значение'),
			},
			{
				key: 'first_name',
				label: tDynamic('Имя'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
				placeholder: tDynamic('Введите значение'),
			},
			{
				key: 'middle_name',
				label: tDynamic('Отчество'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
				placeholder: tDynamic('Введите значение'),
			},
			{
				key: 'nick',
				label: tDynamic('Ник пользователя'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
			},
			{
				key: 'user_id',
				label: tDynamic('Идентификатор пользователя'),
				type: 'string',
				inputType: 'text',
				onlyView: true,
			},
			{
				key: 'role',
				label: tDynamic('Роль пользователя'),
				type: 'string',
				inputType: 'select',
				canEdit: true,
				options: userRolesValues,
				dictionary: userRolesNames,
			},
			{
				key: 'pin',
				label: tDynamic('PIN-код'),
				type: 'string',
				inputType: 'password_box',
				canEdit: false,
				hidden: false,
			},
			{
				key: 'employee_number',
				label: tDynamic('Табельный номер'),
				type: 'string',
				inputType: 'text',
			},
			{
				key: 'status',
				label: tDynamic('Статус'),
				hidden: false,
				canEdit: true,
				options: ['active', 'disabled'],
				dictionary: statusesDictionary,
				type: 'multiple-select',
				inputType: 'multiple-select',
			},
			{
				key: 'company_id',
				label: tDynamic('Компания'),
				type: 'string',
				inputType: 'text',
				path: 'companies',
				hasLock: false,
			},
		],
	},
	{
		label: tDynamic('Контакты'),
		fields: [
			{
				key: 'phone',
				label: tDynamic('Телефон пользователя'),
				type: 'string',
				inputType: 'phone',
				canEdit: true,
				placeholder: tDynamic('Введите значение'),
			},
			{
				key: 'email',
				label: tDynamic('Почта пользователя'),
				type: 'string',
				inputType: 'text',
				placeholder: tDynamic('Введите значение'),
			},
		],
	},
	{
		label: tDynamic('Другое'),
		fields: [
			{
				key: 'provider',
				label: tDynamic('Через кого проводилась аутентификация'),
				type: 'string',
				inputType: 'select',
				options: ['guest', 'yandex', 'test', 'internal'],
				onlyView: true,
			},
			{
				key: 'store_id',
				label: tDynamic('Склад'),
				type: 'other',
				inputType: 'other',
				canEdit: true,
				hidden: false,
			},
			{
				key: 'created',
				label: tDynamic('Дата и время создания'),
				type: 'string',
				inputType: 'calendar',
				params: {
					onlyDate: false,
					withSeconds: true,
					alwaysShowYear: true,
				},
				onlyView: true,
			},
			{
				key: 'updated',
				label: tDynamic('Дата и время изменения'),
				type: 'string',
				inputType: 'calendar',
				params: {
					onlyDate: false,
					withSeconds: true,
					alwaysShowYear: true,
				},
				onlyView: true,
			},
			{
				key: 'device',
				label: tDynamic('Массив залогиненных устройств пользователя'),
				inputType: 'other',
				type: 'other',
			},
			{
				key: 'stores_allow',
				label: tDynamic('Доступные склады'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				selectEntity: 'stores',
				mode: 'multiple',
			},
			{
				key: 'clusters_allow',
				label: tDynamic('Доступные кластера'),
				selectEntity: 'clusters',
				type: 'multiple-select',
				inputType: 'multiple-select',
				mode: 'multiple',
			},
		],
	},
	{
		label: tDynamic('Настройки'),
		fields: [
			{
				key: 'lang',
				label: tDynamic('Язык интерфейса'),
				type: 'string',
				inputType: 'select',
				defaultValue: 'ru_RU',
				options: languages as unknown as string[],
				canEdit: true,
			},
		],
	},
	{
		label: tDynamic('Визитка'),
		fields: [
			{
				key: 'qrcode',
				label: tDynamic('Визитка'),
				inputType: 'other',
				type: 'other',
			},
		],
	},
]);
