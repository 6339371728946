import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { appSessionId, appVersion } from '~/appConfig';
import type { Events } from '~types/events';
import { getIsOnline, turnAppOffline, turnAppOnline } from '~zustand/metaInfo';

import { logAxiosError, logTvDeviceError, logTvDeviceResponse } from './logs';
import { updateAdditional } from './rum';

export const axiosEv = axios.create();
axiosBetterStacktrace(axiosEv);

axiosEv.defaults.baseURL = '/';
axiosEv.defaults.headers.common = {
	'X-WMS-UI-Version': appVersion,
	'X-Request-Id': appSessionId,
};

axiosEv.interceptors.request.use(
	(config) => {
		if (process.env.REACT_APP_MODE === 'ci') {
			console.log(`---NETWORK EVENT REQUEST URL: ${config.url}`, JSON.stringify(config, undefined, ' '), '---');
			/* eslint-enable */
		}
		return config;
	},
	() => turnAppOffline()
);

axiosEv.interceptors.response.use(
	(response) => {
		logTvDeviceResponse(response);

		const isOnline = getIsOnline();
		if (!isOnline) {
			turnAppOnline();
		}
		if (process.env.REACT_APP_MODE === 'ci') {
			console.log(
				`---NETWORK EVENT RESPONSE URL: ${response.config.url}`,
				JSON.stringify(response, undefined, ' '),
				'---'
			);
			/* eslint-enable */
		}
		return response;
	},
	(error) => {
		logTvDeviceError(error);
		logAxiosError(error);

		const isOnline = getIsOnline();
		error.message?.startsWith('Network Error') ? turnAppOffline() : !isOnline && turnAppOnline();
	}
);

export type EventData = { type?: string; [key: string]: any };

interface EventIn {
	auth: null;
	data: EventData;
	key: Key;
	stored: number;
	_id: string;
}

export interface Event {
	data: EventData[];
	key: Key;
}

interface Subscription {
	key: Key;
	cb: EventCallback[];
}

export type Key = (string | undefined)[];
export type EventCallback = (event: Event, code: string, time?: number) => void;
let delay = 1;

const CancelToken = axios.CancelToken;
let source: any;
let state: any = undefined;
const timeout = 25;
let subscriptions: Subscription[] = [];
let prevSubscriptions: Subscription[] = [];

const ev = {
	take: (data: { state?: string; timeout?: number; keys: Key[] }, config: any) =>
		axiosEv.post<{ events: EventIn[]; state: string; code: Events.EventCodeType }>('/api/ev/take', data, config),
	push: (data: { key: string[]; data: any }[], config: any) => axiosEv.post('/api/ev/push', data, config),
};

export const findSubscription = (key: Key) => {
	const stringKey = JSON.stringify(key);
	const idx = subscriptions.findIndex((s) => JSON.stringify(s?.key) === stringKey);
	return subscriptions[idx];
};

const findSubscriptionIndex = (key: Key) => {
	const stringKey = JSON.stringify(key);
	return subscriptions.findIndex((s) => JSON.stringify(s?.key) === stringKey);
};

// отписка от определенной подписки
export const unsubscribeSingle = (key: Key) => {
	const stringKey = JSON.stringify(key);
	const idx = subscriptions.findIndex((s) => JSON.stringify(s?.key) === stringKey);

	if (idx !== -1) {
		delete subscriptions[idx];
		state = undefined;
	}
	if (source) {
		source.cancel('unsubscribe');
	}
};

const mergeEvents = (events: EventIn[]): Event[] => {
	const result: Event[] = [];

	events.forEach((event) => {
		const findedEvent = result.find((item) => item.key.toString() === event.key.toString());
		if (findedEvent) findedEvent.data.push(event.data);
		else {
			const newEvent = { ...event, data: [event.data] };
			result.push(newEvent);
		}
	});
	return result;
};

const emit = (events: EventIn[], code: Events.EventCodeType) => {
	const mergedEvents = mergeEvents(events);

	mergedEvents.forEach((event) => {
		const subscription = findSubscription(event.key);
		if (!subscription) return;
		subscription.cb.forEach((cb) => {
			try {
				cb(event, code);
			} catch (e) {
				console.error(e);
			}
		});
	});
};

const emitAll = (events: EventIn[], code: Events.EventCodeType) => {
	subscriptions.forEach((s) => {
		s.cb.forEach((cb) => {
			try {
				const stringKey = JSON.stringify(s.key);
				const event = events.find((e) => JSON.stringify(e.key) === stringKey);
				// @ts-expect-error какая-то странность для обработки INIT
				cb(event?.data, code);
			} catch {
				console.error("Can't find event");
			}
		});
	});
};

const take = async () => {
	source = CancelToken.source();
	if (subscriptions.toString() !== prevSubscriptions.toString()) {
		state = undefined;
		prevSubscriptions = subscriptions;
	}
	try {
		const keys = subscriptions.filter((item) => item.cb.length).map((item) => item.key);
		const resp = await ev.take({ keys, state, timeout }, { cancelToken: source.token });
		// @ts-expect-error
		if (resp.status === 500 || !resp.data || resp.message === 'Network ErrorPage') throw Error();
		else delay = 1;
		state = resp.data.state;
		const code = resp.data.code;
		switch (code) {
			case 'OK':
				emit(resp.data.events, code);
				break;
			case 'INIT':
				emitAll(resp.data.events, code);
				break;
			case 'MAYBE_DATA_LOST':
				emitAll(resp.data.events, code);
				break;
			default:
				break;
		}

		setTimeout(take, 500);
	} catch (e) {
		if (e.message === 'unsubscribe') return;
		if (axios.isCancel(e)) {
			setTimeout(take, 500);
			return;
		}
		setTimeout(take, delay * 1000);
		if (delay < 16) delay *= 2;
	}
};

export const init = () => {
	void take();
};
export const subscribe = (key: Key, cb: EventCallback, needInit = false) => {
	let subscription = findSubscription(key);
	if (subscription) {
		subscription.cb.push(cb);
		if (needInit) {
			state = undefined;
			if (source) {
				source.cancel('update keys');
			} else {
				init();
			}
		}
	} else {
		subscription = { key, cb: [cb] };
		subscriptions.push(subscription);
		state = undefined;
		if (source) {
			source.cancel('update keys');
		} else {
			init();
		}
	}
	return () => {
		const idx = subscription?.cb.findIndex((item) => item === cb);
		if (~idx!) subscription?.cb.splice(idx!, 1);
		const idxSub = findSubscriptionIndex(key);
		if (!subscription?.cb.length) {
			subscriptions.splice(idxSub!, 1);
		}
	};
};

export const unSubscribeAll = () => {
	subscriptions = [];
	state = undefined;
	if (source) {
		source.cancel('update keys');
	}
};

export const changeStoreId = (storeId: string) => {
	updateAdditional({
		userStoreId: storeId,
	});

	subscriptions = subscriptions.map((sub) => {
		const newKey = [...sub.key];
		newKey[2] = storeId;
		return { ...sub, key: newKey };
	});
	state = undefined;
	if (source) {
		source.cancel('update keys');
	}
};
