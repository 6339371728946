import { useFieldArray } from 'react-hook-form';

import type { BaseFieldArrayProps } from '~shared/components/Forms/types';

export type { FieldArrayFieldsProps, WrappedFieldArrayProps } from './types';

export function FieldArray<P = object, FieldValue = object>(props: BaseFieldArrayProps<P, FieldValue>) {
	const fieldArray = useFieldArray({ name: props.name! });

	if (!('component' in props)) {
		throw new Error('Missing Component props');
	}

	const { component: Component, ...restProps } = props;

	const fields = {
		map: (callback: (name: string, index: number) => any) =>
			fieldArray.fields.map((field, index) => callback(`${props.name}[${index}]`, index)),
		remove: (index: number | number[] | undefined) => fieldArray.remove(index),
		push: (value: unknown) => fieldArray.append(value),
		get: (index: string | number) => fieldArray.fields[index],
	};

	return (
		// @ts-expect-error
		<Component {...restProps} fields={fields} />
	);
}
