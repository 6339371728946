import api from '~/api';
import type { FinanceWallets } from '~types/financeWallets';
import { checkExp, useUserConstants } from '~zustand/userData';

import type { CourierWalletInfo } from './CouriersTab/types';

const isOldWalletsApi = (): boolean =>
	process.env.REACT_APP_MODE === 'ci' || !checkExp('exp_b2b_new_wallet_service_ui');

export const getApiWalletsList = () => (isOldWalletsApi() ? api.wallets.listOld : api.wallets.list);

export const getApiWalletsLog = () => (isOldWalletsApi() ? api.wallets.logOld : api.financeWallets.log);

type GetApiWalletsListParams = {
	type: 'courier';
	entityId?: string;
	limit?: number;
	cursor?: string;
};

type GetApiWalletsResponse = {
	cursor: string | null;
	results: CourierWalletInfo[];
};

export const getApiStoreWalletsList = (params: GetApiWalletsListParams): Promise<{ data: GetApiWalletsResponse }> => {
	const { type, entityId, limit, cursor } = params;

	const courierIdParams = entityId ? { courier_id: [entityId] } : {};
	if (isOldWalletsApi()) {
		return api.wallets.listOld({ wallet_type: type, limit, cursor, ...courierIdParams });
	}

	return api.wallets.list({ wallet_type: type, limit, cursor, ...courierIdParams });
};

export type CashConfig = FinanceWallets.CashConfig;

export function useCashConfiguration(): CashConfig {
	const constants = useUserConstants();
	const configs = constants.configs?.cash;
	const isConfigsReady = Boolean(constants);
	const isNewWalletUI = Boolean(configs?.new_cashier_ui.enabled);
	const isIncassationFormExtended = Boolean(configs?.extended_incassation_form_enabled.enabled);
	const isHandOffDoubleConfirmation = Boolean(configs?.cash_hand_off_double_confirmation_enabled.enabled);
	const isHandOverDoubleConfirmation = Boolean(configs?.cash_hand_over_double_confirmation_enabled.enabled);
	const isDoubleConfirmation = isHandOffDoubleConfirmation || isHandOverDoubleConfirmation;
	const isHandOffEnabled = Boolean(configs?.cash_hand_off_enabled.enabled);
	const isHandOverEnabled = Boolean(configs?.cash_hand_over_enabled.enabled);
	// todo: LAVKABBLOGISTIC-1933, remove testing flag
	const isCashCorrectionEnabled =
		Boolean(configs?.cash_correct_enabled.enabled) && process.env.REACT_APP_MODE !== 'production';
	const isCashCorrectionCommentRequiredEnabled = Boolean(configs?.cash_correct_comment_require_enabled.enabled);
	const cashOnHandLimit = configs?.cash_on_hand_limit.value || undefined;
	const cashHandOverLimit = configs?.cash_hand_over_limit.value || undefined;
	const cashHandOffTimerDurationLimit = configs?.cash_hand_off_timer_duration.value || undefined;

	return {
		isConfigsReady,
		isNewWalletUI,
		isIncassationFormExtended,
		isHandOffEnabled,
		isHandOffDoubleConfirmation,
		isHandOverEnabled,
		isHandOverDoubleConfirmation,
		isDoubleConfirmation,
		isCashCorrectionEnabled,
		isCashCorrectionCommentRequiredEnabled,
		cashOnHandLimit,
		cashHandOverLimit,
		cashHandOffTimerDurationLimit,
	};
}
