import { Button } from '@lavka/ui-kit';
import { notification, Spin } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import api from '~/api';
import { ReactComponent as AuthApiLogo } from '~images/auth-api-logo.svg';
import { ReactComponent as YandexLogo } from '~images/yandex-logo.svg';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import { removeCredentialsFromLocalStorage } from '~shared/utils/localStorage';
import { logout } from '~shared/utils/loginFunctions';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { useCheckPermit, useUserConstants } from '~zustand/userData';

import { useStyles } from './styles';

const providerSettings = makeDynamicTranslations({
	yandex: {
		name: tDynamic('Войти с Яндекс ID'),
		icon: <YandexLogo />,
	},
	'yandex-team': {
		name: tDynamic('Войти с Yandex Team'),
		icon: <YandexLogo />,
	},
	'auth-api': {
		name: tDynamic('Войти с Yango Tech ID'),
		icon: <AuthApiLogo />,
	},
});

const Login = () => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { providers, domain_configs: domainConfigs } = useUserConstants();
	const isPermitLogin = useCheckPermit('login');
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const invite = searchParams.get('invite') ?? undefined;
	const provider = searchParams.get('provider');

	const selectProvider = useCallback(
		async (provider: string) => {
			try {
				const redirectUrl = `${window.location.origin}/login-done`;

				removeCredentialsFromLocalStorage();
				const { data } = await api.profile.authPrepare(
					{ provider, invite, redirect_uri: redirectUrl },
					{
						disableExternalLog: [410],
					}
				);
				window.location.replace(data.url);
			} catch (error) {
				notification.error({
					message: error,
				});
			}
		},
		[invite]
	);

	useAsyncEffect(async () => {
		if (provider) {
			await selectProvider(provider);
		}
		if (!!invite && !isPermitLogin) {
			await logout(false, `/login?invite=${invite}`);
		}
		if (!invite && !isPermitLogin) {
			navigate('/');
		}
	}, [provider, isPermitLogin]);

	const getProviderSettings = (provider: (typeof providers)[number]) => {
		return provider === 'auth-api' && domainConfigs.branding === 'yandex'
			? providerSettings.yandex
			: providerSettings[provider];
	};

	if (provider) {
		return <Spin />;
	}

	return (
		<div data-test="login page" className={classes.paper}>
			<p className={classes.title}>{t('Авторизация в WMS')}</p>
			<p className={classes.description}>{t('Выберите способ входа для начала работы')}</p>
			<div className={classes.root}>
				{providers.map((provider) => {
					const { name, icon } = getProviderSettings(provider);

					return (
						<Button
							key={provider}
							className={cx(classes.loginButton, {
								[classes.teamLoginButton]: provider === 'yandex-team',
							})}
							onClick={() => selectProvider(provider)}
							data-test={`provider ${provider}`}
							iconLeft={icon}
						>
							{name}
						</Button>
					);
				})}
			</div>
		</div>
	);
};

export default Login;
