import type { TableProps } from 'antd';
import { Alert, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { ColumnType } from 'antd/lib/table';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '~styles/theme';

type PossiblePairs<T extends object> =
	| {
			[K in keyof T]: { dataIndex: K; render?: (text: T[K], data: T, index: number) => ReactNode };
	  }[keyof T]
	| { dataIndex?: never; render?: (value: T, data: T, index: number) => ReactNode };

export type ProColumns<T extends object = any> = Omit<ColumnType<T>, 'dataIndex' | 'render'> &
	PossiblePairs<T> & {
		hideInTable?: boolean | (() => boolean);
		children?: ProColumns<T>[];
	};

export type ProTableProps<T extends object> = Omit<TableProps<T>, 'columns'> & {
	columns: ProColumns<T>[];
	toolBarRender?: () => ReactNode[];
	tableAlertRender?: (value: NonNullable<TableProps<T>['rowSelection']>) => ReactNode;
};

const useStyles = makeStyles()({
	table: {
		overflow: 'auto',
	},
	toolbar: {
		display: 'flex',
		gap: 8,
		marginBlockEnd: 16,
		'> div:first-child': {
			marginInlineEnd: 'auto',
		},
	},
});

export function ProTable<T extends object = any>(props: ProTableProps<T>) {
	const { className, columns, toolBarRender, tableAlertRender, style, pagination = false, ...restProps } = props;

	const { cx, classes } = useStyles();
	const [t] = useTranslation();

	const modifiedColumns = columns
		.filter((e) => (typeof e.hideInTable === 'function' ? !e.hideInTable() : !e.hideInTable))
		.map((column) => ({
			...column,
			children: column.children?.filter((e) =>
				typeof e.hideInTable === 'function' ? !e.hideInTable() : !e.hideInTable
			),
		}));

	return (
		<div className={className} style={style}>
			{toolBarRender && (
				<div className={classes.toolbar}>
					{toolBarRender()
						.filter(Boolean)
						.map((node, index) => (
							// @ts-expect-error
							<div key={node?.key ?? index}>{node}</div>
						))}
				</div>
			)}
			{!!props.rowSelection?.selectedRowKeys?.length && (
				<Alert
					type="info"
					message={
						tableAlertRender && props.rowSelection
							? tableAlertRender(props.rowSelection)
							: t('Выбрано {{count}} элементов', { count: props.rowSelection.selectedRowKeys.length })
					}
				/>
			)}
			<Table
				className={cx(classes.table, props.className)}
				columns={modifiedColumns as ColumnsType<T>}
				pagination={pagination}
				{...restProps}
			/>
		</div>
	);
}

ProTable.Summary = Table.Summary;
