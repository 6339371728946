import { Checkbox, Spin } from 'antd';
import dayjs from 'dayjs';
import { memo, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { dispatchDeliveryType, order as orderStatuses } from '~constants/order';
import {
	courierListItemStatusesNames,
	orderReassignCourierTypesNames,
	orderReassignCourierTypesValues,
} from '~server-types/doc/api/disp/orders';
import useLoadData from '~shared/hooks/useLoadData';
import courierName from '~shared/utils/courierName';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import renderStatus from '~shared/utils/renderStatus';
import type { Couriers } from '~types/couriers';
import type { Orders } from '~types/orders';
import { useCheckExp, useUserConstants } from '~zustand/userData';

import { statusColorDictionary } from '../pageConfig';
import { useStyles } from '../styles';
import UnassignButton from './Button';
import type { ContextTypeCourier } from './context';
import { Context } from './context';
import Group from './Group';

interface Props {
	order: Orders.Order;
}

const UnassignCourier = ({ order }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { stores } = useCache({
		stores: order.store_id,
	});
	const { requestData, setRequestField } = useContext<ContextTypeCourier>(Context);

	const clusterId = stores[order.store_id]?.cluster_id;
	const [orderIds, setOrdersIds] = useState<string[]>([]);

	const constants = useUserConstants();
	const tz = constants.store?.tz;

	const exp_mc_hammer_timetable = constants.store?.options_setup?.exp_mc_hammer_timetable;

	const isExpMcHammer = useCheckExp('exp_mc_hammer');
	const isExpJohnnyBlaze = useCheckExp('exp_johnny_blaze');
	const isAutoUnassignEnabled = !isExpMcHammer && isExpJohnnyBlaze;

	const isExpJackSparrow2 = useCheckExp('exp_jack_sparrow_2');
	const isExpJackSparrow3 = useCheckExp('exp_jack_sparrow_3');

	const cache = useCache({
		clusters: clusterId,
		products: order.required.map((product) => product.product_id),
		items: order.required.map((product) => product.item_id),
		stores: order.store_id,
		orders: orderIds,
		couriersBrief: order.courier_id,
	});

	const { delivery_fallback: fallBack, delivery_max_weight: maxWeight } =
		cache.clusters[clusterId ?? '']?.dispatch_setup || {};

	const getFallbackText = (fallbackType: any) => {
		const texts: any = {};
		if (maxWeight) {
			texts.taxi = t('Назначить на такси (не более {{maxWeight}} кг)', {
				maxWeight,
			});
			texts.deli = t('Отправить на доставку (не более {{maxWeight}} кг)', {
				maxWeight,
			});
		} else {
			texts.taxi = t('Назначить на такси');
			texts.deli = t('Отправить на доставку');
		}
		return texts[fallbackType];
	};

	const couriersData = useLoadData(
		() => api.orders.list_courier({ order_id: order.order_id }),
		[order.order_id, order.updated],
		!isExpJackSparrow2
	);

	const couriersByGroup = useMemo(() => {
		const free: Couriers.CourierForDispatch[] = [];
		const assigned: Couriers.CourierForDispatch[] = [];
		const orderIds: string[] = [];

		if (couriersData.loaded) {
			couriersData.data.couriers?.forEach((courier: Couriers.CourierForDispatch) => {
				if (courier.status === 'free') {
					free.push(courier);
				}
				if (isExpJackSparrow3 && courier.status === 'assigned') {
					assigned.push(courier);
					orderIds.push(...courier.orders.map((order) => order.order_id));
				}
			});
		}

		setOrdersIds(orderIds); // получаем id ордеров курьеров, чтобы потом загрузить по ним данные

		return {
			free,
			assigned,
		};
	}, [couriersData.loaded]);

	// проверяем не отключен ли фоллбэк из-за расписания или эксперимента
	const isFallbackAvailable = () => {
		if (isExpMcHammer || !fallBack) return false;

		if (exp_mc_hammer_timetable) {
			for (const timetable of exp_mc_hammer_timetable) {
				let begin = dayjs(timetable.begin, 'HH:mm:ss', tz);
				let end = dayjs(timetable.end, 'HH:mm:ss', tz);
				if (begin.isAfter(end)) {
					begin = begin.subtract(1, 'day'); // если начало было в предыдущих сутках
				} else if (end.isBefore(begin)) {
					end = end.add(1, 'day'); // если конец в следующих сутках
				}
				if (dayjs().tz(tz).isBetween(begin, end, null, '[]')) {
					return false;
				}
			}
		}
		return true;
	};

	const onSetRequestField = ({ taxiOnly, taxiType }: { taxiOnly: boolean; taxiType?: string }) => {
		setRequestField({ taxi_only: taxiOnly, taxi_type: taxiType });
	};

	return (
		<div className={classes.container}>
			<div className={classes.detailsBlock}>
				<h2 className={classes.blocksTitle}>{t('Информация о заказе')}</h2>
				<div className={classes.detailItem}>
					<span className={classes.detail}>{t('Заказ')}: </span> {`${order.doc_number}`}
				</div>
				<div className={classes.detailItem}>
					<span className={classes.detail}>{t('Статус')}: </span>{' '}
					{renderStatus(order.status, orderStatuses, statusColorDictionary)}
				</div>
				<div className={classes.detailItem}>
					<span className={classes.detail}>{t('Дата создания заказа')}: </span>
					{formatTimeAndYear(order.created)}
				</div>
				<div className={classes.detailItem}>
					<span className={classes.detail}>{t('Адрес доставки')}: </span>
					{order.client_address?.fullname || '—'}
				</div>
				<div className={classes.detailItem}>
					<span className={classes.detail}>{t('Курьер')}: </span>
					<span data-test="window courier name string">{courierName(cache.couriersBrief[order.courier_id ?? ''])}</span>
				</div>
				<div className={classes.detailItem}>
					<span className={classes.detail}>{t('Вес')}: </span>
					{cache.loading ? (
						<Spin size="small" />
					) : (
						<span data-test="order weight">
							{typeof order.vars?.total_order_weight === 'number'
								? t('{{weight}} кг', {
										weight: parseFloat((order.vars?.total_order_weight / 1000).toFixed(2)),
									})
								: '—'}
						</span>
					)}
				</div>
				<div className={classes.detailItem}>
					<span className={classes.detail}>{t('Очередь')}: </span>
					<span data-test="window dispatch_delivery_type">
						{order.dispatch_delivery_type
							? dispatchDeliveryType[order.dispatch_delivery_type] || order.dispatch_delivery_type
							: '—'}
					</span>
				</div>
			</div>
			<div className={classes.reassignInfoContainer}>
				<div
					className={cx(classes.buttonsBlock, {
						[classes.allButtonsBlock]: isAutoUnassignEnabled,
					})}
				>
					<h2 className={classes.blocksTitle}>{t('Автоматическое назначение')}</h2>
					{cache.loading && <Spin />}
					{!cache.loading && (
						<div className={classes.buttonsBlockControls}>
							<Checkbox
								className={classes.buttonsBlockCheckbox}
								data-test="disable batching checkbox"
								checked={requestData.disable_batching}
								onChange={(e) => setRequestField({ disable_batching: e.target.checked })}
							>
								{t('Не делать мультизаказом')}
							</Checkbox>
							<div
								className={cx(classes.buttonsBlockButtons, {
									[classes.allButtonsBlockButtons]: isAutoUnassignEnabled,
								})}
							>
								{isAutoUnassignEnabled
									? orderReassignCourierTypesValues.map((taxiType) => (
											<UnassignButton
												key={taxiType}
												isDisabled={
													order.vars?.manual_dispatch_last_target === `any_${taxiType === 'any' ? 'vehicle' : taxiType}`
												}
												taxiType={taxiType}
												onButtonClick={onSetRequestField}
												buttonText={orderReassignCourierTypesNames[taxiType]}
												isSelected={requestData.taxi_only && requestData.taxi_type === taxiType}
											/>
										))
									: isFallbackAvailable() &&
										fallBack && (
											<UnassignButton
												isDisabled={order.dispatch_delivery_type === 'yandex_taxi'}
												taxiType="any"
												onButtonClick={onSetRequestField}
												buttonText={getFallbackText(fallBack)}
												isSelected={requestData.taxi_only && requestData.taxi_type === 'any'}
											/>
										)}
								<UnassignButton
									onButtonClick={onSetRequestField}
									buttonText={t('Назначить на любого курьера')}
									isSelected={!requestData.taxi_only && !requestData.courier_id}
								/>
							</div>
						</div>
					)}
				</div>
				{isExpJackSparrow2 && (
					<div className={classes.couriersBlock}>
						<h2 className={classes.blocksTitle}>{t('Ручное назначение')}</h2>
						{couriersData.loading && <Spin />}
						{couriersData.loaded &&
							['free', 'assigned'].map((groupName) => (
								<Group
									key={groupName}
									title={courierListItemStatusesNames[groupName]}
									couriers={couriersByGroup[groupName]}
									batching={groupName === 'assigned'}
								/>
							))}
					</div>
				)}
			</div>
		</div>
	);
};
export default memo(UnassignCourier);
