import { notification } from 'antd';

import api from '~/api';
import i18n from '~/i18n';

export const yandexMapsGeocoding = async (addressFullname: string): Promise<[number, number] | undefined> => {
	try {
		const response = await api.yandexMaps.geocode(addressFullname);
		return response.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ');
	} catch {
		notification.error({
			message: i18n.t('Не удалось получить координаты заказа'),
		});
		return undefined;
	}
};
