import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrders, updateOrdersData } from '~redux/actions/ordersData';
import type { AppState } from '~redux/reducers';
import store from '~redux/store';
import type { OrderType } from '~server-types/doc/api/models/order';
import type { Event } from '~shared/utils/ev';
import isKitchenOrderType from '~shared/utils/isKitchenOrderType';
import type { Events } from '~types/events';
import type { Orders } from '~types/orders';
import { addCouriers } from '~zustand/couriersData';

import { Audioplayer } from '../utils/audio';
import useSubscribe from './useSubscribe';

const edaStatusesToReloadOrder = ['ORDER_TAKEN', 'ARRIVED_TO_CUSTOMER', 'DELIVERED'];
const isReloadOrderForDeliveryTimer = (event: Events.OrderEvent, orderInStore: Orders.Order) =>
	event?.eda_status &&
	orderInStore.eda_status !== event.eda_status &&
	edaStatusesToReloadOrder.includes(event.eda_status);

export default function (
	storeId: string,
	loadIncomplete?: boolean,
	updateCouriers?: boolean,
	timeZone?: string,
	onSuccess?: () => void
): {
	ordersData: {
		orders: Orders.Order[];
		loaded: boolean;
	};
	orderEvents: Events.OrderEvent[];
} {
	const ordersData = useSelector((state: AppState) => state.ordersData);
	const [orderEvents, setOrderEvents] = useState<Events.OrderEvent[]>([]);
	const dispatch = useDispatch();

	const processedEventData = (data: Event) => {
		const orderIdToLoad: string[] = [];
		const orderDataToUpdate: any[] = [];
		const couriersToUpdate: string[] = [];
		const orderEvents = data.data.filter(({ type }) =>
			isKitchenOrderType(type as OrderType | undefined)
		) as Events.OrderEvent[];
		setOrderEvents(orderEvents);
		//в области видимости остается ordersData из первого рендера диспетчерской
		orderEvents.forEach((orderEvent) => {
			const processesOrdersData = store.getState().ordersData;
			const orderInStore = processesOrdersData.orders.find(({ order_id }) => {
				return orderEvent.order_id === order_id;
			});
			if (!orderInStore) {
				orderIdToLoad.push(orderEvent.order_id);
				if (orderEvent.status === 'request' && orderEvent.estatus === 'waiting') {
					Audioplayer.play('alert.m4a');
				}
			} else {
				if (
					updateCouriers &&
					orderEvent.status === 'complete' &&
					orderEvent.estatus === 'done' &&
					orderInStore.courier_id
				) {
					couriersToUpdate.push(orderInStore.courier_id);
				}
				if (orderInStore.version < orderEvent.version || isReloadOrderForDeliveryTimer(orderEvent, orderInStore)) {
					orderIdToLoad.push(orderEvent.order_id);
				} else {
					orderDataToUpdate.push(orderEvent);
				}
			}
		});
		if (orderDataToUpdate.length) {
			dispatch(updateOrdersData({ ordersData: orderDataToUpdate }));
		}
		if (orderIdToLoad.length) {
			dispatch(
				getOrders(orderIdToLoad, undefined, {
					timezone: timeZone,
				})
			);
		}
		if (couriersToUpdate.length) {
			addCouriers(couriersToUpdate);
		}
		onSuccess?.();
	};

	const eventCB = (data: Event, code: string) => {
		switch (code) {
			case 'OK':
				processedEventData(data);
				break;
			case 'INIT':
			case 'MAYBE_DATA_LOST':
				dispatch(getOrders(undefined, { loadIncomplete }));
				break;
			default:
				break;
		}
	};

	useSubscribe({
		key: ['order', 'store', storeId],
		cb: eventCB,
		unSub: false,
		single: true,
		name: 'dispatcher',
		needInit: true,
	});

	return { ordersData, orderEvents };
}
