import type { RadioChangeEvent } from 'antd';
import { Col, Radio, Row, Tooltip } from 'antd';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as QuestionCircleSolid } from '~images/icons/question-circle-solid.svg';
import Icon from '~shared/utils/icons';

import { useStyles } from '../styles';
import type { FieldProps } from '../types';
import { EmptyValue, getColSpan, getViewValue } from '../utils';
import { useStyles as useRadioStyles } from './styles';

export interface RadioFieldProps extends FieldProps {
	options: string[];
	dictionary?: Record<string, string>;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	inputClassName?: string;
	hideLabel?: boolean;
	fieldRequired?: boolean;
	dataTest?: string;
	disabledOptions?: string[];
	onlyView?: boolean;
	viewValue?: string;
	tooltip?: string;
	allowClear?: boolean;
}

const RadioField: FunctionComponent<RadioFieldProps> = ({
	id,
	label,
	input,
	disabled,
	options,
	dictionary,
	editMode,
	colspan,
	inputClassName,
	hideLabel,
	fieldRequired,
	dataTest,
	disabledOptions,
	onlyView,
	viewValue,
	tooltip,
	allowClear,
}: RadioFieldProps) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { classes: radioClasses } = useRadioStyles();

	const viewText = viewValue ?? input.value;

	const renderRadioGroup = (
		<div className={cx(classes.inputContainer, 'wms-input-container')}>
			<Radio.Group
				className={cx(inputClassName, classes.inputSelect)}
				data-test={dataTest ?? `data form ${label}${fieldRequired ? ' required' : ''}${disabled ? ' disabled' : ''}`}
				onChange={(e: RadioChangeEvent) => input.onChange(e.target.value)}
				value={input.value}
				disabled={disabled}
				id={id}
			>
				{allowClear && (
					<Radio data-test="data form radio option Не заполнено" value="">
						{t('Не заполнено')}
					</Radio>
				)}
				{options?.map((option, index) => {
					return (
						<Radio
							data-test={`data form radio option ${dictionary?.[option] ?? option}${
								disabledOptions?.includes(option) ? ' disabled' : ''
							}`}
							key={option ?? index}
							value={option}
							disabled={disabledOptions?.includes(option)}
						>
							{dictionary?.[option] ?? option}
						</Radio>
					);
				})}
			</Radio.Group>
		</div>
	);

	return (
		<Row align="top" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={editMode ? { minHeight: 42 } : {}}>
			{!hideLabel && (
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label
						className={cx(classes.label, {
							[classes.labelRequired]: editMode && fieldRequired,
						})}
						htmlFor={id}
					>
						{label}
					</label>
					{tooltip && (
						<Tooltip title={tooltip}>
							<Icon component={QuestionCircleSolid} className={radioClasses.tooltipIcon} />
						</Tooltip>
					)}
					<div className={classes.dotSeparator} />
				</Col>
			)}

			<Col {...getColSpan(colspan)} className={classes.readOnlyFieldContainer}>
				{editMode && !onlyView ? (
					renderRadioGroup
				) : (
					<div className={classes.valueOnViewMode} data-test={`data form ${label}`}>
						{viewText ? getViewValue(viewText, dictionary) : <EmptyValue />}
					</div>
				)}
			</Col>
		</Row>
	);
};

export default RadioField;
