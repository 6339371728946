import { Button, Col, Row, Tooltip } from 'antd';
import type { IntRange } from 'rc-picker/lib/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusRegular } from '~images/icons/plus-regular.svg';
import { ReactComponent as QuestionCircleSolid } from '~images/icons/question-circle-solid.svg';
import Icon from '~shared/utils/icons';

import { useStyles as useFieldStyles } from '../styles';
import type { FieldProps } from '../types';
import { getColSpan } from '../utils';
import { useStyles as useTimeStyles } from './styles';
import TimeRow from './TimeRow';

export interface Day {
	type: string;
	begin?: string;
	end?: string;
}

interface Props extends FieldProps {
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	minuteStep?: IntRange<1, 59>;
	hideLabel?: boolean;
	additionalParams?: {
		typeValue?: string; // если указано, то дизейблим поле с выбором типа с заданным значением
	};
}

const special = ['everyday', 'holiday'];

const TimetableComponent = ({
	label,
	input,
	meta: { error },
	disabled,
	editMode,
	colspan = {
		xl: { span: 20 },
		lg: { span: 20 },
		sm: { span: 24 },
		xs: { span: 24 },
	},
	minuteStep = 15,
	hideLabel,
	additionalParams,
}: Props) => {
	const { cx, classes: fieldClasses } = useFieldStyles();
	const [t] = useTranslation();
	const [days, setDays] = useState<Day[]>(input.value || []);

	useEffect(() => {
		setDays(input.value);
	}, [JSON.stringify(input.value)]);

	const { classes: classes } = useTimeStyles();

	const deleteValue = (indexToDelete: number) => {
		const filteredDays = input.value ? input.value.filter((el: any, index: number) => index !== indexToDelete) : [];
		setDays(filteredDays);
		input.onChange(filteredDays);
	};

	const addRow = () => {
		setDays([...(days ?? []), { type: 'everyday' }]);
	};

	const daysRender = days?.length ? (
		days.map((value, index) => {
			const { type, begin, end } = value;
			const id = begin && end ? `${type}_${begin}_${end}` : `${index}`;

			return (
				<TimeRow
					id={id}
					key={id}
					newItem={!(begin && end)}
					onChange={input.onChange}
					onDelete={deleteValue}
					editMode={editMode}
					disabled={disabled}
					index={index}
					minuteStep={minuteStep}
					value={input.value || []}
					special={special.includes(type)}
					typeValue={additionalParams?.typeValue}
					time={{
						type,
						begin,
						end,
					}}
				/>
			);
		})
	) : (
		<p className={classes.noValueText}>{t('Время работы не задано')}</p>
	);

	return (
		<Row data-test={`data form ${label}${disabled ? ' disabled' : ''}`} className={classes.container} gutter={[0, 14]}>
			<Col {...getColSpan(colspan)}>
				{!hideLabel && (
					<label className={classes.label} style={{ color: error ? 'red' : 'inherit' }}>
						{label}
						<Tooltip title={t('Указывается во временной зоне кластера')}>
							<Icon component={QuestionCircleSolid} className={cx(fieldClasses.tooltipIcon, classes.tooltipIcon)} />
						</Tooltip>
					</label>
				)}
				{daysRender}
				{!disabled && editMode && (
					<Button
						onClick={addRow}
						className={classes.addButton}
						data-test="timetable add btn"
						disabled={days?.length > input.value?.length}
					>
						<Icon component={PlusRegular} />
						{t('Добавить условие')}
					</Button>
				)}
			</Col>
		</Row>
	);
};

export default TimetableComponent;
