import { notification } from 'antd';

import api from '~/api';

export default async function (
	storeId: string,
	options: {
		date?: string;
		metrics?: ('store_metrics' | 'courier_metrics')[];
	},
	setData: (...args: any) => void,
	toggleLoading?: (isLoading: boolean) => void
) {
	const { date, metrics } = options;
	const loadStoreMetricsFunc = api.reportData.realtime_metrics.store_metrics;
	const loadCourierMetricsFunc = api.reportData.realtime_metrics.courier_metrics;

	toggleLoading && toggleLoading(true);
	try {
		const metricsToFetch: Promise<any>[] = [];
		if (metrics) {
			if (metrics.includes('store_metrics')) {
				metricsToFetch.push(loadStoreMetricsFunc({ store_id: storeId, date }));
			}
			if (metrics.includes('courier_metrics')) {
				metricsToFetch.push(loadCourierMetricsFunc({ store_id: storeId, date }));
			}
		} else {
			metricsToFetch.push(
				loadStoreMetricsFunc({ store_id: storeId, date }),
				loadCourierMetricsFunc({ store_id: storeId, date })
			);
		}
		const response = await Promise.allSettled(metricsToFetch);
		const data = response.reduce((result, resp) => {
			if (resp.status !== 'fulfilled') {
				throw Error;
			}
			const { metrics } = resp.value.data;

			for (const key in metrics) {
				result[key] = metrics[key];
			}

			return result;
		}, {});
		setData((pre: any) => ({ ...pre, ...data }));
	} catch (e) {
		notification.error({
			message: e.message,
		});
	} finally {
		toggleLoading && toggleLoading(false);
	}
}

// Теперь есть новая ручка, в которой и складские, и курьерские метрики - можно больше не мержить
export async function loadAverageMetrics(
	storeId: string,
	setData: (...args: any) => void,
	toggleLoading?: (isLoading: boolean) => void
) {
	toggleLoading && toggleLoading(true);
	try {
		const { data } = await api.reportData.tablo_metrics.load({
			slice: 'day',
			entity: 'store',
			entity_id: storeId,
		});
		setData(data.result.metrics);
	} catch (e) {
		notification.error({
			message: e.message,
		});
	} finally {
		toggleLoading && toggleLoading(false);
	}
}
