import { Tag } from 'antd';

export default (
	status: string,
	statuses: Record<string, string>,
	colorDictionary: Record<string, string>,
	className?: string,
	textColor?: string
) => {
	if (status) {
		return (
			<Tag
				style={{ color: textColor }}
				color={colorDictionary[status] || ''}
				data-test="status"
				data-test-content={statuses[status] || status}
				className={`status-tag ${className ?? ''}`}
			>
				{statuses[status] || status}
			</Tag>
		);
	}
	return <>{'—'}</>;
};
