import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type {
	ConfigAvailableGroup,
	ConfigsBulkLoadRequestArgument,
	ConfigsFormResponse,
	ConfigsImportCsv,
	ConfigsLoadRequestArgument,
	ConfigsRemoveRequestArgument,
	ConfigsSaveRequestArgument,
	ConfigsValues,
	ExpConfigsResponse,
	HealthMetricConfig,
} from '~types/configs';

export default {
	load: (input: ConfigsLoadRequestArgument, config?: AxiosAdditionalConfig) => {
		const request = { [`${input.type}_id`]: input.id, group: input.group, name: input.name };
		return axios.post<{ result: ExpConfigsResponse }>(`/api/admin/configs/${input.type}/load`, request, config);
	},
	loadForms: (input: ConfigsLoadRequestArgument, config?: AxiosAdditionalConfig) => {
		const request = { [`${input.type}_id`]: input.id, group: input.group, name: input.name };
		return axios.post<{
			results: ConfigsFormResponse;
		}>(`/api/admin/configs/${input.type}/load_forms`, request, config);
	},
	loadValues: (input: ConfigsLoadRequestArgument, config?: AxiosAdditionalConfig) => {
		const request = { [`${input.type}_id`]: input.id, group: input.group, name: input.name };
		return axios.post<{
			result: ConfigsValues;
		}>(`/api/admin/configs/${input.type}/load_values`, request, config);
	},
	bulkLoad: (input: ConfigsBulkLoadRequestArgument, config?: AxiosAdditionalConfig) => {
		const request = { [`${input.type}_id`]: input.ids, group: input.group, name: input.name };
		return axios.post<{
			result: Record<string, Record<string, HealthMetricConfig>>;
		}>(`/api/admin/configs/${input.type}/bulk_load`, request, config);
	},
	availableGroups: (input: ConfigsLoadRequestArgument, config?: AxiosAdditionalConfig) => {
		const request = { [`${input.type}_id`]: input.id };
		return axios.post<{
			results: Array<string>;
			new_results: Array<ConfigAvailableGroup>;
		}>(`/api/admin/configs/${input.type}/available_groups`, request, config);
	},
	save: (input: ConfigsSaveRequestArgument, config?: AxiosAdditionalConfig) => {
		const configs = { [`${input.type}_id`]: input.id, group: input.group, name: input.name, value: input.value };
		return axios.post<{ configs: ExpConfigsResponse }>(`/api/admin/configs/${input.type}/save`, configs, config);
	},
	remove: (input: ConfigsRemoveRequestArgument, config?: AxiosAdditionalConfig) => {
		const configs = { [`${input.type}_id`]: input.id, group: input.group, name: input.name };
		return axios.post<{ configs: ExpConfigsResponse }>(`/api/admin/configs/${input.type}/rm`, configs, config);
	},
	removeDescendants: (input: ConfigsRemoveRequestArgument, config?: AxiosAdditionalConfig) => {
		const configs = { [`${input.type}_id`]: input.id, group: input.group, name: input.name };
		return axios.post<{ configs: ExpConfigsResponse }>(
			`/api/admin/configs/${input.type}/rm_descendants`,
			configs,
			config
		);
	},
	importCsv: (input: ConfigsImportCsv, config?: AxiosAdditionalConfig) => {
		return axios.post<{ configs: ExpConfigsResponse }>('/api/admin/configs/store/save_csv', input, config);
	},
} as const;
