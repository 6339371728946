import { lazy } from 'react';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

const StoresTable = lazy(() => import('../components/StoresPage'));
const StoresDataPage = lazy(() => import('../components/StoresPage/StoresDataPage'));
const PrintersPage = lazy(() => import('../components/PrintTasksPage'));
const StocksPage = lazy(() => import('../components/StocksPage'));
const StockLogPage = lazy(() => import('../components/StocksPage/StockLogTable'));
const MenuPage = lazy(() => import('../components/Kitchen/MenuPage'));
const Kitchen = lazy(() => import('../components/Kitchen'));

const storesRoutes = makeRoutes([
	{
		path: '/stores',
		permit: 'stores_seek',
		element: <StoresTable />,
	},
	{
		path: '/stores/add',
		permit: 'stores_create',
		element: <StoresDataPage editMode addMode />,
	},
	{
		path: '/stores/edit/:store_id',
		permit: 'stores_save',
		element: <StoresDataPage editMode />,
		disableRouteChecker: true,
	},
	{
		path: '/stores/:store_id',
		permit: 'stores_load',
		element: <StoresDataPage />,
		disableRouteChecker: true,
	},
	{
		path: '/stores/ext/:external_id',
		permit: 'stores_load',
		element: <StoresDataPage />,
	},
	{
		path: '/stores/:store_id/print_tasks',
		permit: 'store_print_tasks',
		element: <PrintersPage />,
	},
	{
		path: '/stores/:store_id/kitchen_menu',
		permit: 'kitchen_menu_view',
		element: <MenuPage />,
	},
	{
		path: '/kitchen',
		element: <PrependPath />,
	},
	{
		path: '/stores/:store_id/kitchen',
		permit: 'kitchen_menu_view',
		exp: 'exp_chef_interface',
		element: <Kitchen />,
	},
	{
		path: '/stocks',
		element: <PrependPath />,
	},
	{
		path: '/stores/:store_id/stocks',
		permit: 'stocks_seek',
		element: <StocksPage />,
	},
	{
		path: '/stores/:store_id/stocks/:stock_id',
		permit: 'stocks_seek',
		element: <StockLogPage />,
	},
]);

export default storesRoutes;
