import { notification } from 'antd';
import type { Dispatch } from 'redux';

import api from '~/api';
import { errorsCode } from '~constants/errorsCode';
import type { OrdersHistorySearchData } from '~shared/components/OrdersHistory';
import getAllRecursive from '~shared/utils/getAllRecursive';
import type { Orders } from '~types/orders';
import { checkExp, checkPermit } from '~zustand/userData';

export const ADD_ORDERS = 'ADD_ORDERS';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_ORDERS_DATA = 'UPDATE_ORDERS_DATA';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const SUPPLEMENT_ORDERS_HISTORY = 'SUPPLEMENT_ORDERS_HISTORY';
export const SUPPLEMENT_ORDERS_HISTORY_UPDATE = 'SUPPLEMENT_ORDERS_HISTORY_UPDATE';
export const PARTNER_PICKUP_LIST_UPDATE = 'PARTNER_PICKUP_LIST_UPDATE';

function addOrdersAction(payload: { orders: Orders.Order[] }) {
	return {
		type: ADD_ORDERS,
		payload,
	};
}

export function updateOrdersData(payload: { ordersData: Record<string, any>[] }) {
	return {
		type: UPDATE_ORDERS_DATA,
		payload,
	};
}

export function updateOrdersAction(payload: { orders: Orders.Order[]; ordersToUpdate: string[] }) {
	return {
		type: UPDATE_ORDERS,
		payload,
	};
}

function supplementOrdersHistoryAction(payload: {
	supplement: Orders.Order[];
	cursor: string | null;
	ordersToUpdate?: string[];
}) {
	return {
		type: SUPPLEMENT_ORDERS_HISTORY,
		payload,
	};
}

function partnerPickupListOrdersAction(payload: { partnerPickupOrders: Orders.Order[] }) {
	return {
		type: PARTNER_PICKUP_LIST_UPDATE,
		payload,
	};
}

function supplementOrdersHistoryUpdateAction(payload: {
	supplement: Orders.Order[];
	settings: { falconIsOn: boolean; agutinIsOn: boolean; chuganinIsOn: boolean; timezone?: string; mayApprove: boolean };
}) {
	return {
		type: SUPPLEMENT_ORDERS_HISTORY_UPDATE,
		payload,
	};
}

export function clearOrdersAction() {
	return {
		type: CLEAR_ORDERS,
	};
}

export function getOrders(
	orderIds?: string[],
	options?: { loadIncomplete?: boolean },
	supplementUpdateSettings?: {
		timezone?: string;
	}
) {
	return async function (dispatch: Dispatch) {
		const agutinIsOn = checkExp('exp_agutin');
		const chuganinIsOn = checkExp('exp_chuganin');
		const falconIsOn = checkExp('exp_falcon');
		const partnerPickupListIsOn = checkExp('exp_partner_pickup_list');
		const mayApprove = checkPermit('dispatcher_approve_acceptance');
		orderIds = orderIds && [...new Set(orderIds)];
		try {
			if (orderIds && orderIds.length) {
				const { data } = await api.orders.load(
					{
						order_id: orderIds,
					},
					{ force: true }
				);

				dispatch(
					updateOrdersAction({
						orders: data.result,
						ordersToUpdate: orderIds,
					})
				);

				// для обновления вкладки "требуется обработка"
				const getDataForNeedProcessing =
					supplementUpdateSettings?.timezone && (agutinIsOn || chuganinIsOn || falconIsOn);
				if (getDataForNeedProcessing) {
					dispatch(
						supplementOrdersHistoryUpdateAction({
							supplement: data.result,
							settings: {
								timezone: supplementUpdateSettings?.timezone,
								agutinIsOn,
								chuganinIsOn,
								falconIsOn,
								mayApprove,
							},
						})
					);
				}

				// для обновления вкладки "Заказы партнеров"
				if (partnerPickupListIsOn) {
					dispatch(
						partnerPickupListOrdersAction({
							partnerPickupOrders: data.result.filter((order) => order.attr.is_pickup),
						})
					);
				}

				return data.result;
			}

			const requests = [api.orders.list];

			if (options?.loadIncomplete) {
				// @ts-expect-error кривой тип getAllRecursive
				requests.push(() => getAllRecursive(api.orders.incomplete));
			}

			const [list, incomplete] = await Promise.all(requests.map((fn) => fn()));

			let ordersData = list.data.results;
			if (options?.loadIncomplete) {
				const ordersDataIds = ordersData.map((order) => order.order_id);
				const filteredIncompleteOrders = incomplete.data.results.filter(
					(order) => !ordersDataIds.includes(order.order_id)
				); // отфильтровываем ордера, которые уже есть в orders/list
				ordersData = [...ordersData, ...filteredIncompleteOrders];
			}
			dispatch(
				addOrdersAction({
					orders: ordersData,
				})
			);
			return ordersData;
		} catch (e) {
			console.error(e);
		}
	};
}

export function supplementOrdersHistory(payload: OrdersHistorySearchData) {
	return async function (dispatch: Dispatch) {
		try {
			const { status, type, doc_date, doc_number, date_status_updated_from, date_status_updated_to } = payload;

			const { data: processingRequiredData } = await getAllRecursive(api.orders.processingRequired, {
				date_status_updated_from,
				date_status_updated_to,
			});

			const { data } = await getAllRecursive(api.orders.history, { status, type, doc_date, doc_number });

			return dispatch(
				supplementOrdersHistoryAction({
					supplement: [...data.orders, ...processingRequiredData?.results],
					cursor: data.cursor,
				})
			);
		} catch (e) {
			console.error(e);
		}
	};
}

export function partnerPickupListOrders() {
	return async function (dispatch: Dispatch) {
		try {
			const { data } = await api.orders.partnerPickupList();
			return dispatch(
				partnerPickupListOrdersAction({
					partnerPickupOrders: data.results,
				})
			);
		} catch (data) {
			notification.error({
				message: errorsCode[data.code] || data.message || data.code,
			});
		}
	};
}

export function setMarkPartnerOrderAsTaken({ order_id }: { order_id: string }) {
	return async function () {
		try {
			await api.orders.markAsPartnerTaken({ order_id });
		} catch (data) {
			notification.error({
				message: errorsCode[data.code] || data.message || data.code,
			});
		}
	};
}
