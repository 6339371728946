import type { AxiosAdditionalConfig } from 'api';
import type { AxiosResponse } from 'axios';

import mergeResponses from './mergeResponses';

export default async function getAllRecursive<
	TParams extends { cursor?: string | null; [key: string]: unknown },
	TRes extends { cursor: string | null; results?: unknown[] },
>(
	api: (data: TParams, config?: AxiosAdditionalConfig) => Promise<AxiosResponse<TRes, TParams>>,
	params: TParams = {} as TParams,
	config?: AxiosAdditionalConfig
): Promise<AxiosResponse<TRes, TParams>> {
	const responses: AxiosResponse<TRes, TParams>[] = [];
	let cursor: string | null = params?.cursor ?? '';

	while (cursor !== null) {
		if (config?.signal?.aborted) {
			throw { data: {}, status: 'CANCELED' };
		}
		const response: AxiosResponse<TRes, TParams> = await api({ ...params, cursor }, config);
		responses.push(response);
		cursor = response.data.cursor;
	}

	if (responses.length) {
		const result = responses.shift()!;
		responses.forEach((res) => mergeResponses<TRes>(result, res));

		return result;
	}

	throw new Error();
}
