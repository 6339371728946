import { makeStyles } from '~styles/theme';

export const keyDataBackground = '#D9DFE8';

export const useStyles = makeStyles()(() => ({
	cardContainer: {
		fontSize: 14,
		borderRadius: 15,
		backgroundColor: '#F5F5F5',
	},
	hidden: {
		display: 'none',
	},
	padding: {
		paddingInlineStart: 20,
		paddingInlineEnd: 20,
	},
	cardHeader: {
		borderRadius: '15px 15px 0 0',
		backgroundColor: '#CCD4E0',
		paddingBlockStart: 10,
		paddingBlockEnd: 10,
	},
	cardType: {
		fontWeight: 'bold',
		marginInlineEnd: 10,
	},
	keydataContainer: {
		display: 'flex',
		flexDirection: 'row',
		paddingBlockStart: 10,
		backgroundColor: keyDataBackground,
	},
	keydataValue: {
		textOverflow: 'ellipsis',
	},
	jsonContainer: {
		paddingBlockStart: 8,
	},
	jsonTree: {
		overflow: 'scroll',
		maxBlockSize: 300,
		paddingInlineEnd: 20,
		overflowX: 'auto',
		inlineSize: '100%',
	},
	jsonSearchInput: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		gap: 5,
	},
	foundKey: {
		fontWeight: 'bold',
		color: '#381D2A',
	},
	suggestButtonsRow: {
		paddingBlockStart: 10,
		paddingBlockEnd: 10,
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: 10,
		backgroundColor: '#E0E0E0',
		borderRadius: '0 0 15px 15px',
		marginBlockStart: 5,
		'& > *': {
			margin: 3,
		},
	},
	giftCard: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 20,
		backgroundImage: 'linear-gradient(to top, #0250c5 0%, #d43f8d 100%)',
		textAlign: 'center',
		blockSize: 400,
		padding: 80,
		fontSize: '2em',
		color: 'white',
		boxShadow: 'rgba(0, 0, 0, 0.5) 0px 4px 12px',
	},
	errorHeader: {
		backgroundColor: '#FFADAD',
	},
	errorKeydata: {
		backgroundColor: '#FFEBEB',
	},
	errorFooter: {
		backgroundColor: '#FF9999',
	},
	spinner: {
		blockSize: 100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	roundHeader: {
		borderRadius: 15,
	},
}));
