import type { ProductScheduleTypes } from '~server-types/doc/api/models/product';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const scheduleTypes: Record<ProductScheduleTypes, string> = makeDynamicTranslations({
	kitchens: tDynamic('Кухня'),
	coffee: tDynamic('Кофе'),
	alcohol: tDynamic('Алкоголь'),
	pharmacy: tDynamic('Аптека'),
	safe: tDynamic('Сейф'),
});
