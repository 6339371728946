import { lazy } from 'react';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

import EquipmentsDataPage from '../components/ShelvesPage/Equipment/EquipmentDataPage';

const ShelvesPage = lazy(() => import('../components/ShelvesPage'));
const ShelfDataPage = lazy(() => import('../components/ShelvesPage/ShelfDataPage'));
const CreateRackPage = lazy(() => import('../components/ShelvesPage/CreateRackPage'));

const shelvesRoutes = makeRoutes([
	{
		path: '/shelves',
		element: <PrependPath />,
	},
	{
		path: '/stores/:store_id/shelves/add_rack',
		permit: 'racks_create',
		element: <CreateRackPage editMode addMode />,
	},
	{
		path: '/stores/:store_id/shelves',
		permit: 'shelves_seek',
		element: <ShelvesPage />,
	},
	{
		path: '/stores/:store_id/shelves/add',
		permit: 'shelves_create',
		element: <ShelfDataPage editMode addMode />,
	},
	{
		path: '/stores/:store_id/shelves/edit/:shelf_id',
		permit: 'shelves_save',
		element: <ShelfDataPage editMode />,
	},
	{
		path: '/stores/:store_id/shelves/:shelf_id',
		permit: 'shelves_load',
		element: <ShelfDataPage />,
	},
	{
		path: '/stores/:store_id/shelves/equipments/add',
		permit: 'equipments_save',
		exp: 'exp_lavka4',
		element: <EquipmentsDataPage editMode={true} addMode={true} />,
	},
	{
		path: '/stores/:store_id/shelves/equipments/edit/:equipment_id',
		permit: 'equipments_save',
		exp: 'exp_lavka4',
		element: <EquipmentsDataPage editMode={true} />,
	},
	{
		path: '/stores/:store_id/shelves/equipments/:equipment_id',
		permit: 'equipments_load',
		exp: 'exp_lavka4',
		element: <EquipmentsDataPage />,
	},
]);

export default shelvesRoutes;
