import type { SectionConfig } from '~constants/dataPageFields/types';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const equipmentsFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'equipment_type_id',
				label: tDynamic('Оборудование'),
				type: 'string',
				inputType: 'select',
				options: [],
				dictionary: {},
				canEdit: true,
			},
			{
				key: 'description',
				label: tDynamic('Описание оборудования'),
				type: 'string',
				inputType: 'textarea',
				canEdit: true,
			},
			{
				key: 'temperature',
				label: tDynamic('Датчик температуры'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'voltage',
				label: tDynamic('Датчик напряжения'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
		],
	},
	{
		label: tDynamic('Стеллажи в этом оборудовании'),
		fields: [
			{
				key: 'racks',
				label: tDynamic('Группа стеллажей'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				selectEntity: 'equipments',
				mode: 'multiple',
			},
		],
	},
]);
