import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Products } from '~types/products';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<
		{
			title?: string;
			product_ids?: string[];
			external_id?: string;
			barcode?: string;
			product_groups?: string[];
		},
		Products.Product
	>('/api/admin/products/list'),
	load: makeLoadFn<Products.Product, 'product_id'>('/api/admin/products/load', 'product_id'),
	save: (data: Record<string, any>) => axios.post<{ result: Products.Product }>('/api/admin/products/save', data),
	barcode: (data: { barcode: string }) => {
		return axios.post('/api/admin/products/barcode', data);
	},
};
