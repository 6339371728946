import { Spin } from 'antd';
import debounce from 'lodash/debounce';
import { memo, useCallback, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import HelmetWrapper from '~shared/components/hoc/HelmetWrapper';
import { LayoutContainer } from '~shared/components/Layout/Container';
import { useRumSpaCounter } from '~shared/hooks/useRumSpaCounter';
import useSubscribe from '~shared/hooks/useSubscribe';
import type { EventCallback } from '~shared/utils/ev';
import { sendChangeRoute } from '~shared/utils/eventMetrikaService';
import { getUserData, useUser } from '~zustand/userData';

function Root() {
	const location = useLocation();
	const user = useUser();

	useRumSpaCounter();

	useLayoutEffect(() => {
		sendChangeRoute(location);
	}, [location]);

	const updateUserData = useCallback(
		debounce(
			() => getUserData(),
			// Делаем немного случайный дебаунс, чтобы размазать нагрузку на бэк
			5_000 * (1 + Math.random())
		),
		[]
	);

	const onConfigChange: EventCallback = (event) => {
		if (event.data.some((e) => e.type === 'config')) {
			updateUserData();
		}
	};

	useSubscribe(
		{
			key: ['config', 'store', user.store_id],
			cb: onConfigChange,
			single: true,
			name: 'update_store_config',
		},
		[user.store_id]
	);

	useSubscribe(
		{
			key: ['config', 'company', user.company_id],
			cb: onConfigChange,
			single: true,
			name: 'update_company_config',
		},
		[user.company_id, user.store_id],
		Boolean(user.store_id)
	);

	return (
		<LayoutContainer>
			<HelmetWrapper />
			{!!user.role ? (
				<div
					style={{
						blockSize: '100%',
					}}
					data-test="root"
				>
					<Outlet />
				</div>
			) : (
				<div
					style={{
						blockSize: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<Spin />
				</div>
			)}
		</LayoutContainer>
	);
}

export default memo(Root);
