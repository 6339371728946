import { notification } from 'antd';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';

import api from '~/api';
import i18n from '~/i18n';
import type { Products } from '~types/products';

export const searchProducts = async (
	searchData: string,
	searchField: string,
	cancelTokenSource: CancelTokenSource | undefined,
	productGroups?: string[]
) => {
	if (searchField === 'title' && searchData.length && searchData.length < 4) {
		return;
	}
	try {
		cancelTokenSource?.cancel();
		cancelTokenSource = axios.CancelToken.source();

		if (searchField === 'barcode') {
			const { data } = await api.search.barcode({ barcode: searchData }, { cancelToken: cancelTokenSource.token });

			if (data.products?.length) {
				return data.products.map((product: Products.Product) => ({
					value: product.product_id,
					text: product.title,
				}));
			} else if (data.items?.length) {
				return data.items.map((item: Products.Item) => ({
					value: item.item_id,
					text: item.title,
				}));
			} else {
				return [];
			}
		} else {
			const { data } = await api.products.list(
				{
					product_groups: productGroups?.length ? productGroups : undefined,
					[searchField]: searchData,
					cursor: '',
				},
				{ cancelToken: cancelTokenSource.token }
			);

			if (data.results?.length) {
				return data.results?.map((product: Products.Product) => ({
					value: product.product_id,
					text: product.title,
				}));
			} else {
				return [];
			}
		}
	} catch {
		notification.error({
			message: i18n.t('Не удалось получить продукты'),
		});
	}
};
