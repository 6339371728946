import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { EquipmentDisabledReasons } from '~server-types/doc/api/admin/equipments';
import type { EquipmentModule } from '~server-types/doc/api/models/equipment';
import type { EquipmentTypeStatuses } from '~server-types/doc/api/models/equipment_type';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { ClusterEquipment, EquipmentSave, EquipmentsItem, EquipmentTypesItem } from '~types/equipment';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	types: {
		list: makeListFn<
			{
				cluster_id?: string;
				store_id?: string;
				title?: EquipmentTypesItem['title'];
				module?: EquipmentTypesItem['module'];
				cursor?: string | null;
				status?: EquipmentTypeStatuses;
			},
			EquipmentTypesItem
		>('/api/admin/equipment_types/list'),

		load: makeLoadFn<EquipmentTypesItem, 'equipment_type_id'>('/api/admin/equipment_types/load', 'equipment_type_id'),

		save: (data: Partial<EquipmentTypesItem>, config?: AxiosAdditionalConfig) =>
			axios.post<{ result: EquipmentTypesItem }>('/api/admin/equipment_types/save', data, config),

		rm: (data: Record<'equipment_type_id', string>, config?: AxiosAdditionalConfig) =>
			axios.post('/api/admin/equipment_types/rm', data, config),

		missing: (data: object, config?: AxiosAdditionalConfig) =>
			axios.post<{ results: Record<string, string[]> }>('/api/admin/equipment_types/missing', data, config),
	},

	items: {
		load: makeLoadFn<EquipmentsItem, 'equipment_id'>('/api/admin/equipments/load', 'equipment_id'),

		list: makeListFn<
			{
				store_id?: string;
				status?: EquipmentTypeStatuses;
				module?: EquipmentModule;
			},
			EquipmentsItem
		>('/api/admin/equipments/list'),

		cluster_list: makeListFn<EmptyObject, ClusterEquipment>('/api/admin/equipments/cluster_list'),

		save: (data: EquipmentSave, config?: AxiosAdditionalConfig) =>
			axios.post<{ result: EquipmentsItem }>('/api/admin/equipments/save', data, config),

		rm: (data: Record<'equipment_id', string>, config?: AxiosAdditionalConfig) =>
			axios.post('/api/admin/equipments/rm', data, config),

		turnOn: (data: Record<'equipment_id', string>, config?: AxiosAdditionalConfig) =>
			axios.post('/api/admin/equipments/turn_on', data, config),
		turnOff: (
			data: {
				equipment_id: string;
				external_id: string;
				reason: EquipmentDisabledReasons;
			},
			config?: AxiosAdditionalConfig
		) =>
			axios.post<{ result: { equipment: EquipmentsItem; order_id: string } }>(
				'/api/admin/equipments/turn_off',
				data,
				config
			),
	},
};
