import 'react-phone-number-input/style.css';

import { Col, Input, Row, Tooltip } from 'antd';
import type { JSX, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';

import type { ITextField } from '~constants/dataPageFields/types';
import { ReactComponent as TooltipIcon } from '~images/question-circle-solid.svg';
import { validateEmail } from '~shared/utils/fieldsValidation';

import CopyString from '../../hoc/CopyString';
import { useStyles } from '../styles';
import type { FieldProps } from '../types';
import { EmptyValue, getColSpan, useFieldChangeProps } from '../utils';
import LongTextView from './LongTextView';
import PasswordView from './PasswordView';
import { useStyles as useTextStyles } from './styles';

interface TextFieldProps extends FieldProps, ITextField {
	onPressEnter?: () => void;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	hasLock?: boolean;
	addMode?: boolean;
	pattern?: string;
	actionBtn?: JSX.Element;
	removeLabel?: boolean;
	hideLabel?: boolean;
	fieldRequired?: boolean;
	loading?: boolean;
	tooltip?: string;
	customLayout?: boolean;
	placeholder?: string;
	allowClear?: boolean;
	copyButtonConfig?: {
		tooltip: string;
		success: string;
		dataTest?: string;
	};
	dataTest?: string;
	isFieldInvalid?: boolean;
	isFieldInvalidMessage?: string;
	suffix?: string | ReactNode;
	errorInlineEnd?: boolean;
	hasViewMode?: boolean;
	width?: string;
	maxLength?: number;
	showCount?: boolean;
	rows?: number;
}

const TextField = ({
	id,
	label,
	input,
	meta: { error, touched },
	disabled,
	onPressEnter,
	editMode,
	inputType,
	colspan,
	hasLock,
	addMode,
	pattern,
	actionBtn,
	max,
	min,
	rows,
	tooltip,
	removeLabel = false,
	hideLabel = false,
	fieldRequired,
	onlyView,
	additionalContent,
	render,
	customLayout,
	placeholder,
	allowClear,
	copyButtonConfig,
	dataTest,
	isFieldInvalid,
	isFieldInvalidMessage,
	suffix,
	errorInlineEnd,
	hasViewMode = true,
	width,
}: TextFieldProps) => {
	const [t] = useTranslation();

	const { cx, classes } = useStyles();
	const { classes: textClasses } = useTextStyles({ error });

	const fieldChangeProps = useFieldChangeProps(
		input,
		disabled,
		!addMode && hasLock,
		editMode,
		`input lock button ${label}`,
		pattern
	);

	const renderInput = () => {
		switch (inputType) {
			case 'phone':
				return (
					<PhoneInput
						className={cx(textClasses.phoneInput, {
							[textClasses.inputError]: touched && error,
						})}
						numberInputProps={{
							className: cx(textClasses.input, 'ant-input'),
							'data-test': `data form ${label}${disabled ? ' disabled' : ''}`,
						}}
						{...input}
						{...fieldChangeProps.changeProps}
						placeholder={placeholder}
					/>
				);
			case 'textarea':
				return (
					<div className={textClasses.emailInput}>
						<Input.TextArea
							rows={rows ?? 4}
							className={cx(textClasses.input, {
								[textClasses.inputError]: touched && !!error,
							})}
							{...input}
							{...fieldChangeProps.changeProps}
							data-test={`data form ${label}${disabled ? ' disabled' : ''}`}
							placeholder={placeholder}
							allowClear={allowClear}
							maxLength={max}
							showCount={!!max}
						/>
						{isFieldInvalid && <span className={textClasses.inputTextError}>{isFieldInvalidMessage}</span>}
					</div>
				);
			case 'password_box':
				if (disabled) {
					return <PasswordView value={input.value} label={label} />;
				} else {
					return (
						<Input.Password
							className={cx(textClasses.input, {
								[textClasses.inputError]: touched && !!error,
							})}
							{...input}
							{...fieldChangeProps.changeProps}
							data-test={`data form ${label}${disabled ? ' disabled' : ''}`}
							placeholder={placeholder}
						/>
					);
				}
			case 'email':
				return (
					<div className={textClasses.emailInput}>
						<Input
							className={cx(textClasses.input, {
								[textClasses.inputError]: touched && (error || typeof validateEmail(input.value) === 'string'),
							})}
							onPressEnter={onPressEnter}
							id={id}
							type={inputType}
							data-test={`data form ${label}${disabled ? ' disabled' : ''}`}
							{...input}
							{...fieldChangeProps.changeProps}
							placeholder={placeholder}
						/>
						{
							<span className={textClasses.inputTextError}>
								{validateEmail(input.value, t('Проверьте правильность ввода почты'))}
							</span>
						}
					</div>
				);
			default:
				return (
					<Input
						className={cx(textClasses.input, {
							[textClasses.inputError]: !!error,
						})}
						onPressEnter={onPressEnter || undefined}
						id={id}
						type={inputType}
						max={max}
						min={min}
						suffix={suffix}
						data-test={dataTest ?? `data form ${label}${disabled ? ' disabled' : ''}`}
						{...input}
						{...fieldChangeProps.changeProps}
						placeholder={placeholder}
						allowClear={allowClear}
					/>
				);
		}
	};

	const longText = inputType === 'textarea' && input.value && input.value.length > 35;

	const renderValue = (): JSX.Element | string =>
		copyButtonConfig ? (
			<CopyString
				string={input.value}
				tooltip={copyButtonConfig.tooltip}
				success={copyButtonConfig.success}
				dataTest={copyButtonConfig.dataTest}
			>
				{input.value}
			</CopyString>
		) : (
			input.value
		);

	const renderView = () => {
		if (longText) {
			return <LongTextView text={input.value} dataTest={`data form ${label}`} />;
		}
		if (inputType === 'password_box') {
			return <PasswordView value={input.value} label={label} />;
		}
		if (render) {
			return (
				<div className={classes.valueOnViewMode} data-test={`data form ${label}`}>
					{render(input.value)}
				</div>
			);
		}

		return (
			<div
				className={cx(classes.valueOnViewMode, {
					withCopyButton: !!copyButtonConfig,
				})}
				data-test={dataTest ?? `data form ${label}`}
			>
				{input.value || input.value === 0 ? renderValue() : <EmptyValue />}
			</div>
		);
	};

	const renderInputContainer = () => (
		<>
			{(editMode && !onlyView) || !hasViewMode ? (
				<>
					<div className={cx(classes.inputContainer, 'wms-input-container', { error })}>
						{renderInput()}
						{!addMode && hasLock && fieldChangeProps.lockButton}
						{actionBtn}
					</div>
					{error && (
						<span
							className={cx(classes.inputTextError, { [classes.inputTextErrorInlineEnd]: errorInlineEnd })}
							data-test={`data form ${label} error`}
						>
							{error}
						</span>
					)}
				</>
			) : (
				renderView()
			)}
		</>
	);

	if (customLayout) {
		return renderInputContainer();
	}

	return (
		<Row align="top" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={{ minBlockSize: 42, inlineSize: width }}>
			{!removeLabel && (
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					{!hideLabel && (
						<>
							<div className={classes.tooltipWrapper}>
								<label
									className={cx(classes.label, {
										[classes.labelRequired]: editMode && fieldRequired,
									})}
									htmlFor={id}
								>
									{label}
								</label>
								{tooltip && (
									<Tooltip title={tooltip}>
										<TooltipIcon width="12" height="12" className={classes.labelTooltip} />
									</Tooltip>
								)}
							</div>

							<div className={classes.dotSeparator} />
						</>
					)}
				</Col>
			)}
			<Col {...getColSpan(colspan)} className={classes.readOnlyFieldContainer}>
				{renderInputContainer()}
			</Col>
			{additionalContent && <Col span={24}>{additionalContent}</Col>}
		</Row>
	);
};
export default TextField;
